<mat-label class="subHeading"
  >Sub Product Type<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select
  #subprductSelect
  class="insurer"
  multiple
  formControlName="subproducttype"
>
  <mat-option *ngFor="let subProduct of subProductList" [value]="subProduct">
    {{ subProduct }}
  </mat-option>
</mat-select>
