import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

import { formatAllDates } from 'src/app/core/utils/utility.functions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TableDialogComponent } from '../../dialog-components/table-dialog/table-dialog.component';
import { PayinOutService } from '../payin-out.service';
import { BasicDialogComponent } from '../../dialog-components/basic-dialog/basic-dialog.component';


@Component({
  selector: 'app-form-save-buttons',
  templateUrl: './form-save-buttons.component.html',
  styleUrls: ['./form-save-buttons.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FormSaveButtonsComponent implements OnInit {
  private excludeFromDuplicateCheck: string[] = ['bp', 'contest'];

  @Input('isNew') isNew: boolean = false;
  @Input('lob') lob: string;

  form: FormGroup;

  ruleId: string = '';

  isSending: boolean = false;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    private snackbarService: SnackbarService,
    private router: Router,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private payoutService: PayinOutService
  ) { };

  ngOnInit(): void {
    // this.dialogService.openDialog({title: `Error`, body: ""}, BasicDialogComponent, 100000000)
    this.form = this.ctrlContainer.form;
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        this.ruleId = params['id'];
      }
    });
  }

  submitForm() {
    console.log(this.ctrlContainer.form.value);

    if (this.isSending) { return };
    const rule_data = this.removeNull(formatAllDates(this.ctrlContainer.form.value), null, '');
    if (!URLS[this.lob] && !this.payoutService.isValidForm()) {
      this.form.setErrors({ 'invalidData': true });
      return;
    }

    const data = ({ 'product': this.lob, 'rule_id': this.ruleId, 'data': rule_data });
    if (this.excludeFromDuplicateCheck.indexOf(this.lob) != -1) {
      this.createRule(data);
    } else {
      this.checkDuplicate(data);
    }
  }

  checkDuplicate(data: any) {
    this.isSending=true
    this.http.post(`/services/api/v1/rules/check_rule_duplication`, data).subscribe((res: any) => {
      this.isSending=false;
      if (!res['status']) {
        this.createRule(data);
        return;
      };
      const dialogData = { 'lob': this.lob, 'data': res['data'] };
      this.dialogService.openDialog(dialogData, TableDialogComponent, 500000).subscribe((res: any) => {
        if (res == 'continue') {
          this.createRule(data);
        } else if (res == 'cancel') {
          
          // this.onCancel();
        }
      });
    },
    (err: any) => {
      this.isSending = false;
  })
  }

  createRule(data: any) {
    this.isSending = true;
    let url = URLS[this.lob] || '/services/api/v1/rules';

    const ruleType = data.data.rule_type;

    let ruleSubscription;
    if (this.isNew) {
      ruleSubscription = this.http.post(url, data);
    } else {
      url = `${url}/${this.ruleId}`;
      ruleSubscription = this.http.put(url, data);
    }

    ruleSubscription.subscribe((res: any) => {
      this.isSending = false;
      this.snackbarService.openSnackbar(res);
      if (this.lob == 'contest' || this.lob.includes('bp')) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.form.reset();
        this.payoutService.resetForm();
        if (ruleType) {
          this.form.patchValue({ 'rule_type': ruleType });
        }
      }
    },
      (err: any) => {
        this.isSending = false;
    });
  }

  removeNull(obj: any, target?: any, replacement?: any): any {
    if (obj === null) { return obj };

    if (Array.isArray(obj)) {
      const newArr = [];
      for (const val of obj) {
        if (typeof (val) !== "object") {
          newArr.push(val !==false && val !== 0 ? val || null: val);
        } else {
          newArr.push(this.removeNull(val));
        }
      }
      return newArr;
    } else if (typeof (obj) === "object") {
      for (const key of Object.keys(obj)) {
        if (typeof (obj[key]) !== "object") {
          obj[key] = obj[key]!==false && obj[key] !== 0 ? obj[key] || null : obj[key];
        } else {
          obj[key] = this.removeNull(obj[key]);
        }
      }
    }
    return obj!==false && obj!== 0 ? obj || null : obj;
  }

  onCancel() {
    if (this.lob.includes('bp')) {
      this.lob = 'bp';
    }
    window.location.href = "/rules/overview/" + this.lob;
  }
}

const URLS:any = {
  'contest': '/services/api/v1/rules/contest',
  'bp_partner_activation': '/services/api/v1/bp/partner-activation-criteria',
  'bp': '/services/api/v1/bp/rule',
  'super_bp': '/services/api/v1/super_bp/rules',
};