<div class="row">
    <div class="col-4">
        <mat-label class="subHeading">State<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'state_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="stateList"
            [control]="stateControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
    <div class="col-4">
        <mat-label class="subHeading">City<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'city_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="cityList"
            [control]="cityControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
</div>
