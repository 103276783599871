<div class="container p-4">
    <h2 class="text-center">Duplicate Rules</h2>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <hr>
    
        <ng-container *ngFor="let disCol of columnDefs let colIndex = index;" matColumnDef="{{disCol.columnDef}}">
            <th mat-header-cell *matHeaderCellDef class="header">
                {{ disCol.header | titlecase }}
            </th>
    
            <td mat-cell *matCellDef="let element" class="{{element.class}}">
                <span *ngIf="!disCol.isLink; else link">
                    {{ disCol.cell(element) | titlecase}}
                </span>
    
                <ng-template #link>
                    <a [href]="[disCol.url(element)]" target="_blank">
                        {{ disCol.cell(element) }}
                    </a>
                </ng-template>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayColumns">
        </tr>
    
        <tr mat-row *matRowDef="let row; columns: displayColumns;">
        </tr>
    </table>

    <div mat-dialog-actions>
        <button *ngIf="!data.data.exact_matching_rule.length && !bp" mat-button (click)="clickOk()" tabindex="2">Continue</button>
        <button mat-button (click)="clickCancel()" tabindex="-1">Cancel</button>
    </div>
</div>