import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';

/*
!!! Mainly used for fields that are supposed to be dependent on other form controls !!!
Service is responsible for maintaining state of form control values and exposing observable for detecting value changes
This will be used as an alternative for angular form control vaue changes.
Since using value changes is not a feasable option for working with dependent fields when the SearchDropdownComponent is used.
When you search something in dropdown component, it fires value changes with new values without retaining the existing values.
*/

interface ControlState{
  value: string[] | number[]
  changeObservable: Subject<any>
}

@Injectable({providedIn:'root'})
export class FormControlService {
  // Holds the state of a form control; ie, value of form control.
  private state:{[key:string]: ControlState} = {};

  constructor() { }

  // Register a form control to store the state of that form control
  // If onValueChanges is true, then the state is updated with formcontrol valuechanges(provided by angular)
  register(formControl: { controlName: string, control: FormControl | AbstractControl }, onValueChanges?: boolean) {
    if (this.state[formControl.controlName]) {
      console.log(`Already registered ${formControl.controlName}`);
      return;
    }
    this.state[formControl.controlName] = { value: [], changeObservable: new ReplaySubject() };
    if (onValueChanges) {
      formControl.control.valueChanges.subscribe(val => this.updateStateValue(formControl.controlName, val));
    }
  }

  getStateValue(controlName: string) {
    if (this.state[controlName]) {
      return this.state[controlName].value;
    }
    return null;
  }

  // Updates the value of form control in state and fire the subject with newly assigned value
  updateStateValue(controlName: string, newValue: string[] | number[]) {
    if (this.state[controlName]) {
      this.state[controlName].value = newValue;
      this.fireChangeObservable(controlName);
    }
  }
  
  private fireChangeObservable(controlName: string) {
    console.log('Fired changes', controlName, this.state[controlName].value);
    this.state[controlName].changeObservable.next(this.state[controlName].value);
  }

  isRegistered(controlName: string) {
    return !!this.state[controlName];
  }

  getControlObservable(controlName: string) {
    if (this.state[controlName]) {
      return this.state[controlName].changeObservable;
    } else {
      throw Error(`control ${controlName} does not exist in control state.`)
    }
  }

}
