import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { NotificationBellComponent } from './notification-bell/notification-bell.component';
import { ProfileAvatarComponent } from './header/profile-avatar/profile-avatar.component';
import { WindowRef } from '../core/services/window.service';
import { TableComponent } from './table/table.component';
import { MaterialModule } from '../shared/material/material.module';
import { IsGrantedDirective } from '../shared/directive/app-is-granted.directive';
import { HistoryComponent } from './history/history.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    NavBarComponent,
    HeaderComponent,
    NotificationBellComponent,
    ProfileAvatarComponent,
    TableComponent,
    IsGrantedDirective,
    HistoryComponent,
  ],
  imports: [CommonModule, RouterModule, MaterialModule, SharedModule],
  exports: [
    NavBarComponent,
    HeaderComponent,
    TableComponent,
    ProfileAvatarComponent,
  ],
  providers: [WindowRef],
})
export class FeaturesModule {}
