import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-rule-id',
  templateUrl: './rule-id.component.html',
  styleUrls: ['./rule-id.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RuleIdComponent implements OnInit {
  @Input('required') required = false;
  form:FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};
  ngOnInit():void{
    this.form = this.ctrlContainer.form;
    if(this.required){
      this.form.addControl('rule_id', new FormControl('', Validators.required));
    }
    else{
      this.form.addControl('rule_id',new FormControl());
    }

  }
}
