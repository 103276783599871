<div class="row">
    <div class="col-md-4 col-12">
        <mat-label class="subHeading">Category<span *ngIf="isRequired" class="star">*</span></mat-label>
        <mat-select [placeholder]="!isMultiSelect ? 'Select' : 'All'" [formControl]="vehicleCategoryControl" multiple>
            <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let category of categories" [value]="category.value">{{category.name}}</mat-option>
        </mat-select>
    </div>
    <div class="col-md-4 col-12" *ngIf="lob == 'cv'">
        <mat-label class="subHeading">Segment<span *ngIf="vehicleSegmentControl.hasValidator(requiredFn)" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="null"
            [dataValueKey]="null"
            [dataSource]="segments"
            [control]="vehicleSegmentControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
    <div class="col-md-4 col-12" *ngIf="lob == 'cv'" >
        <mat-label class="subHeading">Vehicle Class</mat-label>
        <app-search-dropdown
            [dataSearchKey]="'name'"
            [dataValueKey]="'id'"
            [dataSource]="vehicleClasses"
            [control]="vehicleClassControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
</div>