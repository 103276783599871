import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, CanActivate, CanLoad, Resolve, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, skip } from 'rxjs';
import { StateService } from 'src/app/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate{

  constructor(private stateService: StateService) { };

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.stateService.loaded$.subscribe((val:any)=> console.log('giard val',val));
    return this.stateService.loaded$;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.stateService.loaded$.subscribe((val: any) => console.log('guard', val));
    return this.stateService.loaded$;
  }
}
