import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-plan-type-finsure-field',
  templateUrl: './plan-type-finsure-field.component.html',
  styleUrls: ['./plan-type-finsure-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PlanTypeFinsureFieldComponent {
  @Input('lob') lob: any;

  plan_type: any = [];

  form: FormGroup;

  plantypeControl = new FormControl();

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    if (this.form.controls['plan_type']) {
      this.plantypeControl.setValue(this.form.controls['plan_type'].value);
      this.form.removeControl('plan_type');
    }
  
    this.form.addControl('plan_type', this.plantypeControl);
    this.getPlanType();
  }
  getPlanType() {
    const url = '/services/api/v1/rb_masters/finsure_plan_type'
    this.http.get(url).subscribe(
      (res: any)=>{
        this.plan_type = res['data'];

      }
    );
  }

  ngOnDestroy(): void {
    this.form.removeControl('plan_type');
  }
}
