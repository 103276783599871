<div class="even">
<mat-label class="subHeading ">NCB</mat-label>
<button class="side" (click)="ncbForm.controls['from'].setValue(null);ncbForm.controls['to'].setValue(null); ">Remove</button>
</div>

<div formGroupName="ncb" class="d-flex align-items-center mt-2" style="gap: 10px;">
    <mat-label>From</mat-label>
    <mat-select formControlName="from" class="w-50 m-0" placeholder="Select">
        <mat-option *ngFor="let ncb of fromNcbValues" [value]="ncb">{{ncb}}</mat-option>
    </mat-select>
    <mat-label>To</mat-label>
    <mat-select formControlName="to" class="w-50 m-0" placeholder="Select">
        <mat-option *ngFor="let ncb of toNcbValues" [value]="ncb">{{ncb}}</mat-option>
    </mat-select>
</div>