import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';

@Component({
  selector: 'app-plan-policy-type',
  templateUrl: './plan-policy-type.component.html',
  styleUrls: ['./plan-policy-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PlanPolicyTypeComponent {
  @Input('lob') lob: any;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('required') isRequired = false;

  form: FormGroup;
  planpolicytypeControl = new FormControl();

  plan_policy_type: any = [];

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    private controlStateService:FormControlService
  ) {
    this.controlStateService.register({ controlName: 'plan_policy_type', control: this.planpolicytypeControl });
  }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.form.controls['plan_policy_type']) {
      this.planpolicytypeControl.setValue(this.form.controls['plan_policy_type'].value);
    }
    this.form.addControl('plan_policy_type', this.planpolicytypeControl);

    this.getPlanName();
  }

  getPlanName() {
    const url = '/services/api/v1/rb_masters/health-plan-policy-type';
    this.http.get(url).subscribe((res: any) => {
      this.plan_policy_type = res['data'].map((val: any) => { val['rb_id'] = `${val['rb_id']}`;  return val});
      if (this.form.value['plan_policy_type_edit']) {
        this.planpolicytypeControl.setValue(this.form.value['plan_policy_type_edit']);
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('plan_policy_type');
  }

}
