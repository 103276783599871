import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-engine-no-field',
  templateUrl: './engine-no-field.component.html',
  styleUrls: ['./engine-no-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EngineNoFieldComponent implements OnInit {
  alphaRegex = /^[a-zA-Z0-9]+$/;
  @Input('required') isRequired = false;

  form: FormGroup;
  engineNoControl = new FormControl(null, [ Validators.minLength(6)]);

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('engine_no', this.engineNoControl);

    this.form.controls['business_type'].valueChanges.subscribe((val: any) => {
      if ((val.indexOf('new_business') != -1)) {
        this.isRequired = true;
        if (!this.engineNoControl.hasValidator(Validators.required)) {
          this.engineNoControl.addValidators(Validators.required);
          this.engineNoControl.updateValueAndValidity();
        }
      } else if(this.engineNoControl.hasValidator(Validators.required)){
        this.engineNoControl.removeValidators(Validators.required);
        this.isRequired = false;
        this.engineNoControl.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('engine_no');
  }
}
