import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Apiurls } from '../insurer-field/Api_urls';
import { HttpService } from 'src/app/core/services/http.service';
import { ActivatedRoute } from '@angular/router';
import { sort } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-insurer-field-two-wheeler',
  templateUrl: './insurer-field-two-wheeler.component.html',
  styleUrls: ['./insurer-field-two-wheeler.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class InsurerFieldTwoWheelerComponent {
  @Input('lob') lob: any;
  @Input('required') isRequired = true;
  @Input('multiple') isMultiSelect = false;

  insurers: any = [];

  form: FormGroup;
  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getinsurer();
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl(
        'insurerMultiple',
        new FormControl([], Validators.required)
      );
    } else {
      this.form.addControl('insurerMultiple', new FormControl([]));
    }
    // this.form.controls['insurer'].valueChanges.subscribe((val: any) => {
    //   if (val == null) {
    //     this.form.controls['insurer'].setValue('');
    //   }
    // });
  }

  ngOnChanges(changes: any) {
    if (changes['lob']) {
      this.getinsurer();
    }
  }

  getinsurer() {
    if (!this.lob || !this.lob.length) return;

    const product = this.lob;
    const url = Apiurls[product as keyof typeof Apiurls];
    this.http.get(url).subscribe((res: any) => {
      this.insurers = [];
      this.insurers = res['data'];
      this.insurers = sort(this.insurers, 'insurer_name');
      // // console.log(this.insurers);
      let insurerData = this.form.value['insurerMultiple'];
      if (insurerData.length == 0) {
        this.form.patchValue({
          insurerMultiple: null,
        });
      } else {
        this.form.patchValue({
          insurerMultiple: insurerData,
        });
      }
    });
  }

  // getinsurer() {
  //   const product = this.lob;
  //   const url = Apiurls[product as keyof typeof Apiurls];
  //   this.http.get(url).subscribe((res: any) => {
  //     if (res) {
  //       this.insurers = [];
  //       this.insurers = sort(res['data'], 'insurer_name');
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    this.form.removeControl('insurerMultiple');
  }
}
