import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent {
  @Input('transaction') transaction: any;
  @Input('lob') lob: any;
  @Input('rule_date') rule_date: any;
  @Input('sDate') sDate: any;
  @Input('premium') premium: any;
  @Input('payment_id') payment_id: any;
  @Input('tab') tab:any
  @Input('path') path:any
  @ViewChild('myModalClose') modalClose:any;
  @Input('label') label: string = "Total Premium";
  @Input('file') file: File | null;
  @Input('businessType') businessType: any;

  // @Input('label') label: any;
  filters: any;
  url: string;
  actionButtonLabel: string = ""
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  loading:boolean=false

  constructor(private http: HttpService, private http2: HttpClient,public snackBar:MatSnackBar,) { };

  onDownload(data: any) {
    this.loading = true
    let formData = null;
    if (this.tab=='transaction level computation') {
      this.filters = {
        'lob': this.lob,
        'rule_date': this.rule_date,
        'success_date': this.sDate, 
        'business_type': this.businessType
      }
      formData = new FormData();
      if (this.file) {
        formData.append('file', this.file);
      };
      this.url = `/services/api/v1/${this.path}/download_transactions`;
    }
    else if(this.tab=='disbursement mismatch'){
      this.filters={
        'lob':this.lob,
        'rule_date':this.rule_date,
        'success_date': this.sDate,
      }
      this.url=`/services/api/v1/${this.path}/mismatch_csv_download`;
    }
    else if(this.tab=='exception') {
      this.filters = {
        'lob': this.lob,
        'payment_id': this.payment_id,
        'rule_date':this.rule_date,
        'success_date': this.sDate,
      }
      this.url = `/services/api/v1/${this.path}/download_exceptions`;
    };
    const params = this.http.createParams({ "filters": this.filters });
    console.log(this.filters);
    // if (this.rule_date) {
     
    // }
    // else {

    // }
    let apiCall;
    if (formData) {
      if (!formData.entries().next().value) {
        formData = null;
      }
      apiCall = this.http2.post<any>(this.url, formData, { params: params, observe: 'body', responseType: 'text' as 'json' });
    } else {
      apiCall = this.http2.get<any>(this.url, { params: params, observe: 'body', responseType: 'text' as 'json' });
    }
    apiCall.subscribe(
      (response) => {
        const a = document.createElement("a");
        let blob = new Blob(["\ufeff", response]);
        let url = URL.createObjectURL(blob);
        a.href = url;
        a.download = this.lob + ".csv";
        document.body.appendChild(a);
        a.click();
        this.loading=false;
        document.body.removeChild(a);
        this.modalClose.nativeElement.click();
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  // partnerLevel(){
  //   const url = `/api/v1/transactions/approve_transactions`;
  //   const params = {'rule_date': this.rule_date,'lob':this.lob,'success_date':this.sDate};
  //   this.http.post(url,params).subscribe((res: any) => {
  //     this.open(res["message"])
  //   });
  // }
  open(response: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    this.snackBar.open(response, this.action ? this.actionButtonLabel : undefined, config);
  }
}


