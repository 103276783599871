<ng-container>
    <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
    <h2 class="scheduler">Scheduler</h2>
    <div class="container">
        <table>
            <thead>
              <tr>
                <th>Lob</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Modified Date</th>
                <th>State</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tableData">
                <td>{{ data.lob }}</td>
                <td>{{ data.start_date | date }}</td>
                <td>{{ data.end_date | date }}</td>
                <td>{{data.modified_at | date}}</td>
                <td>{{ data.state || "-"}}</td>
                <td>{{ data.active }}</td>

              </tr>
            </tbody>
          </table>
          <h2 class="mt-5 scheduler">Sync Count</h2>
          <table>
            <thead>
              <tr>
                <th>Lob</th>
                <th>Job Count</th>
                <th>Fetched</th>
                <th>Processed</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of syncCount | keyvalue ">
              <tr *ngIf="(item.value.published !== 0 || item.value.consumed !== 0) || item.value.job_count" >
                <td>{{item.key | titlecase}}</td>
                <td >{{item.value.job_count}}</td>
                <td >{{item.value.published}}</td>
                <td >{{item.value.consumed}}</td>
              </tr>
            </tbody>
          </table>
    </div>
</ng-container>


