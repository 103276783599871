<mat-label class="subHeading"
  >RB Business Type<span *ngIf="isRequired" class="star">*</span></mat-label
>
<!-- <mat-select
  #rbBusinessTypeSelect
  class="insurer"
  multiple
  formControlName="rbBusinessType"
>
  <mat-option
    *ngFor="let rbBusiness of rbBusinessTypeList"
    [value]="rbBusiness"
  >
    {{ rbBusiness }}
  </mat-option>
</mat-select> -->
<mat-select
  formControlName="rbBusinessType"
  class="business"
  multiple
  #rbBusinessTypeSelect
>
  <mat-option
    *ngFor="let businessType of businessTypes"
    [value]="businessType"
    >{{ businessType.replaceAll("_", "") | titlecase }}</mat-option
  >
</mat-select>
