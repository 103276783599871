import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BasicDialogInterface } from "./basic-dialog.interface";

@Component({
    selector: 'app-basic-dialog',
    templateUrl: './basic-dialog.component.html',
    styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent {
    messageData: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: BasicDialogInterface, public dialogRef: MatDialogRef<BasicDialogComponent>) {
        if (typeof (data.body) == 'object') {
            this.messageData = JSON.stringify(data.body);
        } else {
            this.messageData = data.body;
        }
    };

    closeDialog() {
        this.dialogRef.close();
    }

}