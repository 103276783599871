import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-auto-loan-bank-name',
  templateUrl: './auto-loan-bank-name.component.html',
  styleUrls: ['./auto-loan-bank-name.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AutoLoanBankNameComponent {
  @Input('required') required: boolean = false;
  @Input('multiple') multiple: boolean = true;
  controlName = 'insurer';
  bankNames: string[] = [];

  constructor(private http: HttpService, private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.getBankNames();
    if (!this.ctrlContainer.form.controls[this.controlName]) {
      this.ctrlContainer.form.addControl(this.controlName, new FormControl());
    }
  }

  getBankNames() {
    this.http.get('/services/api/v1/rb_masters/get-autoloan-bank_name').subscribe((res: any) => {
      this.bankNames = res;
    })
  }

  ngOnDestroy() {
    this.ctrlContainer.form.removeControl(this.controlName);
  }
}