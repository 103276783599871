import { Component, Input, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-partner-code-type',
  templateUrl: './partner-code-type.component.html',
  styleUrls: ['./partner-code-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PartnerCodeTypeComponent {
  @Input('required') isRequired = false;

  form: FormGroup;
  @Input('lob') lob: any;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;

  partnerCodeDataList: any = [];
  partnerCodeList: any = [];
  constructor(private ctrlContainer: FormGroupDirective) {}

  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'partnerCode',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('partnerCode', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.partnerCodeDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        this.partnerCodeList = [];
        this.partnerCodeList =
          this.partnerCodeDataList[lobSelected]['partner_code_type'];
      }
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('partnerCode');
  }
}
