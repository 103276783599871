import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { rangeValidator, alternateValidator } from 'src/app/core/utils/utility.functions';

export interface RangeFieldConfig {
  required: boolean,
  label: string,
  controlName: string,
  maxLength?: number,
  minLength?: number
};

@Component({
  selector: 'app-range-field',
  templateUrl: './range-field.component.html',
  styleUrls: ['./range-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RangeFieldComponent {
  @Input('config') config: RangeFieldConfig = null;

  rangeForm = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    const form = this.ctrlContainer.form;
    if (this.config.required) {
      this.rangeForm.controls['to'].addValidators([Validators.required, rangeValidator.bind(this, this.rangeForm), alternateValidator.bind(this, this.rangeForm)]);
      this.rangeForm.controls['from'].addValidators([Validators.required, rangeValidator.bind(this, this.rangeForm), alternateValidator.bind(this, this.rangeForm)]);
    }
    else {
      this.rangeForm.controls['to'].addValidators(rangeValidator.bind(this, this.rangeForm));
      this.rangeForm.controls['from'].addValidators(rangeValidator.bind(this, this.rangeForm));
    }

    if (form.controls[this.config.controlName]) {
      this.rangeForm.patchValue(form.controls[this.config.controlName].value);
      form.removeControl(this.config.controlName);
    }
    form.addControl(this.config.controlName, this.rangeForm);
  }

  ngOnDestroy(): void {
    this.ctrlContainer.form.removeControl(this.config.controlName);
  }
}
