import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
@Component({
  selector: 'app-state-city',
  templateUrl: './state-city.component.html',
  styleUrls: ['./state-city.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class StateCityComponent {
  @Input('showRto') showRto = true;
  @Input('required') isRequired = false;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService) { };

  stateList: any = [];
  cityList: any = [];


  stateControl = new FormControl([]);
  cityControl = new FormControl([]);


  ngOnInit() {
    this.getStates();
    if (this.isRequired) {
      this.stateControl.addValidators(Validators.required);
      this.cityControl.addValidators(Validators.required);

    }

    this.subscribeToValueChanges();

    this.form = this.ctrlContainer.form;
    if (this.form.controls['state']) {
      this.stateControl.patchValue(this.form.controls['state'].value);
    }
    if (this.form.controls['city']) {
      this.cityControl.patchValue(this.form.controls['city'].value);
    }


    this.form.addControl('state', this.stateControl);
    this.form.addControl('city', this.cityControl);

  }

  subscribeToValueChanges() {
    this.stateControl.valueChanges.subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getCities(val);
        } else {
          this.cityList = [];

        }
      }
    );

  }

  getStates() {
    const url = '/services/api/v1/rb_masters/life_states';
    this.http.get(url).subscribe((res: any) => {
      this.stateList = sort(res['data'], 'state_name');
    });
  }

  getCities(stateIds: any) {
    const url = `/services/api/v1/rb_masters/life_cities?state_id=${stateIds}`;
    this.http.get(url).subscribe((res: any) => {
      this.cityList = sort(res['data'], 'city_name');
    });
  }


  ngOnDestroy(): void {
    this.form.removeControl('state');
    this.form.removeControl('city')
  }

}
