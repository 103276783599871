import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-active-pos-field',
  templateUrl: './active-pos-field.component.html',
  styleUrls: ['./active-pos-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ActivePosFieldComponent implements OnInit{
  @Input('required') isRequired = false;

  form: FormGroup;
  activePosForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    if (this.isRequired) {
      this.activePosForm.controls['to'].addValidators([Validators.required,rangeValidator.bind(this, this.activePosForm)]);
      this.activePosForm.controls['from'].addValidators([Validators.required,rangeValidator.bind(this, this.activePosForm)]);
    } else {
      this.activePosForm.controls['to'].addValidators(rangeValidator.bind(this, this.activePosForm));
      this.activePosForm.controls['from'].addValidators(rangeValidator.bind(this, this.activePosForm));
    }
    this.form = this.ctrlContainer.form;

    this.form.addControl('active_pos', this.activePosForm);
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.activePosForm.controls['to'].value) < Number(this.activePosForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.activePosForm.controls['to'].invalid) {
  //     this.activePosForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.activePosForm.controls['from'].invalid) {
  //     this.activePosForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('active_pos');
  }

}
