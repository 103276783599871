import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderService } from '../../services/loader-service.service';

@Component({
  selector: 'app-base-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './base-loader.component.html',
  styleUrls: ['./base-loader.component.scss']
})
export class BaseLoaderComponent {
  text: string = "Searching...";

  constructor(public loaderService: LoaderService) { };
}
