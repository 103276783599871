<mat-label class="subHeading"
  >Policy Type<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select
  #policyTypeSelect
  class="insurer"
  multiple
  formControlName="policyType"
>
  <mat-option *ngFor="let policyType of policyTypeList" [value]="policyType">
    {{ policyType }}
  </mat-option>
</mat-select>
