import { Component } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-loan-bank-field',
  templateUrl: './loan-bank-field.component.html',
  styleUrls: ['./loan-bank-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class LoanBankFieldComponent {
  form: FormGroup;
  loanBankName = new FormControl();
  loanAmount = new FormControl();
  loanBank:any[]=[]
  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
  ) {}

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.form.addControl('bank_name', this.loanBankName);
    if (this.form.value['bank_name']) {
      this.loanBankName.setValue(this.form.value['bank_name']);
    }

    // this.form.addControl('approved_loan_amount', this.loanAmount);
    // if (this.form.value['approved_loan_amount']) {
    //   this.loanAmount.setValue(this.form.value['approved_loan_amount']);
    // }
    this.getBankName();
  }

  getBankName() {
    const url =`/services/api/v1/rb_masters/rb_loan_bank_names`
    this.http.get(url).subscribe((res: any) => {
      this.loanBank = res['data'];
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('bank_name');
    // this.form.removeControl('approved_loan_amount');
  }
}
