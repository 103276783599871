import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-policy-age',
  templateUrl: './policy-age.component.html',
  styleUrls: ['./policy-age.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PolicyAgeComponent implements OnInit{
  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('policy_age', new FormControl());
  }

  ngOnDestroy(): void {
    this.form.removeControl('policy_age');
  }
}
