import { Component, OnInit, Input} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-activation-bonus-field',
  templateUrl: './activation-bonus-field.component.html',
  styleUrls: ['./activation-bonus-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ActivationBonusFieldComponent implements OnInit {
  @Input('required') isRequired = false;
  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired){
      this.form.addControl('activation_bonus', new FormControl([], Validators.required));
    }

  }

  ngOnDestroy(): void {
    this.form.removeControl('activation_bonus');
  }

}
