import { Component} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { formatAllDates } from 'src/app/core/utils/utility.functions';
import { FormControlService } from 'src/app/shared/services/form-control.service';

@Component({
  selector: 'app-group-rto',
  templateUrl: './group-rto.component.html',
  styleUrls: ['./group-rto.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class GroupRtoComponent {

  form: FormGroup;
  groupList: object[] = []
  groupRtoControl = new FormControl([])

  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective,
    private controlStateService:FormControlService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.form.addControl('group', this.groupRtoControl);
    this.onValueChanges();
  }

  onValueChanges() {
    this.groupRtoControl.valueChanges.subscribe((groupRto: any) => {
      this.form.patchValue({
        "state": groupRto[0].state,
        "city": groupRto[0].city,
        "rto": groupRto[0].rto,
      });
    });

    this.form.controls['rule_date'].valueChanges.subscribe((ruleDate:any)=>{
      this.getGroupRto();
    });

    this.controlStateService.getControlObservable('insurer').subscribe((insurer:any)=>{
      this.getGroupRto();
    })
   
  }

  getGroupRto(){
    const insurer =this.form.controls['insurer'].value;
    const ruleDate=this.form.controls['rule_date'].value;
    const lob = this.router.url.split('/')[3];
    const params =formatAllDates({"lob":lob,"insurer":insurer,"rule_date":ruleDate});

    if(ruleDate !=null && insurer !=null ){
      const url=`/services/api/v1/group_rto/group_rto`;
      this.http.get(url, {'filters': params}).subscribe((res:any)=>{
        this.groupList = res.data.map((item: any) => {
          item.id = {
            state: item.state,
            city: item.city,
            rto: item.rto
          }
          return item;
        });
      })
    }
   
  }

  ngOnDestroy(): void {
    this.form.removeControl('group');
  }

}
