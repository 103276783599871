import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';;

@Component({
  selector: 'app-rb-insurer-nomenclature',
  templateUrl: './rb-insurer-nomenclature.component.html',
  styleUrls: ['./rb-insurer-nomenclature.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RbInsurerNomenclatureComponent implements OnInit {
  @Input('required') isRequired = false;
  @Input() lob: any;       // Define input property for Line of Business
  @Input() insurer: any;   // Define input property for Insurer
  status: boolean = false;
  recon: boolean = false;
  form: FormGroup;
  nomenclatureRb: any=  [];
  nomenclatureInsurer:any=[];

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnChanges(changes: any) {
    if (changes['lob']) {
      this.getNomenclatureRB();
    }
    if(changes['insurer']){
      this.getNomenclatureInsurer();
    }
  }

  ngOnInit(): void {

    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl('rb_nomenclature',new FormControl(null, Validators.required));
      this.form.addControl('insurer_nomenclature',new FormControl(null, Validators.required));
      this.form.addControl('status',new FormControl(null, Validators.required));
      this.form.addControl('recon',new FormControl(null, Validators.required));
    } else {
      this.form.addControl('rb_nomenclature', new FormControl());
      this.form.addControl('insurer_nomenclature', new FormControl());
      this.form.addControl('status', new FormControl());
      this.form.addControl('recon', new FormControl());
    }
    this.form.controls['rb_nomenclature'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['rb_nomenclature'].setValue([]);
      }
    });
    this.form.controls['insurer_nomenclature'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['insurer_nomenclature'].setValue([]);
      }
    });
    this.form.controls['status'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['status'].setValue([]);
      }
    });
    this.form.controls['recon'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['recon'].setValue([]);
      }
    });
  }


  active_status() {
    this.status = !this.status;

  }
  active_recon(){
    this.recon = !this.recon;
  }

  getNomenclatureRB(){
    if(this.lob){
      const url = `/services/api/v1/recon/insurer_nomenclature/fetch_nomenclature_headers/rb?lob=${this.lob}`;
      this.http.get(url).subscribe((res: any) => {
        this.nomenclatureRb = res['data'];
      });
    }
  }

  getNomenclatureInsurer(){
    if(this.lob && this.insurer){
      const url = `/services/api/v1/recon/insurer_nomenclature/fetch_nomenclature_headers/insurer?insurer=${this.insurer}&lob=${this.lob}`;
      this.http.get(url).subscribe((res:any) => {
        this.nomenclatureInsurer = res['data'];
      });
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('rb_nomenclature');
    this.form.removeControl('insurer_nomenclature');
    this.form.removeControl('status');
  }

}
