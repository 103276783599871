import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-vendor-premium-rsa',
  templateUrl: './vendor-premium-rsa.component.html',
  styleUrls: ['./vendor-premium-rsa.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class VendorPremiumRsaComponent implements OnInit, OnDestroy {
  form: FormGroup;
  vendorPremiumControl = new FormControl('');

  constructor(private ctrlContainer: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.ctrlContainer.form.addControl('vendor_premium', new FormControl());
  }


  ngOnDestroy(): void {
    this.form.removeControl('vendor_premium');
  }
}
