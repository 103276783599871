import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-annuity-type',
  templateUrl: './annuity-type.component.html',
  styleUrls: ['./annuity-type.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AnnuityTypeComponent {
  @Input('required') isRequired = false;

  form: FormGroup;

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl('annuity_type', new FormControl(null, Validators.required));
    } else {
      this.form.addControl('annuity_type', new FormControl(null));
    }

    this.form.controls['annuity_type'].valueChanges.subscribe((res: any) => {
      if (this.form.controls['deferment_period']) {
        if (res === 'deferred') {
          this.form.controls['deferment_period'].enable();
        } else {
          this.form.controls['deferment_period'].reset();
          this.form.controls['deferment_period'].disable();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.form.controls['deferment_period']) {
      this.form.controls['deferment_period'].enable();
    }
    this.form.removeControl('annuity_type');
  }

}
