import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { formatAllDates } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-activation-rule-field',
  templateUrl: './activation-rule-field.component.html',
  styleUrls: ['./activation-rule-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ActivationRuleFieldComponent implements OnInit {
  @Input('required') isRequired = true;

  activationRules: any = [];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService, public activatedRoute: ActivatedRoute) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl('activation_rule_name', new FormControl(null, Validators.required));
    } else {
      this.form.addControl('activation_rule_name', new FormControl(null));
    }
    this.getActivation(this.form.controls['rule_date'].value);

    this.form.controls['rule_date'].valueChanges.subscribe(val => {
      this.getActivation(val);
    });

  }

  getActivation(dateRange: object = {}) {
    dateRange = formatAllDates(dateRange)
    const url = '/services/api/v1/bp/criteria';
    this.http.get(url, { ...dateRange }).subscribe((res: any) => {
      this.activationRules = res['data'];
    });
    
}

  ngOnDestroy(): void {
    this.form.removeControl('activation_rule_name');
  }

}
