<label class="subHeading"
  >Product Name<span *ngIf="isRequired" class="star">*</span></label
>
<!-- <mat-select formControlName="plan_name" multiple>
    <mat-option *ngFor="let plan of plans" [value]="plan.rb_id">
        {{plan.plan_name}}
    </mat-option>
</mat-select> -->
<app-search-dropdown
  [dataSearchKey]="'name'"
  [dataValueKey]="'rb_id'"
  [dataSource]="product"
  [control]="productControl"
></app-search-dropdown>
