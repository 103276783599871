import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { Apiurls } from '../insurer-field/Api_urls';

@Component({
  selector: 'app-rb-business-type',
  templateUrl: './rb-business-type.component.html',
  styleUrls: ['./rb-business-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RbBusinessTypeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('policyTypeSelect', { static: true }) policyTypeSelect: MatSelect;
  @Input('required') isRequired = false;
  insurers: any;
  form: FormGroup;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('lob') lob: any;
  rbBusinessTypeDataList: any = [];
  rbBusinessTypeList: any = [];
  businessTypes: any = [];
  ngOnInit() {
    if (this.lob == 'life') {
      this.businessTypes = ['new_business', 'renewal', 'snb'];
    } else if (this.lob == 'health') {
      this.businessTypes = ['new_business', 'renewal', 'portability'];
    } else {
      this.businessTypes = ['new_business', 'renewal', 'rollover'];
    }
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'rbBusinessType',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('rbBusinessType', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.rbBusinessTypeDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        this.lob = changes['lob'].currentValue;
        this.businessTypes = [];
        if (this.lob == 'life') {
          this.businessTypes = ['new_business', 'renewal', 'snb'];
        } else if (this.lob == 'health') {
          this.businessTypes = ['new_business', 'renewal', 'portability'];
        } else {
          this.businessTypes = ['new_business', 'renewal', 'rollover'];
        }
        // this.rbBusinessTypeList = [];
        // this.rbBusinessTypeList =
        //   this.rbBusinessTypeDataList[lobSelected]['RB_Business_Type'];
      }
    }
    // if (changes['allRuleIdData']) {
    //   if (
    //     changes['allRuleIdData'].currentValue.rb_business_type !== undefined
    //   ) {
    //     let getUrl = window.location.href;
    //     if (getUrl.includes('payout-processing/main/edit-payout-rule')) {
    //       let rb_business_type =
    //         changes['allRuleIdData'].currentValue.rb_business_type;
    //       this.form.patchValue({
    //         rbBusinessType: rb_business_type,
    //       });
    //     }
    //   }
    // }
  }
  ngOnDestroy(): void {
    this.form.removeControl('rbBusinessType');
  }
}
