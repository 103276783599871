import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-premium-base-field',
  templateUrl: './premium-base-field.component.html',
  styleUrls: ['./premium-base-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PremiumBaseFieldComponent implements OnInit {
  @Input('lob') lob: string = 'motor';
  @Input('required') isRequired = false;
  @Input('isChild') isChild = false;

  form: FormGroup;

  premiumBaseTypes = ['1+1', '1+5'];

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    if(this.isChild){
      this.premiumBaseTypes = ['1+3', '1+5'];
    }
    if (this.lob == 'motor4W') {
      this.premiumBaseTypes = ['1+1', '1+3'];
    }
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl('premium_base', new FormControl(null, Validators.required));
    } else {
      this.form.addControl('premium_base', new FormControl());
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('premium_base');
  }
}
