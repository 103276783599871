import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { PLAN_API } from './api-constants';
import { FormControlService } from 'src/app/shared/services/form-control.service';

@Component({
  selector: 'app-policy-plan',
  templateUrl: './policy-plan.component.html',
  styleUrls: ['./policy-plan.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PolicyPlanComponent implements OnInit {
  @Input('insurerDependent') insurerDependent: boolean = false;
  @Input('planpolicytypeDependent') planpolicytypeDependent: boolean = false;
  @Input('lob') lob: any;
  @Input('required') isRequired = false;
  @Input('allRuleIdData') allRuleIdData: any;

  plans: any = [];
  form: FormGroup;
  plannameControl = new FormControl();

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    private controlStateService: FormControlService,
  ) { };

  ngOnInit(): void {
    if (this.isRequired) {
      this.plannameControl.addValidators(Validators.required);
    }

    this.form = this.ctrlContainer.form;
    if (this.form.value['plan_name']) {
      this.plannameControl.setValue(this.form.value['plan_name']);
      this.form.removeControl('plan_name');
    }
    this.form.addControl('plan_name', this.plannameControl);
    this.controlStateService.register({ controlName: 'plan_name', control: this.form.controls['plan_name'] });

    if (this.insurerDependent || this.planpolicytypeDependent) {
      this.subscribeValueChangesV2();
    } else {
      this.getPlans();
    }
    // this.subscribeValueChanges();
  }

  subscribeValueChanges() {
    if (this.insurerDependent) {
      if (this.planpolicytypeDependent) {
        this.form.controls['insurer'].valueChanges.subscribe((insurer: any) => {
          const planPolicyType = this.form.value['plan_policy_type'];
          if (insurer) {
            this.getPlans(insurer, planPolicyType);
          }
        });
        this.form.controls['plan_policy_type'].valueChanges.subscribe((policyPlanValue: any) => {
          const insurer = this.form.value['insurer'];
          if (policyPlanValue) {
            this.getPlans(insurer, policyPlanValue);
          }
        });
      } else {
        this.form.controls['insurer'].valueChanges.subscribe((insurer: any) => {
          if (insurer) {
            this.getPlans(insurer);
          }
        });
      }
    } else {
      this.getPlans();
    }
  }

  subscribeValueChangesV2() {
    if (this.planpolicytypeDependent && this.controlStateService.isRegistered('plan_policy_type')) {
      this.controlStateService.getControlObservable('plan_policy_type').subscribe((policyPlanValue: any) => {
        if (policyPlanValue) {
          const insurer = this.form.value['insurer'];
          this.getPlans(insurer, policyPlanValue);
        }
      });
    }

    if (this.insurerDependent && this.controlStateService.isRegistered('insurer')) {
      this.controlStateService.getControlObservable('insurer').subscribe((insurer: any) => {
        if (insurer) {
          const planPolicyType = this.form.value['plan_policy_type'];
          this.getPlans(insurer, planPolicyType);
        }
      });
    }
  }

  getPlans(insurer?: any, planpolicytype?: any) {

    const url = PLAN_API[this.lob as keyof typeof PLAN_API];

    const params: any = {};
    if (insurer) {
      params['insurer_id'] = insurer;
    }
    if (planpolicytype) {
      params['plan_policy_type'] = planpolicytype;
    }
    this.http.get(url, params).subscribe((res: any) => {
      this.plans = res['data'];
      if (this.form.value['plan_name_edit']) {
        this.plannameControl.setValue(this.form.value['plan_name_edit']);
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('plan_name');
  }
}
