import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { PayinOutService } from '../payin-out.service';

@Component({
  selector: 'app-payin-out-parent',
  templateUrl: './payin-out-parent.component.html',
  styleUrls: ['./payin-out-parent.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayinOutParentComponent {
  @Input() lob: string;

  form: FormGroup = new FormGroup({});

  constructor(private payoutService: PayinOutService, private ctrlContainer:FormGroupDirective) { };

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.form.addControl('payout', new FormGroup({}));
    this.form.addControl('payin', new FormGroup({}));
    this.form.addControl('actual_payin', new FormGroup({}));

    this.payoutService.form = this.form;
  }
}
