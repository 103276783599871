import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-not-in-plan-name-for-rsa',
  templateUrl: './not-in-plan-name-for-rsa.component.html',
  styleUrls: ['./not-in-plan-name-for-rsa.component.scss']
})
export class NotInPlanNameForRsaComponent {
  plan_name: any = [];
  @Input('allRuleIdData') allRuleIdData: any;
  form: FormGroup;
  @Input('required') isRequired = false;
  notInplannameControl = new FormControl();
  lobSelected: any = '';

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.form.controls['plan_name']) {
      this.notInplannameControl.setValue(this.form.controls['plan_name'].value);
    }
    this.form.addControl('not_in_plan_name', this.notInplannameControl);

    this.getPlanName();
  }
  getPlanName() {
    const url = '/services/api/v1/rb_masters/get-rsa-plan-names';
    this.http.get(url).subscribe((res: any) => {
      this.plan_name = res['data'];
      if (this.form.value['plan_name_edit']) {
        this.notInplannameControl.setValue(this.form.value['plan_name_edit']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('plan_name');
  }
}
