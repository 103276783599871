import { NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';


const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h23',
  hour: '2-digit',
  minute: '2-digit',
};

const MAT_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};



@Component({
  selector: 'app-contest-period',
  templateUrl: './contest-period.component.html',
  styleUrls: ['./contest-period.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
    { provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
  ]
})
export class ContestPeriodComponent {
  form: FormGroup;

  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;

  public start_dateControl = new FormControl(getMonthStartEnd()[0],Validators.required);
  public end_dateControl = new FormControl(getMonthStartEnd()[1],Validators.required);

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('contest_period', new FormGroup({
      'start_date': this.start_dateControl,
      'end_date': this.end_dateControl
    }));
  }
}

function getMonthStartEnd(){
  const today = new Date();
  let start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(),today.getMinutes()+5);
  let end = new Date(today.getFullYear(), today.getMonth()+1, 0, 23,59);

  return [start, end];
}