<div class="even">
<mat-label class="subHeading"
  >LOB<span *ngIf="isRequired" class="star">*</span></mat-label
>
<button class="side" (click)="form.controls['lob'].setValue(null)">Remove</button>
</div>
<mat-select *ngIf="!isMultiSelect; else multi" formControlName="lob" placeholder="All">
  <mat-option *ngFor="let lob of lobs" [value]="lob.value">
    {{ lob.name }}
  </mat-option>
</mat-select>

<ng-template #multi>
  <app-search-dropdown
      [dataSearchKey]="'name'"
      [dataValueKey]="'value'"
      [dataSource]="lobs"
      [control]="form.controls['lob']"
      [multiple]="true"
  ></app-search-dropdown>
</ng-template>
