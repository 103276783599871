import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-seating-capacity-field',
  templateUrl: './seating-capacity-field.component.html',
  styleUrls: ['./seating-capacity-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class SeatingCapacityFieldComponent implements OnInit {
  form: FormGroup;
  seatingCapacityForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.seatingCapacityForm.controls['to'].addValidators(rangeValidator.bind(this, this.seatingCapacityForm));
    this.seatingCapacityForm.controls['from'].addValidators(rangeValidator.bind(this, this.seatingCapacityForm));
    this.form = this.ctrlContainer.form;

    this.form.addControl('seating_capacity', this.seatingCapacityForm);

    (this.form.controls['seating_capacity'] as FormGroup).controls['from'].valueChanges.pipe(
      debounceTime(-200)
    ).subscribe((val: any) => {
      if (val && Number(val[0]) == 0) {
        (this.form.controls['seating_capacity'] as FormGroup).controls['from'].patchValue(val.slice(1));
      }
    });

    (this.form.controls['seating_capacity'] as FormGroup).controls['to'].valueChanges.pipe(
      debounceTime(-200)
    ).subscribe((val: any) => {
      if (val && Number(val[0]) == 0) {
        (this.form.controls['seating_capacity'] as FormGroup).controls['to'].patchValue(val.slice(1));
      }
    });
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.seatingCapacityForm.controls['to'].value) < Number(this.seatingCapacityForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.seatingCapacityForm.controls['to'].invalid) {
  //     this.seatingCapacityForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.seatingCapacityForm.controls['from'].invalid) {
  //     this.seatingCapacityForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('seating_capacity');
  }
}
