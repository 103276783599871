<mat-label class="subHeading"
  >Payment Type<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select
  #paymentTypeSelect
  class="insurer"
  multiple
  formControlName="paymentType"
>
  <mat-option *ngFor="let paymentType of paymentTypeList" [value]="paymentType">
    {{ paymentType }}
  </mat-option>
</mat-select>
