<div class="container p-3">
    <button class="close-button btn" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="row">
        <p class="h5 text-center text-danger">{{data.title}}</p>
    </div>
    <hr class="mt-0">
    <div class="row">
        <div class="col-12 text-center body">
            {{messageData}}
        </div>
    </div>
</div>