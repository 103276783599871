import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-payment-term',
  templateUrl: './payment-term.component.html',
  styleUrls: ['./payment-term.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PaymentTermComponent {
  @Input('required') isRequired = false;

  form: FormGroup;

  paymentTermForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    if(this.isRequired){
      this.paymentTermForm.controls['from'].addValidators([Validators.required, rangeValidator.bind(this, this.paymentTermForm),alternateValidator.bind(this,this.paymentTermForm)]);
      this.paymentTermForm.controls['to'].addValidators([Validators.required, rangeValidator.bind(this, this.paymentTermForm),alternateValidator.bind(this,this.paymentTermForm)]);
    }else{
      this.paymentTermForm.controls['from'].addValidators(rangeValidator.bind(this, this.paymentTermForm));
      this.paymentTermForm.controls['to'].addValidators(rangeValidator.bind(this, this.paymentTermForm));
    }
    this.form.addControl('payment_term', this.paymentTermForm);
    this.subscribeValueChanges();
  }

  subscribeValueChanges() {
    this.paymentTermForm.controls['from'].valueChanges.subscribe(val => {
      if (!val && val != null) {
        this.paymentTermForm.controls['from'].setValue(null);
      }
    });

    this.paymentTermForm.controls['to'].valueChanges.subscribe(val => {
      if (!val && val != null) {
        this.paymentTermForm.controls['to'].setValue(null);
      }
    });
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.paymentTermForm.controls['to'].value) < Number(this.paymentTermForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.paymentTermForm.controls['to'].invalid) {
  //     this.paymentTermForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.paymentTermForm.controls['from'].invalid) {
  //     this.paymentTermForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('payment_term');
  }
}
