<div class="row">
    <div class="col-md-4 col-12" [ngClass]="{'disabled': stateControl.disabled}">
        <mat-label class="subHeading">State<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'state_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="stateList"
            [control]="stateControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
    <div class="col-md-4 col-12" [ngClass]="{'disabled': cityControl.disabled}">
        <mat-label class="subHeading">City<span *ngIf="cityControl.hasValidator(requiredValidator)" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'city_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="cityList"
            [control]="cityControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
    <div class="col-md-4 col-12" *ngIf="showRto" [ngClass]="{'disabled': rtoControl.disabled}">
        <label class="subHeading">RTO<span *ngIf="rtoControl.hasValidator(requiredValidator)" class="star">*</span></label>
        <app-search-dropdown
            [dataSearchKey]="'name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="rtoList"
            [control]="rtoControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
</div>