import { Component } from '@angular/core';
import { StateService } from './shared/services/global.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'paymaster_fe';

  constructor(public stateService: StateService) { };

}
