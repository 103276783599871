import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ProductTypeComponent implements OnInit{
  productTypes = ['saving', 'protection', 'retirement', 'trop', 'ulip'];

  form: FormGroup;
  productTypeControl = new FormControl();

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    if (this.form.controls['product_type']) {
      this.productTypeControl.setValue(this.form.controls['product_type'].value);
    }
    this.form.addControl('product_type', this.productTypeControl)
  }

  ngOnDestroy(): void {
    this.form.removeControl('product_type');
  }
}
