<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div [ngClass]="loading ? 'secondary2': 'modal-content'">
            <div [ngClass]="loading ? 'secondary': 'modal-body row'">
                <div class="col-10 data">
                    <div class="row mt-2">
                        Total No. Of Transaction:{{transaction}}
                    </div>
                    <div class="row mt-2">
                        {{label}} (₹):{{premium}}
                    </div>
                </div>
                <div class="col-2 close">
                    <button #myModalClose type="button" class="material-icons" data-bs-dismiss="modal">close</button>
                </div>
            </div>
            <div [ngClass]="loading ? 'secondary': 'modal-footer'">
                <button type="button" class="btn btn-primary" (click)="onDownload($event)">Export CSV</button>
                <!-- <button *ngIf="rule_date" type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="partnerLevel($event)">{{label}}</button> -->
            </div>
            <div class="spinner-container" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
</div>