<mat-label class="subHeading"
  >Payment Mode<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select
  #payoutPaymentModeSelect
  class="insurer"
  multiple
  formControlName="payoutpaymentmode"
>
  <mat-option *ngFor="let paymentmode of paymentModeList" [value]="paymentmode">
    {{ paymentmode }}
  </mat-option>
</mat-select>
