import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-loan-approved-amount',
  templateUrl: './loan-approved-amount.component.html',
  styleUrls: ['./loan-approved-amount.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})

export class LoanApprovedAmountComponent implements OnInit{

  @Input('required') isRequired = false;
  form: FormGroup;
  laonApprovedAmount: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });
  constructor(private ctrlContainer: FormGroupDirective) { };
  ngOnInit(): void {
    if (this.isRequired) {
      this.laonApprovedAmount.controls['to'].addValidators([Validators.required, rangeValidator.bind(this, this.laonApprovedAmount), alternateValidator.bind(this, this.laonApprovedAmount)]);
      this.laonApprovedAmount.controls['from'].addValidators([Validators.required, rangeValidator.bind(this, this.laonApprovedAmount), alternateValidator.bind(this, this.laonApprovedAmount)]);
    }
    else {
      this.laonApprovedAmount.controls['to'].addValidators(rangeValidator.bind(this, this.laonApprovedAmount));
      this.laonApprovedAmount.controls['from'].addValidators(rangeValidator.bind(this, this.laonApprovedAmount));
    }

    this.form = this.ctrlContainer.form;

    if (this.form.controls['laonApprovedAmount']) {
      this.laonApprovedAmount.patchValue(this.form.controls['laonApprovedAmount'].value);
      this.form.removeControl('laonApprovedAmount');
    }

    this.form.addControl('laonApprovedAmount', this.laonApprovedAmount)
  }

  // change() {
  //   if (parseInt(this.laonApprovedAmount.controls['to'].value) >= 10000000) {
  //     this.laonApprovedAmount.controls['to'].setValue(10);
  //   }
  // }
  ngOnDestroy(): void {
    this.form.removeControl('laonApprovedAmount');
  }

}


