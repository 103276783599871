import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective, FormControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-renewal-payment-mode',
  templateUrl: './renewal-payment-mode.component.html',
  styleUrls: ['./renewal-payment-mode.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class RenewalPaymentModeComponent {
  controlName = 'renewal_payment_mode';

  paymentModes = [
    {value: 1, name: "Online Payment"},
    {value: 2, name: "Cash/Cheque/DD"},
    {value: 3, name: "ECS"},
    {value: 4, name: "NACH"},
    {value: 5, name: "SI"},
  ];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl(this.controlName, new FormControl([]));

    this.form.controls[this.controlName].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.patchValue({ [this.controlName]: [] });
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }

}
