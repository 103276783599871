import { Component, Input, OnInit } from '@angular/core';
import { Validators, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-zero-dep',
  templateUrl: './zero-dep.component.html',
  styleUrls: ['./zero-dep.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ZeroDepComponent implements OnInit {
  @Input('required') isRequired: boolean = false;

  form: FormGroup;
  ZeroDepForm = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
          this.ZeroDepForm.controls.from.addValidators(Validators.required);
          this.ZeroDepForm.controls.to.addValidators(Validators.required);
    }

    this.ZeroDepForm.controls['to'].addValidators(rangeValidator.bind(this, this.ZeroDepForm));
    this.ZeroDepForm.controls['from'].addValidators(rangeValidator.bind(this, this.ZeroDepForm));

    if (this.form.controls['zero_dep']) {
      this.ZeroDepForm.patchValue(this.form.controls['zero_dep'].value);
      this.form.removeControl('zero_dep');
    }

    this.form.addControl('zero_dep', this.ZeroDepForm);

  }

  ngOnDestroy(): void {
    this.form.removeControl('zero_dep');
  }
}
