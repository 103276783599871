<table [formGroup]="form" class="refundDetail">
    <tr>
        <th>Pay Out</th>
        <th>Percentage(%)</th>
        <th>Amount</th>
    </tr>
    <tr [formGroup]="renewalPayout.controls[3]">
        <td>Net Premium/ Per Policy</td>
        <td><input formControlName="percent" placeholder="percent" appNumberOnly appPercentOnly></td>
        <td><input formControlName="amount" [maxlength]="5" placeholder="amount" appNumberOnly></td>
    </tr>
    <tr>
        <th>Pay In</th>
        <th>Percentage(%)</th>
        <th>Amount</th>
    </tr>
    <tr [formGroup]="renewalPayin.controls[3]">
        <td>Net Premium/ Per Policy</td>
        <td><input formControlName="percent" placeholder="percent" appNumberOnly appPercentOnly></td>
        <td><input formControlName="amount" [maxlength]="5" placeholder="amount" appNumberOnly></td>
    </tr>
</table>

