<table *ngIf="data && data.length">
    <tr>
        <th>S.No</th>
        <th *ngFor="let header of keys">
            {{header.replace('_',  ' ') | titlecase}}
        </th>
    </tr>
    <tr *ngFor="let historyObj of data; let i = index">
        <td>{{i+1}}</td>
        <td *ngFor="let value of getValues(historyObj)">
            {{value}}
        </td>
    </tr>
</table>