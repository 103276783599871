import { Component } from '@angular/core';
import { StateService } from 'src/app/shared/services/global.service';
import { menuOptions } from './navbar-options';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
 
})
export class NavBarComponent {
  menuOptions: any

  constructor(private  state:StateService)
  {
    this.state.loaded$.subscribe(val => {
      if (!val) { return };
      this.menuOptions = menuOptions;
      
      console.log("navbar")
    })
  }

}
