export const Apiurls={
    'motor':'/services/api/v1/rb_masters/insurers',
    'motor4W': '/services/api/v1/rb_masters/insurers',
    'life': '/services/api/v1/rb_masters/life_insurer_list',
    'cv': '/services/api/v1/rb_masters/insurers',
    'health': '/services/api/v1/rb_masters/health_insurer_list',
    'travel': '/services/api/v1/rb_masters/travel_insurer_list',
    'sme': '/services/api/v1/rb_masters/sme_insurer',
    'finsure': '/services/api/v1/rb_masters/finsure_insurer',
    'misp': '/services/api/v1/rb_masters/insurers',
    // 'finsure_cards': '/services/api/v1/rb_masters/finsure_credit_card_banks',
    'bp': '',
    'rdp': ''
};
