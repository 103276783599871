import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { Apiurls } from '../insurer-field/Api_urls';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PaymentTypeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('paymentTypeSelect', { static: true })
  paymentTypeSelect: MatSelect;
  @Input('required') isRequired = false;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('lob') lob: any;
  form: FormGroup;
  paymentTypeList: any = ["Cheque", "Online", "Cash","Float","Credit Card","Net Banking","Debit Card"];
  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'paymentType',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('paymentType', new FormControl());
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('paymentType');
  }
}
