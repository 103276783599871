import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-product-type-field',
  templateUrl: './product-type-field.component.html',
  styleUrls: ['./product-type-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ProductTypeFieldComponent {
  @Input('required') isRequired = false;
  @Input('multiple') isMultiSelect: boolean = true;
  @Input('allRuleIdData') allRuleIdData: any;
  // productTypes =[
  //   "saving",
  //   "term",
  //   "trop",
  //   "retirement",
  //   "ulip"
  // ];
  lobSelected: any = '';
  productTypes: any = [];

  // productTypeControl = new FormControl();
  form: FormGroup;
  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl(
        'product_type',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('product_type', new FormControl());
    }
    this.getProductType();
  }

  getProductType() {
    const url = '/services/api/v1/rb_masters/life_product_name';
    this.http.get(url).subscribe((res: any) => {
      this.productTypes = res['data'];
    });
  }
  selectionChange(event: any) {
    if (!this.isMultiSelect && event.isUserInput && event.source.selected) {
      this.form.controls['product_type'].setValue([event.source.value]);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('product_type');
  }
}
