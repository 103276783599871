import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { RolesPermissions, UserPermissions } from '../../models/user.permissions'

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  permissions = new Array<UserPermissions>;

  constructor(private http: HttpClient) { };

  getUserPermissions(): Observable<UserPermissions> {
    const url = '/services/api/v1/accounts/user_roles';
    return this.http.get<UserPermissions>(url);
  }
  getAllPermissions(): Observable<RolesPermissions[]> {
    const url = '/services/api/v1/roles/permissions';
    return this.http.get<RolesPermissions[]>(url);
  }

}

