import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-trailer-idv-field',
  templateUrl: './trailer-idv-field.component.html',
  styleUrls: ['./trailer-idv-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class TrailerIdvFieldComponent implements OnInit {
  form: FormGroup;
  trailerIdvForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.trailerIdvForm.controls['to'].addValidators(rangeValidator.bind(this, this.trailerIdvForm));
    this.trailerIdvForm.controls['from'].addValidators(rangeValidator.bind(this, this.trailerIdvForm));

    if (this.form.controls['vehicle_trailer_idv']) {
      this.trailerIdvForm.patchValue(this.form.controls['vehicle_trailer_idv'].value);
      this.form.removeControl('vehicle_trailer_idv');
    }

    this.form.addControl('vehicle_trailer_idv', this.trailerIdvForm);
  }

  ngOnDestroy(): void {
    this.form.removeControl('vehicle_trailer_idv');
  }
}
