import { Component } from '@angular/core';
import { RangeFieldConfig } from '../range-field/range-field.component';

@Component({
  selector: 'app-loan-disburse-amount',
  templateUrl: './loan-disburse-amount.component.html',
  styleUrls: ['./loan-disburse-amount.component.scss']
})
export class LoanDisburseAmountComponent {
  config: RangeFieldConfig = {
    required: false,
    label: 'Loan Disburse Amount',
    controlName: 'loan_disburse_amount',
  }
}
