<label class="subHeading"
  >Plan Name<span *ngIf="isRequired" class="star">*</span></label
>
<!-- <mat-select formControlName="plan_name" multiple>
    <mat-option *ngFor="let plan of plans" [value]="plan.rb_id">
        {{plan.plan_name}}
    </mat-option>
</mat-select> -->
<app-search-dropdown
  [dataSearchKey]="'plan_name'"
  [dataValueKey]="'plan_name_id'"
  [dataSource]="plan_name"
  [control]="plannameControl"
></app-search-dropdown>
