<ng-container *ngFor="let obj of payin_payout_types">
    <div class="table-wrapper refund" [formGroupName]="obj.ctrlName">
        <table class="round-table refundDetail">
            <tr>
                <th>{{obj.displayName | titlecase}}</th>
                <th>Percentage(%)</th>
                <th>Amount(₹)</th>
            </tr>
            <tr *ngFor="let payoutBaseObj of payoutBase" [formGroupName]="payoutBaseObj.value">
                <td>{{payoutBaseObj.title}}</td>
                <td><input
                        type="text"
                        formControlName="percent"
                        class="per"
                        placeholder="Type"
                        appNumberOnly
                        appPercentOnly
                        #percent
                        (keyup)="percent.value ? amount.value = null: null"
                    />
                </td>
                <td><input
                        type="text"
                        formControlName="amount"
                        class="amount"
                        placeholder="Type"
                        appNumberOnly
                        #amount
                        (keyup)="amount.value ? percent.value = null: null"
                    />
                </td>
            </tr>
        </table>
    </div>
    <hr>
</ng-container>