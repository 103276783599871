import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-payin-percentage-field',
  templateUrl: './payin-percentage-field.component.html',
  styleUrls: ['./payin-percentage-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayinPercentageFieldComponent implements OnInit{
  label: string = 'Payin Percentage';
  isFlatAmount: boolean;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('payin_value', new FormControl());
    this.form.controls['payin_type'].valueChanges.subscribe((val: any) => {
      if (val == 1) {
        this.label = 'Payin Percentage';
        this.isFlatAmount = false;
      }
      if (val == 2) {
        this.label = 'Payin Amount';
        this.isFlatAmount = true;
      }
    });
  }
}
