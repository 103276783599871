<form [formGroup]="carry">
  <div>
    <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
    <div class="ml-3">
      <app-date-field [flag]="flag" class="m-3"></app-date-field>
      <h3 class="evi">Evidence Capture<span class="star">*</span></h3>
      <app-file-field class="file" (fileUploaded)="onFileChange($event)"></app-file-field>
      <button  class="submit" type="button" (click)="submit()" [disabled]="!carry.valid">Submit</button>
    </div>
</div>
</form>