import { RESOURCES } from "src/app/core/utils/constants";


export const menuOptions = [
  // {
  //   title: 'Dashboard',
  // },
  {
    title: 'Admin',
    insides: [
      {
        name: 'User',
        link: '/admin/user',
        module :RESOURCES.USER_CREATION
      },
      {
        name: 'Role',
        link: '/admin/roles',
        module:RESOURCES.CREATION_OF_ROLE
      },
      {
        name: 'Disbursement Company Configuration',
        link: '/admin/company',
        module: RESOURCES.ADD_COMPANY
      },

      {
        name: 'Approval Matrix',
        link: '/admin/escalation',
        module:RESOURCES.CREATION_OF_ESCALATION_TREE
      },
      {
        name:'Multi Year Insurer Configuration',
        link:'/admin/child',
        module:RESOURCES.MULTI_YEAR
      },
      {
        name:'InstaPay Configuration',
        link:'/admin/insta-payout',
        module:RESOURCES.INSTA_PAY
      },
      {
        name:'Create Group RTO',
        link:'/admin/group-rto',
        module:RESOURCES.CREATE_GROUP_RTO
      },
      {
        name:'Restrict InstaPay Partner',
        link:'/admin/restrict-instapay-partner',
        module:RESOURCES.RESTRICT_INSTAPAY_OPTION
      },
    ],
  },
  {
    title: 'Batch Processing',
    insides: [
      {
        name: 'Rule Creation',
        link:'/dashboard/bulk-upload',
        module: RESOURCES.BULK_UPLOAD_CREATE,
      },
      {
        name: 'Rule Edit',
        link:'/dashboard/bulk-upload-edit',
        module: RESOURCES.BULK_UPLOAD_EDIT,
      },
      {
        name: 'Transaction Vs Rule Mismatch',
        link: '/dashboard/bulk-upload-mismatch',
        module: RESOURCES.BULK_UPLOAD_MISMATCH,
      },
      {
        name: 'Transaction Vs Rule Match',
        link: '/dashboard/bulk-upload-rule-match',
        module: RESOURCES.BULK_UPLOAD_MATCH,
      },
      {
        name: 'Partner Level ITR Update',
        link: '/dashboard/partner-sheet',
        module: RESOURCES.ITR_UPLOAD,
      },
      {
        name: 'Download Transaction',
        link: '/dashboard/transaction-download',
        module: RESOURCES.BULK_UPLOAD_CREATE,
      },
      // {
      //   name: 'CT/CF Request',
      //   link: '/approval/console-transfer',
      //   module:42
      // },
      // {
      //   name: 'CT/CF Approve & Reject',
      //   link: '/approval/console-reject-approve',
      // },
    ],
  },
  {
    title: 'Dashboard',
    insides: [
      {
        name: 'Transaction Search',
        link:'/dashboard/transaction-search',
        module: RESOURCES.TRANSACTION_SEARCH,
      },
      {
        name:  'Rule Stats',
        link:'approval/approved',
        module: RESOURCES.CARRY_FORWARD,
      },
    ],
  },
  {
    title: 'Console & Carry Forward Rule',
    insides: [
      {
        name: 'Pending For Approval',
        link:'/approval/console-transfer',
        mmodule: RESOURCES.CONSOLE_TRANSFER,
      },
      {
        name:  'Console & Carry Forward Stats',
        link: '/approval/console-reject-approve',
        module: RESOURCES.CONSOLE_TRANSFER_APPROVAL_REJECT,
      },
    ],
  },
  {
    title: 'Contest',
    insides: [
      {
        name: 'Contest Rule',
        link: '/rules/overview/contest',
        // module:29
      },
    ],
  },
  {
    title: 'LOBs',
    insides: [
      {
        name: 'Two Wheeler',
        link: '/rules/overview/motor',
        // module:9
      },
      {
        name: 'Four Wheeler',
        link: '/rules/overview/motor4W',
        // module:9
      },
      {
        name: 'Commercial Vehicle',
        link: '/rules/overview/cv',
        // module:9
      },
      {
        name: 'Health',
        link: '/rules/overview/health',
        // module:9
      },
      {
        name: 'MISP',
        link: '/rules/overview/misp',
        // module:9
      },
      {
        name: 'BP/Leader Care/SBP',
        link: '/rules/overview/bp',
        // module:9
      },
      {
        name: 'Super BP',
        link: '/rules/overview/super_bp',
      },
      {
        name: 'Leadercare',
        link: '/rules/leadercare/dashboard',
      },
      {
        name: 'RDP',
        link: '/rules/overview/rdp',
      },
      {
        name: 'Finsure',
        link: '/rules/overview/finsure' ,
        // module:9
      },
      {
        name: 'Auto Loans',
        link: '/rules/overview/auto_loans' ,
        // module:9
      },
      {
        name: 'Travel',
        link: '/rules/overview/travel',
        // module:9
      },
      {
        name: 'Life',
        link: '/rules/overview/life',
        // module:9
      },
      {
        name: 'SME',
        link: '/rules/overview/sme',
        // module:9
      },
      {
        name: 'Road Side Assist',
        link: '/rules/overview/road_side_assist',
        // module:9
      },
    ],
  },
  // {
  //   title: 'Payout Engine',
  // },
  {
    title: 'Payout Processing',
    insides: [
      {
        name: 'Disbursement Rule',
        link: '/payout-processing/payout',
        module: RESOURCES.DISBURSEMENT_RULE
      },
      {
        name: 'Transaction Level',
        link: '/payout-processing/transactions',
        module:RESOURCES.TRANSACTION_LEVEL_ACCESS
      },
      {
        name:'Instant Transaction',
        link: '/payout-processing/instant-payout',
        module: RESOURCES.TRANSACTION_LEVEL_ACCESS,
      },
      {
        name: 'Partner Level',
        link: '/payout-processing/partner-level',
        module:RESOURCES.PARTNER_LEVEL_ACCESS
      },
      {
        name: 'Company Level',
        link: '/payout-processing/company-level',
        module:[RESOURCES.DISBURSEMENT_L1_APPROVAL, RESOURCES.DISBURSEMENT_L2_APPROVAL, RESOURCES.DISBURSEMENT_L2_APPROVAL]
      }
    ],
  },
  {
    title: 'Pay In Processing',
    insides:[
      {
        name: 'Pay In',
        link: 'pay-in-processing/payin',
      },
      {
        name: 'Mapping',
        link: 'pay-in-processing/mapping',
      },
      {
        name: 'Reconciliation',
        link: 'pay-in-processing/reconciliation',
      },
    ]
  },
  {
    title: 'History',
    insides:[
      {
        name:'Disbursement History',
        link: '/disbursement-history/dashboard',
        module: RESOURCES.DISBURSEMENT_HISTORY
      },
      {
        name:'Payout Disbursement Report',
        link: '/disbursement-history/transaction-level-report',
        module: RESOURCES.DISBURSEMENT_HISTORY
      }
    ]
  },
  {
    title:'Outstanding',
    insides:[
      {
        name:'Outstanding Sheets',
        link: '/outstanding/sheets',
        module: RESOURCES.OUTSTANDING
      },
      {
        name:'Recovery Clearance',
        link: '/outstanding/recovery-clearance',
        module: RESOURCES.RECOVERY_CLEARANCE
      }
    ]
  },
  // {
  //   title: 'Reports',
  // },
  {
    title: 'Finance',
    insides: [
      {
        name: 'Finance Report',
        link: '/finance/finance-report/',
        module: RESOURCES.BANK_SHEET
      }
    ],
  },
  {
    title: 'Statements',
    insides: [
      {
        name: 'Partner Statement',
        link: '/payout-statement/partner-statement/',
        module: RESOURCES.PARTNER_STATEMENT
      }
    ],
  },


  // {
  //   title: "Logout"
  // },
];
