import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-payout-type-field',
  templateUrl: './payout-type-field.component.html',
  styleUrls: ['./payout-type-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayoutTypeFieldComponent implements OnInit {
  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('payout_type', new FormControl(null, Validators.required));
  }

  ngOnDestroy(): void {
    this.form.removeControl('payout_type');
  }
}
