<div class="row align-items-center header-bar">
    <div class="col-6">
        <a [href]="homeUrl">
            <img src="../../../assets/logos/RB-Logo.svg" alt="rb_logo">
        </a>
    </div>
    <div class="col-6 row justify-content-end align-items-center">
        <div class="col-1 d-none d-sm-block">
            <button class="scheduler" (click)="openConsoleDialog()">
            <img src="'../../../assets/icons/circle-info-solid.svg" alt=""/>
            </button>
        </div>
        <app-notification-bell class="col-1 d-none d-sm-block m-3"></app-notification-bell>
        <app-profile-avatar class="col-2 m-3"></app-profile-avatar>
    </div>
</div>