import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { Observable, startWith, debounceTime, distinctUntilChanged, filter, of, switchMap, map } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';
import { AutoCompleteSearchDropdownOptionsI } from './autocomplete-field.interface';


/**
 * @summary Creates dropdown with text based api searching of data.
 * @description Autocomplete field that populates dropdown by searching the data with search value provided.
 * @param formControlName name of the form control in case of using the field inside a form.
 * @param config Set of options to provide to make the component work. Follows AutoCompleteSearchDropdownOptionsI
 * @example <app-autocomplete-field [formControlName]="'state'" [config]="AutoCompleteSearchDropdownOptions"></app-autocomplete-field>
 */
@Component({
  selector: 'app-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AutocompleteFieldComponent implements OnInit, OnChanges{
  /** @description Boolean to represent wheather the form control has been patched programatically. Or when patched through parent form. */
  private isFormPatched: boolean = false;

  /** @description Boolean to represent wheather the dropdown has been touched by the user. */
  private isControlTouched: boolean = false;

  @ViewChild('inputField') searchTextBox: ElementRef;

  @Input('controlName') formControlName: string;
  @Input('config') configOption: AutoCompleteSearchDropdownOptionsI;

  minCharacters: number = 3;
  required: boolean = false;
  dataIdKey: string = '';
  dataValueKey: string = '';

  inputControl = new FormControl();
  selectFormControl = new FormControl([]);

  filteredOptions: Observable<any> = of([]);
  private searchValueChanges: Observable<any>;

  private selectedData: any[] = [];
  private selectedValuesSet: Set<any> = new Set();

  constructor(private http: HttpService, private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.setConfig();
    this.setInputChanges();
    this.setSelectControlValueChanges();

    if (this.ctrlContainer?.form && this.formControlName) {
      this.ctrlContainer.form.addControl(this.formControlName, this.selectFormControl);
    } else if (!this.formControlName) {
      console.warn('Parent form detected but no form control name specified');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configOptions']) {
      this.setConfig();
    }
  }

  /**
   * @summary Reads the config and sets the corosponding class variables.
   */
  private setConfig() {
    this.required = this.configOption.requiredField;
    this.dataIdKey = this.configOption.dataIdKey;
    this.dataValueKey = this.configOption.dataNameKey;
    if (this.configOption.minTextLength || this.configOption.minTextLength == 0) {
      this.minCharacters = this.configOption.minTextLength;
    }
  }

  /**
   * @summary Handles value changes for search text box control
   * @description Triggers search api only when input length is is greater than or equal to min characters.
   */
  private setInputChanges() {
    this.searchValueChanges = this.inputControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map((val)=> val ? val.trim() : ''),
      switchMap(val => {
        if (val.length >= this.minCharacters) {
          console.log(`Searching with value: ${val}`);
          return this.searchData(val.toLowerCase().trim());
        } else {
          return of([]);
        }
      })
    )
  }

  /**
   * @summary Handles value changes for select form control
   * @description When the control is patched, we need to backfill the selectedData array and selectedValuesSet.
   * We only need to operate when control is patched, ie; control is not yet modified by the user.
   */
  private setSelectControlValueChanges() {
    this.selectFormControl.valueChanges.subscribe(val => {
      if (!this.isFormPatched && !this.isControlTouched && val && val.length) {
        this.isFormPatched = true;
        this.getData();
      }
    });
  }


  /**
   * @summary Handles event when dropdown is opened or closed.
   * @description When dropdown is opened, filtered options is set to observable array obtained after searching.
   * When dropdown is closed, filtered options is set to selected data as observable.
   * Form Control's value is set once the dropdown is closed.
   * @param opened Represents wheather dropdown is opened. true when opened. false, when closed
   */
  openedChange(opened: boolean) {
    if (opened) {
      this.searchTextBox.nativeElement.focus();
      this.filteredOptions = this.searchValueChanges;
    } else {
      this.inputControl.setValue('');
      this.filteredOptions = of(this.selectedData);
      this.selectFormControl.setValue([...this.selectedValuesSet]);
    }
  }

  /**
   * @param event - selectionChangeEvent, fired when mat option is checked/unchecked.
   * @param option - Data object for that mat-option. @example (event, {'rb_id': 1,'name':'gurugram'})
   * @description Only executed when the input is from user.
   * Since selectionChange is also fired when form control value is changed. We need to ignore selectionChange event in case of form control.
   * @summary Value is checked in the set and then updated in the set and selectedData
   */
  selectionChange(event: any, option: any) {
    if (event.isUserInput) {
      this.isControlTouched = true;
      const value = event.source.value;
      if (event.source.selected && !this.selectedValuesSet.has(value)) {
        this.selectedData.push(option);
        this.selectedValuesSet.add(value);
      } else if (!event.source.selected && this.selectedValuesSet.has(value)) {
        const existingOpt = this.getExistingOptionById(option[this.dataIdKey]);
        const existingOptidx = this.selectedData.indexOf(existingOpt);
        this.selectedData.splice(existingOptidx, 1);
        this.selectedValuesSet.delete(value);
      }
    }
  }

  /**
   * @description Calls the API with search value to retreieve results matching the query
   * @param value Value to search.
   * @example searchData('guru')
   * @returns Observable
   */
  private searchData(value: string) {
    console.log(this.formControlName, value);
    
    if (value) {
      const url = this.configOption.searchApiUrl;
      const queryParamKey = this.configOption.queryParamKey;
      return this.http.get(url, { [queryParamKey]: value }).pipe(
        filter((option: any) => !this.getExistingOptionById(option[this.dataIdKey])),
      );
    }
    return of([])
  }

  /**
   * @description Since form control value is an array of numbers, we need to retreive the data obj
   * for those values to populate our selectedData array.
   * @summary Calls the data api which returns an array of data objects
   * @example DATA API response: [{'rb_id': 1, 'name':'gurugram'}]
   */
  private getData() {
    const ids = this.selectFormControl.value;
    const url = this.configOption.dataApiUrl;
    this.http.get(url, { 'ids': ids }).subscribe((res: any) => {
      this.selectedData = res
      this.selectedValuesSet = new Set(this.selectedData.map(val => val[this.dataIdKey]));
      this.filteredOptions = of(this.selectedData);
    });
  }

  private getExistingOptionById(id: number) {
    const idKey = this.dataIdKey;
    return this.selectedData.find((item: any) => item[idKey] == id);
  }

  private getExistingOptionByValue(value: string) {
    const valueKey = this.dataValueKey;
    return this.selectedData.find((item: any) => item[valueKey] == value);
  }

  /**
   * @summary Just returns a string of names seperated by '\n' to populate the tooltop button
   */
  get toolTipData() {
    return this.selectedData.map((val, idx) => `${idx + 1}. ${val[this.dataValueKey]}`).join('\n');
  }

}
