import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-gvw-field',
  templateUrl: './gvw-field.component.html',
  styleUrls: ['./gvw-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class GVWFieldComponent implements OnInit {
  form: FormGroup;
  gvwForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.gvwForm.controls['to'].addValidators(rangeValidator.bind(this, this.gvwForm));
    this.gvwForm.controls['from'].addValidators(rangeValidator.bind(this, this.gvwForm));

    if (this.form.controls['gvw']) {
      this.gvwForm.patchValue(this.form.controls['gvw'].value);
      this.form.removeControl('gvw');
    }

    this.form.addControl('gvw', this.gvwForm);
  }

  ngOnDestroy(): void {
    this.form.removeControl('gvw');
  }
}
