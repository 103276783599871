import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { rangeValidator, alternateValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-idv-field',
  templateUrl: './idv-field.component.html',
  styleUrls: ['./idv-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class IDVFieldComponent implements OnInit {
  form: FormGroup;
  idv: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });
  @Input('required') isRequired = false;
  constructor(private ctrlContainer: FormGroupDirective) {}

  ngOnInit(): void {
    this.form=this.ctrlContainer.form;
    this.form.addControl('idv',this.idv)
    if(this.isRequired){
     this.idv.controls['to'].addValidators([Validators.required,rangeValidator.bind(this,this.idv),alternateValidator.bind(this,this.idv)]);
     this.idv.controls['from'].addValidators([Validators.required,rangeValidator.bind(this,this.idv),alternateValidator.bind(this,this.idv)]);
    }
    else{
     this.idv.controls['to'].addValidators(rangeValidator.bind(this,this.idv));
     this.idv.controls['from'].addValidators(rangeValidator.bind(this,this.idv));
    }
   }

  ngOnDestroy(): void {
    this.form.removeControl('idv');
  }
}
