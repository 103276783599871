import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
@Component({
  selector: 'app-product-finsure-field',
  templateUrl: './product-finsure-field.component.html',
  styleUrls: ['./product-finsure-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ProductFinsureFieldComponent {
  @Input('lob') lob: any;

  product: any = [];

  form: FormGroup;
  @Input('required') isRequired = false;
  @Input('allRuleIdData') allRuleIdData: any;
  productControl = new FormControl();
  lobSelected: any = '';
  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    if (this.form.controls['product_name']) {
      this.productControl.setValue(this.form.controls['product_name'].value);
      this.form.removeControl('product_name');
    }

    this.form.addControl('product_name', this.productControl);
    this.getProduct();
  }
  getProduct() {
    const url = '/services/api/v1/rb_masters/finsure_product';
    this.http.get(url).subscribe((res: any) => {
      this.product = res['data'];
      if (this.form.value['product_name']) {
        this.productControl.setValue(this.form.value['product_name']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('product_name');
  }
}
