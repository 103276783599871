import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';


@Component({
  selector: 'app-credit-card-name',
  templateUrl: './credit-card-name.component.html',
  styleUrls: ['./credit-card-name.component.scss'],
    viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CreditCardNameComponent {
    @Input('lob') lob: any;

  cc_name: any = [];
  @Input('allRuleIdData') allRuleIdData: any;
  form: FormGroup;
  @Input('required') isRequired = false;
  ccNameControl = new FormControl();
  lobSelected: any = '';

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('cc_name', this.ccNameControl);
    if (this.form.value['cc_name']) {
      this.ccNameControl.setValue(this.form.value['cc_name']);
    }

    this.getCCName();
  }

  getCCName() {
    const url = '/services/api/v1/rb_masters/finsure_credit_card_names';
    this.http.get(url).subscribe((res: any) => {
      this.cc_name = res['data'];
      if (this.form.value['cc_name_edit']) {
        this.ccNameControl.setValue(this.form.value['cc_name_edit']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('cc_name');
  }



}
