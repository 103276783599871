<!-- <mat-label class="subHeading">State</mat-label>
<mat-select class="state" [formControl]="selectFormControl">
    <mat-option *ngFor="let stateObj of stateList" [value]="stateObj.rb_id">{{ stateObj.state_name }}</mat-option>
</mat-select> -->


<app-search-dropdown
    [dataSearchKey]="'state_name'"
    [dataValueKey]="'rb_id'"
    [dataSource]="stateList"
    [control]="selectFormControl"
></app-search-dropdown>
