<div class="table1">
  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container
      *ngFor="let disCol of tableColumns; let colIndex = index"
      matColumnDef="{{ disCol.columnDef }}"
    >
      <th mat-header-cell *matHeaderCellDef class="header">
        <mat-checkbox
          *ngIf="disCol.isSelect"
          (change)="selectAll($event.checked)"
        ></mat-checkbox>
        {{ disCol.header | titlecase }}
      </th>

      <td mat-cell *matCellDef="let element" class="{{element.class}}">
        <span *ngIf="!disCol.isLink && !disCol.isSelect && !disCol.hasBtn; else link">
          {{ disCol.cell(element) | titlecase | slice : 0 : 74 }}
        </span>

        <ng-template #link>
          <a
            *ngIf="!disCol.isSelect && !disCol.hasBtn; else selection"
            [href]="[disCol.url(element)]"
            target="_blank"
          >
            {{ disCol.cell(element) }}
          </a>
        </ng-template>
        <mat-checkbox
          #checkboxes
          customId="{{ disCol.cell(element) }}"
          *ngIf="disCol.isSelect"
          (change)="onSelect($event.checked, element._id)"
        ></mat-checkbox>

        <ng-container *ngIf="disCol.hasBtn">
          <button class="btn" *ngFor="let btn of disCol.buttonArray" (click)="btn.btnFn(element._id)">{{btn.name}}</button>
        </ng-container>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<mat-paginator [length]="totalCount" [pageSize]="100"></mat-paginator>
