
import {Component, Inject, Input, OnInit,ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpService } from "src/app/core/services/http.service";
import {NgxMatDateFormats,NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { formatAllDates, sort } from 'src/app/core/utils/utility.functions';

// If using Moment
const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h23',
  hour: '2-digit',
  minute: '2-digit',
};

const MAT_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};


@Component({
  selector: 'app-console-dialog',
  templateUrl: './console-dialog.component.html',
  styleUrls: ['./console-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
  ],
})
export class ConsoleDialogComponent implements OnInit {
  lob:string;
  consoleTransferform: FormGroup
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;

  public end_date = getMonthEnd();
  minDate: string | Date = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ConsoleDialogComponent>,
  private activatedRoute: ActivatedRoute,
  private http: HttpService,
  private formBuilder: FormBuilder,
  private router: Router,
  ) {
    this.consoleTransferform = this.formBuilder.group({
      lob: [''],
      end_date: ['']
    });

  }


  ngOnInit() {
    this.lob = this.router.url.split("/").at(-1);
    this.consoleTransferform.get('lob')?.setValue(this.lob);
    this.consoleTransferform.get('end_date').setValue(this.end_date);
    this.disableDate();
  }

  consoleTransfer() {
    const url = '/services/api/v1/console';
    let data = {end_date:'',lob:'',insurer:'',evi_capture:''};
    data['end_date']=formatAllDates(this.consoleTransferform.get('end_date').value);
    data['evi_capture']=this.consoleTransferform.get('evi_capture').value;
    data['insurer']=this.consoleTransferform.get('insurer').value
    data['lob']=this.lob;
    this.http.post(url, data).subscribe(res => {
      window.location.href = "/rules/overview/" + this.lob;
    });
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close();
  }

  disableDate(){
    const d = new Date();
    this.minDate = d;
  }
}

function getMonthEnd() {
  const today = new Date();
  let end = new Date(today.getFullYear(), today.getMonth() + 2, 0, 23, 59);
  return end;
}

