import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsType } from '../models/user.permissions'
import { StateService } from '../services/global.service';
 @Directive({
  selector: '[appIsGranted]'
})
export class IsGrantedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerS: StateService
  ) { }
  @Input() set appIsGranted(permission: Array<number>) {
    const resource = permission[0];
    const permissionType = permission[1];
    if (Array.isArray(resource)) {
      let res = resource.find((r: any) => this.permissionManagerS.isGranted(r, permissionType));
      if (res) {
        this.isGranted(res, permissionType)
      }
    } else {
      this.isGranted(resource, permissionType)
    }
  }

   private isGranted(resource: number, permissionType: PermissionsType) {
    if (this.permissionManagerS.isGranted(resource, permissionType)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
  
}