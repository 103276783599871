import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-transaction-id',
  templateUrl: './transaction-id.component.html',
  styleUrls: ['./transaction-id.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class TransactionIdComponen implements OnInit {

  form: FormGroup;
  constructor(private crtlContainer: FormGroupDirective){}

  ngOnInit(): void {
    this.form=this.crtlContainer.form;
    this.form.addControl('transaction_Id',new FormControl());
  }

}
