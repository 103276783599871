import { Component, Input, OnInit, Renderer2, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { AgeFieldComponent } from '../age-field/age-field.component';
import { CreditCardBankFieldComponent } from '../credit-card-bank-field/credit-card-bank-field.component';
import { NopFieldComponent } from '../nop-field/nop-field.component';
import { PlanNameFinsureFieldComponent } from '../plan-name-finsure-field/plan-name-finsure-field.component';
import { PlanNameLifeFinsureFieldComponent } from '../plan-name-life-finsure-field/plan-name-life-finsure-field.component';
import { PlanTypeFinsureFieldComponent } from '../plan-type-finsure-field/plan-type-finsure-field.component';
import { SumInsuredFieldComponent } from '../sum-insured-field/sum-insured-field.component';
import { TotalBusinessComponent } from '../total-business/total-business.component';


@Component({
  selector: 'app-finsure-product-field-v2',
  templateUrl: './finsure-product-field-v2.component.html',
  styleUrls: ['./finsure-product-field-v2.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class FinsureProductFieldV2Component {
  @Input('lob') lob: any;
  @Input('required') isRequired = false;
  @Input('containerRef') containerRef: ViewContainerRef;

  @ViewChild('fieldsContainer', { static: true, read: ViewContainerRef }) defaultContainerRef: ViewContainerRef;

  fieldsContainer: ViewContainerRef;

  product: any = [];

  form: FormGroup;
  productControl = new FormControl();
  lobSelected: any = '';

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    private renderer2 : Renderer2,
  ) { };

  ngOnInit(): void {
    if (!this.containerRef) {
      console.warn("[Not Provided] You can provide ViewContainerRef 'containerRef' to render fields in your desired block. Going with default block.")
      this.fieldsContainer = this.defaultContainerRef;
    } else {
      this.fieldsContainer = this.containerRef;
    }

    this.productControl.valueChanges.subscribe((res: any)=>{
      this.updateFieldsForProducts(res);
    });

    this.form = this.ctrlContainer.form;

    if (this.form.controls['product_name']) {
      this.productControl.setValue(this.form.controls['product_name'].value);
      this.form.removeControl('product_name');
    }

    this.form.addControl('product_name', this.productControl);
    this.getProduct();

  }

  getProduct() {
    const url = '/services/api/v1/rb_masters/finsure_product';
    this.http.get(url).subscribe((res: any) => {
      this.product = res['data'];
    });
  }

  updateFieldsForProducts(product: any) {
    this.fieldsContainer.clear();

    const fields = PRODUCT_FIELDS[product];
    for (const field of fields) {
      for (const controlName of field['control_names']) {
        if (!this.form.controls[controlName]) {
          this.form.addControl(controlName, new FormControl());
        }
      }
      let createdComp = this.fieldsContainer.createComponent(field['component']);
      this.renderer2.addClass(createdComp.location.nativeElement, field['class']);
      this.renderer2.addClass(createdComp.location.nativeElement, 'gapped-row');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('product_name');
  }
}

const PRODUCT_FIELDS:any = {
  1: [
    { 'control_names': ['plan_type'], 'component': PlanTypeFinsureFieldComponent, 'class': 'col-4'},
    { 'control_names': ['plan_name'], 'component': PlanNameFinsureFieldComponent, 'class': 'col-4' },
    // { 'control_names': ['total_business'], 'component': TotalBusinessComponent, 'class': 'col-6' },
    // { 'control_names': ['nop'], 'component': NopFieldComponent, 'class': 'col-4' },
  ],
  2: [
    {'control_names': ['plan_name'], 'component':PlanNameLifeFinsureFieldComponent, 'class': 'col-4'},
    {'control_names': ['age'], 'component':AgeFieldComponent, 'class': 'col-6'},
    {'control_names': ['sum_insured'], 'component':SumInsuredFieldComponent, 'class': 'col-6'},
  ],
  3: [
    { 'control_names': ['cc_bank', 'cc_name'], 'component': CreditCardBankFieldComponent, 'class': 'col-8' }
  ],
}