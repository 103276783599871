<div class="container">
<form [formGroup]="run">
    
    <div>
        <h1 class="rule">Run Rules</h1>
        <span><mat-icon class="close" (click)="closeDialog()">close</mat-icon></span>
        <div class="ml-3">
            <app-lob-select [required]="true"></app-lob-select>
            <button class="submit" type="button" (click)="submit()" [disabled]="run.invalid || perm" [ngClass]="{'disabled': run.invalid || perm}">Submit</button>
        </div>
    </div>
</form>
</div>