import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-finsure-insurer-field',
  templateUrl: './finsure-insurer-field.component.html',
  styleUrls: ['./finsure-insurer-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class FinsureInsurerFieldComponent {
  @Input('lob') lob: any;

  insurer: any = [];
  form: FormGroup;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('required') isRequired = false;
  insurerControl = new FormControl();
  lobSelected: any = '';

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.form.controls['insurer']) {this.insurerControl.setValue(this.form.controls['insurer'].value);}
    this.form.addControl('insurer', this.insurerControl);

    this.getInsurerName();
  }
  getInsurerName() {
    
    const url = '/services/api/v1/rb_masters/finsure_providers';
    this.http.get(url).subscribe((res: any) => {      
      this.insurer = res['data'];
    });
  }


  ngOnChanges(changes: SimpleChanges): void {   
    if (changes['lob']) {      
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('insurer');
  }
}



