<mat-label class="subHeading">Payment Type</mat-label>
<mat-select 
#paymentTypeSelect
class="insurer"
multiple
formControlName="payment_instrument">
    <mat-option *ngFor="let option of paymentOptions" [value]="option.value">
        {{ option.key }}
    </mat-option>
</mat-select>
  