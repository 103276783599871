import { Component } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';


@Component({
  selector: 'app-customer-type',
  templateUrl: './customer-type.component.html',
  styleUrls: ['./customer-type.component.scss'],
  providers: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})

export class CustomerTypeComponent {

  form: FormGroup;
  customerTypControl = new FormControl();
  customerList:object[]=[];
  customerTypeList = [
    {value: 1, name: 'individual'},
    {value: 2, name: 'corporate'}
  ];

  constructor(
    private ctrlContainer: FormGroupDirective
  ){}

  ngOnInit(){
    this.customerList = this.customerTypeList;
    this.form = this.ctrlContainer.form;
    if (this.form.controls['customer_type']) {
      this.customerTypControl.setValue(this.form.controls['customer_type'].value);
    }
    this.form.addControl('customer_type', this.customerTypControl);
  }

  ngOnDestroy(){
    this.form.removeControl('customer_type');
  }

}
