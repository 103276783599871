<mat-label class="subHeading ">Registration No.<span *ngIf="isRequired" class="star">*</span></mat-label>

<input
    [formControl]="registrationControl"
    [value]="registrationControl.value | uppercase"
    class="reg mt-2"
    placeholder="xxxxxxxxxxxx"
    [maxlength]="12"
    alphaNumeric
/>
<mat-error *ngIf="registrationControl.hasError('maxlength')">
  Max length is 12 characters
</mat-error>
