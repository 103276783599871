<mat-label class="subHeading"
  >Insurer<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select *ngIf="!isMultiSelect; else multi" formControlName="insurer" class="insurer" placeholder="Select">
  <mat-option *ngFor="let insurerObj of insurers" [value]="insurerObj.rb_id">
    {{ insurerObj.insurer_name }}
  </mat-option>
</mat-select>

<ng-template #multi>
  <app-search-dropdown
      [dataSearchKey]="'insurer_name'"
      [dataValueKey]="'rb_id'"
      [dataSource]="insurers"
      [control]="form.controls['insurer']"
      [multiple]="true"
  ></app-search-dropdown>
</ng-template>