<!-- <label class="subHeading"
  >Plan Name<span *ngIf="isRequired" class="star">*</span></label
>
<mat-select formControlName="plan_name">
    <mat-option *ngFor="let obj of plan_name" [value]="obj.rb_id">
        {{obj.name}}
    </mat-option>
</mat-select>
 -->
<!-- <div class="even">
  <mat-label class="subHeading"
    >Plan Name<span *ngIf="isRequired" class="star">*</span></mat-label
  >
  <button class="side" (click)="form.controls['plan_name'].setValue(null)">
    Remove
  </button>
</div>
<mat-select
  *ngIf="!isMultiSelect; else multi"
  formControlName="plan_name"
  class="insurer"
  placeholder="Select"
>
  <mat-option *ngFor="let obj of plan_name" [value]="obj.rb_id">
    {{ obj.name }}
  </mat-option>
</mat-select>
<ng-template #multi>
  <app-search-dropdown
    [dataSearchKey]="'plan_name'"
    [dataValueKey]="'rb_id'"
    [dataSource]="plan_name"
    [control]="form.controls['plan_name']"
    [multiple]="true"
  ></app-search-dropdown>
</ng-template> -->
<mat-label class="subHeading"
  >Plan Name<span *ngIf="isRequired" class="star">*</span></mat-label
>
<div *ngIf="isMultiSelect">
  <mat-select
    #planNameSelect
    class="insurer"
    multiple
    formControlName="plan_name"
  >
    <mat-option *ngFor="let obj of plan_name" [value]="obj.rb_id">
      {{ obj.name }}
    </mat-option>
  </mat-select>
</div>
<div *ngIf="!isMultiSelect">
  <mat-select formControlName="plan_name">
    <mat-option *ngFor="let obj of plan_name" [value]="obj.rb_id">
      {{ obj.name }}
    </mat-option>
  </mat-select>
</div>
