import { Component } from '@angular/core';
import { RangeFieldConfig } from '../range-field/range-field.component';

@Component({
  selector: 'app-auto-loan-interest-rate',
  templateUrl: './auto-loan-interest-rate.component.html',
  styleUrls: ['./auto-loan-interest-rate.component.scss']
})
export class AutoLoanInterestRateComponent {
  config: RangeFieldConfig = {
    required: false,
    label: 'Loan Interest Rate',
    controlName: 'loan_interest_rate',
  }
}
