import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-partner-code',
  templateUrl: './partner-code.component.html',
  styleUrls: ['./partner-code.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PartnerCodeComponent {
  @Input('required') isRequired = false;
  @Input('fromFile') fromFile = false;
  @Input('label') label = 'Codes';
  @Input('controlName') controlName: string = 'partner_code';

  alphaRegex = /^[a-zA-Z0-9,]+$/;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective,private http: HttpService){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if(this.isRequired){
      this.form.addControl(this.controlName, new FormControl(null, [Validators.required, Validators.pattern(this.alphaRegex)]));
    }
    else{
      this.form.addControl(this.controlName,new FormControl(null,Validators.pattern(this.alphaRegex)))
    }
    

  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName);
  }

  onCodeCsvUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const url = '/services/api/v1/payout/ei-codes';
    this.http.post(url, formData).subscribe((res: any) => {
      if (res) {
        this.form.controls[this.controlName].patchValue(res);
      }
    });
  }

}
