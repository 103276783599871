import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {
  private historyUrl: string;
  private params: any;

  data: any;

  constructor(private http: HttpService, private route: ActivatedRoute) { };

  ngOnInit() {
    this.route.data.subscribe(data => this.historyUrl = data['url']);
    this.route.queryParams.subscribe(
      params => {
        this.params = params;
      }
    );
    this.getHistory();
  }

  getHistory() {
    const url = this.historyUrl;
    const params = this.params;
    this.http.get(url, params).subscribe((res: any) => {
      this.data = res;
    });
  }

  get keys() {
    return Object.keys(this.data[0]);
  }

  getValues(obj: any) {
    return Object.values(obj);
  }
}
