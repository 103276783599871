import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { getPayoutBase, PAYOUT_BASE } from './constants';

@Component({
  selector: 'app-pay-out',
  templateUrl: './pay-out.component.html',
  styleUrls: ['./pay-out.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayOutComponent {
  @Input('lob') lob: string = 'motor';
  hasValue = {
    [PAYOUT_BASE.OD_PREMIUM.value]: false,
    [PAYOUT_BASE.TP_PREMIUM.value]: false,
    [PAYOUT_BASE.NET_PREMIUM.value]: false,
  };

  payoutControls: any = { "percent": [], "amount": [] };

  payin_payout_types = [
    { 'ctrlName': 'payout', 'displayName': 'Pay Out' }
  ];

  form: FormGroup;

  payoutBase: any[];
  payoutGroup: FormGroup = new FormGroup({});

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.payoutBase = getPayoutBase(this.lob);
    this.createFormControls();
    this.setAlternatingControls();
  }

  createFormControls() {
    for (const payoutBase of this.payoutBase) {
      
      const payoutPercControl = new FormControl('', Validators.required);
      const payoutAmtCtrl = new FormControl('', Validators.required);

      this.payoutControls.percent.push(payoutPercControl);

      
      this.payoutControls.amount.push(payoutAmtCtrl);


      this.payoutGroup.addControl(`${payoutBase.value}`, new FormGroup({ 'percent': payoutPercControl, 'amount': payoutAmtCtrl }));

      // this.valueRangeCheck(payoutPercControl);
      // this.valueRangeCheck(payoutAmtCtrl);

      this.test(payoutPercControl,payoutBase.value)
      this.test(payoutAmtCtrl,payoutBase.value)

    };
    
    this.form.addControl('payout', this.payoutGroup);

  }

  setAlternatingControls() {
    const percentControls = [
      ...this.payoutControls.percent,
      // ...this.payinControls.percent,
      // ...this.acPayinControls.percent,
    ];
    const amountControls = [
      ...this.payoutControls.amount,
      // ...this.payinControls.amount,
      // ...this.acPayinControls.amount,
    ];

    this.enableDisable(percentControls, amountControls);
    this.enableDisable(amountControls, percentControls);
  }

  enableDisable(arr1: any, arr2: any) {
    for (const ctrl of arr1) {
      ctrl.valueChanges.subscribe((val: any) => {
        if (val) {
          arr2.forEach((ctrl2: any) => {
            ctrl2.disable({ onlySelf: true, emitEvent: false });
          });
        } else if (!this.valueExists(arr1)) {
          arr2.forEach((ctrl2: any) => {
            ctrl2.enable({ onlySelf: true, emitEvent: false });
          });
        }
      });
    }
  }

  valueRangeCheck(ctrl1: any, ctrl2: any, ctrl3: any) {
    ctrl1.valueChanges.subscribe((val: any) => {
      const val1 = this.numberOrZero(val);
      const val2 = this.numberOrZero(ctrl2.value);
      const val3 = this.numberOrZero(ctrl3.value);

      if ((val1 > val2 || val1 > val3)) {
        ctrl1.setErrors({ 'invalid': true });
      } else if (val1 < val2 && val1 < val3) {
        ctrl1.setErrors(null);
        ctrl2.setErrors(null);
        ctrl3.setErrors(null);
      }
    });

    ctrl2.valueChanges.subscribe((val: any) => {
      const val1 = this.numberOrZero(ctrl1.value);
      const val2 = this.numberOrZero(val);
      const val3 = this.numberOrZero(ctrl3.value);

      if ((val2 < val1 || val2 > val3)) {
        ctrl2.setErrors({ 'invalid': true });
      } else if (val2 > val1 && val2 < val3) {
        ctrl1.setErrors(null);
        ctrl2.setErrors(null);
        ctrl3.setErrors(null);
      }
    });

    ctrl3.valueChanges.subscribe((val: any) => {
      const val1 = this.numberOrZero(ctrl1.value);
      const val2 = this.numberOrZero(ctrl2.value);
      const val3 = this.numberOrZero(val);

      if ((val3 < val1 || val3 < val2)) {
        ctrl3.setErrors({ 'invalid': true });
      } else if (val3 > val1 && val3 > val2) {
        ctrl1.setErrors(null);
        ctrl2.setErrors(null);
        ctrl3.setErrors(null);
      }
    });
  }

  test(payoutCtrl: any, payoutBase:any) {
    payoutCtrl.valueChanges.subscribe((val: any) => {
      if (this.isBadNet || this.isBadOD || this.isBadTp || (!val && !this.form.touched)) { return };
      console.log('value', val);
      console.log('payout base V', payoutBase);

      if (this.numberOrZero(val) > 0) {
        // this.hasValue[payoutBase] = true;
        this.removeRequired(payoutBase);
        this.disableGroup(payoutBase);

      } else {
        // this.hasValue[payoutBase] = false;
        this.setRequired(payoutBase);
        this.enableGroup(payoutBase);

      }
    })
  }

  isBadOD = false;
  isBadTp = false;
  isBadNet = false;

  removeRequired(payoutBase: any) {
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value) {
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(tpGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadTp = true;
          ctrl.updateValueAndValidity();
          this.isBadTp = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(odGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadOD = true;
          ctrl.updateValueAndValidity();
          this.isBadOD = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      Object.values(odGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadOD = true;
          ctrl.updateValueAndValidity();
          this.isBadOD = false;
        }
      });
      Object.values(tpGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadTp = true;
          ctrl.updateValueAndValidity();
          this.isBadTp = false;
        }
      });
    }
  }

  setRequired(payoutBase: any) {
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value) {
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(tpGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadTp = true;
          ctrl.updateValueAndValidity();
          this.isBadTp = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(odGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadOD = true;
          ctrl.updateValueAndValidity();
          this.isBadOD = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      Object.values(odGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadOD = true;
          ctrl.updateValueAndValidity();
          this.isBadOD = false;
        }
      });
      Object.values(tpGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadTp = true;
          ctrl.updateValueAndValidity();
          this.isBadTp = false;
        }
      });
    }
  }

  disableGroup(payoutBase: any) {
    console.log("payout base D", payoutBase)
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value || payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      this.ddd(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      this.ddd(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.ddd(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
    }
  }

  enableGroup(payoutBase: any) {
    console.log("payout base E", payoutBase)
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value || payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      this.eee(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      this.eee(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.eee(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
    }
  }

  valueExists(arr: any[]) {
    let flag = false;
    arr.forEach((ctrl: any) => {
      if (ctrl.value) { flag = true; return; };
    });
    return flag;
  }

  numberOrZero(num: any) {
    return parseFloat(num) || 0;
  }


  ddd(group: any) {
    Object.values(this.getControlAsFormGroup(group).controls).forEach((ctrl: any) => {
      if (ctrl.enabled) {
        ctrl.disable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  eee(group: any) {
    Object.values(this.getControlAsFormGroup(group).controls).forEach((ctrl: any) => {
      if (ctrl.disabled) {
        ctrl.enable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  getControlAsFormGroup(ctrl:any) {
    return ctrl as FormGroup;
  }


}
