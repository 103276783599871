import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription, tap } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';
import { TableDataSource } from './data-source';
import { TableDataService } from './table-data.service';
import { Column } from './table.interface';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, AfterViewInit, OnDestroy {
  @Output('dataChanged') dataChanged: EventEmitter<any> =
    new EventEmitter<any>();
  @Input('columnDefs') tableColumns: Array<Column>;
  @Input('displayColumns') displayedColumns: Array<string>;
  @Input('filters') filters: any;
  @Input('data') data: any = null;

  totalCount: number = 0;

  dataSource: TableDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren('checkboxes') selectBoxes: QueryList<any>;

  triggerSubscription: Subscription;
  dataSourceSubscription: Subscription;

  constructor(private tableService: TableDataService, private http: HttpService) {};

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.tableService);
    this.checkMandatoryDirectives();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadData(this.filters,this.data))).subscribe();

    // Using settimeout to prevent angular change detection error
    setTimeout(() => {
      this.subscribeToSubscriptions();
    });
  }

  loadData(params = {}, data:any=null) {
    this.dataSource.loadData(
      params,
      '',
      data,
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  updatePagination(data: any) {
    // if (!data.data) return;
    this.totalCount = data.total_records;
  }

  ngOnDestroy(): void {
    this.unSubscribeAll();
  }

  checkMandatoryDirectives() {
    if (!this.displayedColumns) {
      throw new TypeError(
        'displayColumns is required! Provide columnDefs directive'
      );
    }
    if (!this.tableColumns) {
      throw new TypeError(
        'columnDefs is required! Provide columnDefs directive'
      );
    }
  }

  selectAll(allSelected: boolean) {
    const checkBoxes = this.selectBoxes['_results'];
    for (const checkbox of checkBoxes) {
      checkbox.checked = allSelected;
      const id = checkbox['_elementRef']['nativeElement']['customId'];
      this.onSelect(allSelected, id);
    }
  }

  onSelect(selected: boolean, value: string) {
    if (selected) {
      this.tableService.addSelectedElement(value);
    } else {
      this.tableService.removeSelectedElement(value);
    }
  }

  subscribeToSubscriptions() {
    this.dataSourceSubscription = this.dataSource.lessonsSubject.subscribe(
      (data) => {
        this.dataChanged.emit(data);
        this.updatePagination(data);
      }
    );
    this.triggerSubscription = this.tableService.triggerSubject.subscribe(
      (params:any) => {
        try {
          this.filters = params[0];
          this.data = params[1];
          this.loadData(...params);
        } catch (err) {
          this.filters = params;
          this.loadData(params);
        }
      }
    );
  }

  unSubscribeAll() {
    this.dataSourceSubscription.unsubscribe();
    this.triggerSubscription.unsubscribe();
  }
}
