import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  providers: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class SelectCompanyComponent {
  @Input('required') required: boolean = false;
  companies: any = [];

  companyFormControl: FormControl|AbstractControl = new FormControl();

  constructor(private http: HttpService, private controlContainer:FormGroupDirective) { };

  ngOnInit() {
    if (this.required) {
      this.companyFormControl.addValidators(Validators.required);
      this.companyFormControl.updateValueAndValidity();
    }
    if (this.controlContainer.form) {
      if (!this.controlContainer.form.controls['company']) {
        this.controlContainer.form.addControl('company', this.companyFormControl);
      }
      this.companyFormControl = this.controlContainer.form.controls['company'];
    }
    this.getCompanies();
  }

  getCompanies() {
    const url = '/services/api/v1/company';
    this.http.get(url).pipe(
      map((res:any)=> res.data.map((val:any)=>val.company_name))
    ).subscribe(res => this.companies=res);
  }
}
