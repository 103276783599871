<form [formGroup]="consoleReason">
    <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
    <h2 class="reason">Reason</h2>
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
                <input formControlName="reason"/>
            </div>
        </div>
        <button type="button" class="btn submit-btn" (click)="clickOk()" [disabled]="!consoleReason.valid">Submit</button>
    </div>
</form>