import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { Apiurls } from './Api_urls';

import { sort } from 'src/app/core/utils/utility.functions';
import { FormControlService } from 'src/app/shared/services/form-control.service';

@Component({
  selector: 'app-insurer-field',
  templateUrl: './insurer-field.component.html',
  styleUrls: ['./insurer-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class InsurerFieldComponent implements OnInit {
  @Input('lob') lob: any;
  @Input('required') isRequired = true;
  @Input('multiple') isMultiSelect = false;

  insurers: any = [];

  form: FormGroup;
  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    public activatedRoute: ActivatedRoute,
    private controlStateService:FormControlService,
  ) {}

  ngOnInit(): void {
    // this.getinsurer();
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl(
        'insurer',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('insurer', new FormControl(null));
    }
    this.controlStateService.register({ controlName: 'insurer', control: this.form.controls['insurer'] }, true);
  }

  ngOnChanges(changes: any) {
    if (changes['lob']['previousValue'] != changes['lob']['currentValue']) {
      this.getinsurer();
    }
  }

  getinsurer() {
    if (!this.lob || !this.lob.length) return;

    const product = this.lob;
    const url = Apiurls[product as keyof typeof Apiurls];
    if (!url) {
      throw Error(`Insurer api url is not set for lob ${product}`);
    }
    this.http.get(url).subscribe((res: any) => {
      this.insurers = res['data'];
      this.insurers = sort(this.insurers, 'insurer_name');
      if (this.form.value['lob'] === 'finsure') {
        if (this.form.value['insurerMultiple']) {
          let insurerData = this.form.value['insurerMultiple'][0];
          this.form.patchValue({
            insurer: insurerData,
          });
        }
      } else {
        if (this.form.value['insurerMultiple']) {
          let insurerData = this.form.value['insurerMultiple'];
          this.form.patchValue({
            insurer: insurerData,
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('insurer');
  }
}
