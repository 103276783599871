import { Component } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-total-business',
  templateUrl: './total-business.component.html',
  styleUrls: ['./total-business.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class TotalBusinessComponent {
  form: FormGroup;

  totalBusinessForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  isInsurerSpecific: FormControl = new FormControl(false);

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.totalBusinessForm.controls['to'].addValidators(rangeValidator.bind(this, this.totalBusinessForm));
    this.totalBusinessForm.controls['from'].addValidators(rangeValidator.bind(this, this.totalBusinessForm));
    this.form = this.ctrlContainer.form;
    this.form.addControl('total_business', this.totalBusinessForm);
    this.form.addControl('is_insurer_specifc', this.isInsurerSpecific);
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.totalBusinessForm.controls['to'].value) < Number(this.totalBusinessForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.totalBusinessForm.controls['to'].invalid) {
  //     this.totalBusinessForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.totalBusinessForm.controls['from'].invalid) {
  //     this.totalBusinessForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('total_business');
    this.form.removeControl('is_insurer_specifc');
  }
}
