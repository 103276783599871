import { Injectable } from '@angular/core';
import { map, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { HttpService } from '../core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  userRulePerms: any;

  private loaded = new ReplaySubject<boolean>();

  loadedd = this.loaded.asObservable();

  constructor(private http: HttpService) {
    this.aaaa();
  };

  getUserPerms() {
    const url = `services/api/v1/accounts/get_maker_checker_auth`;
    return this.http.get(url).pipe(map((res: any) => this.userRulePerms = res));
  };

  aaaa() {
    this.getUserPerms().subscribe((res: any) => {
      this.loaded.next(true);
    });
  }

  isAuthorized(lob: string, ruleType: any = null) {
    if (lob.includes('finsure')) {
      lob = 'finsure';
    }
    let isAllowed = false;
    if (lob == 'contest') {
      isAllowed = this.isContestAllowed();
    } else {
      if (ruleType && this.userRulePerms[lob]) {
        isAllowed = this.userRulePerms[lob][ruleType];
      } else if(this.userRulePerms[lob]){
        for (const key of Object.keys(this.userRulePerms[lob])) {
          if (key != '3' && this.userRulePerms[lob][key]) {
            isAllowed = true;
            break;
          }
        }
      } else if (lob.includes('bp') || lob.includes('rdp')) {
        isAllowed = true;
      }
    }
    return isAllowed;
  }

  isContestAllowed() {
    return !!Object.values(this.userRulePerms).find((obj:any)=> obj[3])
  }
}

