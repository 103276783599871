import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BasicDialogComponent } from 'src/app/shared/components/dialog-components/basic-dialog/basic-dialog.component';
import { AuthService } from '../services/auth.service';
import { LoaderService } from 'src/app/shared/services/loader-service.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private dialogService: DialogService, private router: Router, private authService: AuthService, private loaderService: LoaderService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            // retry(1),
            catchError((error: HttpErrorResponse) => {
                console.error('HTTP error:', error);
                this.handleErrorResponse(error);
                return throwError(error); // Rethrow the error
            }),
            finalize(() => {
                this.loaderService.stopLoading(); // stop loader after response is recieved
            })
        );
    }

    private handleErrorResponse(error: HttpErrorResponse): void {
        if (error.status === 401) {
            this.dialogService.openDialogwithTimeout(
                { title: `Error`, body: error.error.message },
                BasicDialogComponent,
                15000,
                this.authService.logOutWithoutNext
            );
        } else {
            const errorMessage = error.status >= 500 ? error.message : error.error;
            this.dialogService.openDialog(
                { title: `Error`, body: errorMessage },
                BasicDialogComponent,
                15000
            );
        }
    }
}