<mat-label class="subHeading"
  >Insurer<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select
  formControlName="insurer"
  multiple
  class="insurer"
  placeholder="Select"
>
  <mat-option *ngFor="let insurerObj of insurers" [value]="insurerObj.rb_id">
    {{ insurerObj.insurer_name }}
  </mat-option>
</mat-select>
