import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loaderSubject = new Subject<boolean>();
  private _loadingText = "Loading...";

  $loading = this._loaderSubject.asObservable();

  constructor() { };

  get text() {
    return this._loadingText;
  }

  startLoading(text?: string) {
    this._loaderSubject.next(true);
    if (text) {
      this._loadingText = text;
    }
  }

  stopLoading() {
    this._loaderSubject.next(false);
    this._loadingText = "Loading...";
  }

}
