import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  FormControl,
  Validators,
  ControlContainer,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
})
export class FileFieldComponent {
  @Input('label') label: string;
  @Input('acceptOnly') validFormats: any[] = [];
  @Output('fileUploaded') fileUploadEmitter = new EventEmitter();
  @Input('event') fileResetEvent: Observable<any>;
  @ViewChild('fileInput', { read: HTMLInputElement }) fileInput:HTMLInputElement;

  fileName = "No File Choosen";

  form: FormGroup;

  private parentSub: Subscription;

  constructor() { };

  ngOnInit() {
    if (this.fileResetEvent) {
      this.parentSub = this.fileResetEvent.subscribe(res => this.fileName='No File Choosen');
    }
  }

  ngOnDestroy() {
    if (this.parentSub) {
      this.parentSub.unsubscribe();
    }
  }

  onChange(inputElem: HTMLInputElement) {
    const file = inputElem.files[0];

    if (file && !this.isValidFile(file)) {
      inputElem.value = null;
      return false;
    }

    this.fileName = inputElem.files[0].name.slice(0,13)+"..." || 'No File Choosen';
    this.fileUploadEmitter.emit(inputElem.files[0]);
    inputElem.value = null;
    return true;
  }

  isValidFile(file: File) {
    if (this.validFormats.length) {
      if (this.validFormats.indexOf(file.type) == -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
