import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-date',
  templateUrl: './success-date.component.html',
  styleUrls: ['./success-date.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SuccessDateComponent implements OnInit {
  @Input('need') need = false;
  form: FormGroup;

  constructor(private ctrlContainer: FormGroupDirective) { }
  ngOnInit() {
    this.form = this.ctrlContainer.form;
    if (this.need) {
      this.form.addControl('successDate', new FormControl(Validators.required))
    }
    else{
      this.form.addControl('successDate', new FormControl())
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('successDate');
  }
}
