import { Component, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';

import { getPayoutBase } from 'src/app/shared/components/form_fields/payin-out/constants';
import { PayinOutService } from '../payin-out.service';

@Component({
  selector: 'app-payin-out-child',
  templateUrl: './payin-out-child.component.html',
  styleUrls: ['./payin-out-child.component.scss']
})
export class PayinOutChildComponent implements OnDestroy{
  @Input()lob = 'motor';
  @Input()title = '';
  @Input('form') payoutForm: FormGroup = new FormGroup({});
  @Input()required: boolean = true;

  payoutBaseObjects: any = [];

  amountControls: any = [];
  percentControls: any = [];

  //Subscriptions
  amountDisableSub: Subscription;
  percentDisableSub: Subscription;
  payoutBaseSub: Subscription;
  validatorSub: Subscription;

  constructor(private payoutService: PayinOutService) { };

  ngOnInit() {
    this.payoutBaseObjects = getPayoutBase(this.lob);

    for (const payoutBaseObj of this.payoutBaseObjects) {
      let group = new FormGroup({
        'percent': new FormControl(null),
        'amount': new FormControl(null),
      });
      if (this.title == 'Pay Out') {
        group = new FormGroup({
          'percent': new FormControl(null, Validators.required),
          'amount': new FormControl(null, Validators.required),
        });
      }
      this.payoutDisableOnChanges(group.controls['percent'], payoutBaseObj.value, 'percent');
      this.payoutDisableOnChanges(group.controls['amount'], payoutBaseObj.value, 'amount');
      this.amountControls.push(group.controls['amount']);
      this.percentControls.push(group.controls['percent']);
      this.payoutForm.addControl(payoutBaseObj.value, group);
    }
    this.subscriptions();
  }

  subscriptions() {
    this.amountDisableSub=this.payoutService.enableDisableAmount.subscribe(
      (val: any) => {
        // console.log('amount', val);
        
        if (val == 'enable') {
          this.amountControls.forEach((ctrl: any) => ctrl.enable());
        } else if (val == 'disable') {
          this.amountControls.forEach((ctrl: any) => ctrl.disable());
        }
      }
    );

    this.percentDisableSub=this.payoutService.enableDisablePercent.subscribe(
      (val: any) => {
        if (val == 'enable') {
          this.percentControls.forEach((ctrl: any) => ctrl.enable());
        } else if (val == 'disable') {
          this.percentControls.forEach((ctrl: any) => ctrl.disable());
        }
      }
    );

    this.payoutBaseSub = this.payoutService.enableDisablePayoutBaseGroup.subscribe(
      (val: any) => {
        if (!val) return;
        const payoutBase = val[0];
        const command = val[1];

        if (!this.payoutForm.controls[payoutBase]) return;

        if (command == 'enable') {
          Object.values((this.payoutForm.controls[payoutBase] as FormGroup).controls).forEach((ctrl: any) => {
            ctrl.enable();
          });
        } else if (command == 'disable') {
          Object.values((this.payoutForm.controls[payoutBase] as FormGroup).controls).forEach((ctrl: any) => {
            ctrl.disable();
          });
        }
      }
    );

    if (this.required) {
      this.validatorSub = this.payoutService.enableDisableValidatorSub.subscribe(
        (val: any) => {
          if (!val) return;
          const payoutBase = val[0];
          const command = val[1];

          if (!this.payoutForm.controls[payoutBase]) return;
  
          if (command == 'enable') {
            Object.values((this.payoutForm.controls[payoutBase] as FormGroup).controls).forEach((ctrl: any) => {
              if (!ctrl.hasValidator(Validators.required)) {
                ctrl.addValidators(Validators.required);
                ctrl.updateValueAndValidity();
              }
            });
          } else if (command == 'disable') {
            Object.values((this.payoutForm.controls[payoutBase] as FormGroup).controls).forEach((ctrl: any) => {
              if (ctrl.hasValidator(Validators.required)) {
                ctrl.removeValidators(Validators.required);
                ctrl.updateValueAndValidity();
              }
            });
          }
        }
      );
    }
  }

  payoutDisableOnChanges(ctrl1: any, payoutBase:any, type:any) {
    ctrl1.valueChanges.pipe(
      debounceTime(100)
    ).subscribe((val: any) => {
      if (val) {
        if (type == 'percent') {
          this.payoutService.enableDisableAmount.next('disable');
        } else if (type == 'amount') {
          this.payoutService.enableDisablePercent.next('disable');
        }
        if (payoutBase == 1 || payoutBase == 2) {
          this.payoutService.enableDisableValidatorSub.next([payoutBase, 'enable']);
          this.payoutService.enableDisablePayoutBaseGroup.next([3, 'disable']);
          if (payoutBase == 1 && !this.payoutService.valueExists(null, 2)) {
            this.payoutService.enableDisableValidatorSub.next([2, 'disable']);
          } else if (payoutBase == 2 && !this.payoutService.valueExists(null, 1)) {
            this.payoutService.enableDisableValidatorSub.next([1, 'disable']);
          }
        } else if (payoutBase == 3) {
          this.payoutService.enableDisableValidatorSub.next([3, 'enable']);
          this.payoutService.enableDisablePayoutBaseGroup.next([1, 'disable']);
          this.payoutService.enableDisablePayoutBaseGroup.next([2, 'disable']);
        }
      } else if (!val && val != null) {
        ctrl1.patchValue(null);
        if (type == 'percent' && !this.payoutService.valueExists(type)) {
          this.payoutService.enableDisableAmount.next('enable');
        } else if (type == 'amount' && !this.payoutService.valueExists(type)) {
          this.payoutService.enableDisablePercent.next('enable');
        }
        if ((payoutBase == 1 || payoutBase == 2)) {
          if (!this.payoutService.valueExists(null, 2) && !this.payoutService.valueExists(null, 1)) {
            this.payoutService.enableDisableValidatorSub.next([1, 'enable']);
            this.payoutService.enableDisableValidatorSub.next([2, 'enable']);
            this.payoutService.enableDisablePayoutBaseGroup.next([3, 'enable']);
          } else if (payoutBase == 1 && !this.payoutService.valueExists(null, 1) && this.payoutService.valueExists(null, 2)) {
            this.payoutService.enableDisableValidatorSub.next([1, 'disable']);
          } else if (payoutBase == 2 && !this.payoutService.valueExists(null, 2) && this.payoutService.valueExists(null, 1)) {
            this.payoutService.enableDisableValidatorSub.next([2, 'disable']);
          }
        } else if (payoutBase == 3 && !this.payoutService.valueExists(null, 3)) {
          this.payoutService.enableDisablePayoutBaseGroup.next([1, 'enable']);
          this.payoutService.enableDisablePayoutBaseGroup.next([2, 'enable']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.amountDisableSub.unsubscribe();
    this.percentDisableSub.unsubscribe();
    this.payoutBaseSub.unsubscribe();
    if (this.validatorSub) {
      this.validatorSub.unsubscribe();
    }
    this.payoutService.enableDisableAmount.next(null);
    this.payoutService.enableDisablePercent.next(null);
    this.payoutService.enableDisablePayoutBaseGroup.next(null);
    this.payoutService.enableDisableValidatorSub.next(null);
  }
}
