<mat-label class="subHeading">Product Type<span *ngIf="isRequired" class="star">*</span></mat-label>
<mat-select multiple formControlName="product_type" class="business" [placeholder]="!isMultiSelect ? 'Select' : 'All'">
    <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let productType of productTypes" [value]="productType.key">{{productType.val | titlecase}}</mat-option>
</mat-select>

<!-- <div class="row">
        <mat-label class="subHeading">Product Type<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'val'"
            [dataValueKey]="'key'"
            [dataSource]="productTypes"
            [control]="productTypeControl"
        ></app-search-dropdown>
</div> -->
