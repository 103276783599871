import { Data } from '@angular/router';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExtendDialogComponent } from '../../dialog-components/extend-dialog/extend-dialog.component';
import { StateService } from 'src/app/shared/services/global.service';
import { RESOURCES } from 'src/app/core/utils/constants';


@Component({
  selector: 'app-carry-button',
  templateUrl: './carry-button.component.html',
  styleUrls: ['./carry-button.component.scss'],
})
export class CarryButtonComponent implements OnInit, OnChanges{
  @Input('lob') lob:string;
  @Input('ruleId') ruleId:any;
  @Input('ruleData') ruleData:any;

  showCarryBtn:boolean = false;

  constructor(public dialog: MatDialog,public stateService: StateService){}

  ngOnInit(): void {
    this.checkPerms();
  }

  ngOnChanges(changes:any){
    if(changes['ruleData']){
      this.checkPerms();
    };
  }

  openDialog(){
    let dialogReff = this.dialog.open(ExtendDialogComponent, {
      width: '550px', height:'250px',data: { lob: this.lob, ruleId: this.ruleId,ruleData:this.ruleData }
    });
  }

  checkPerms(){
    this.showCarryBtn = false;
    if(this.ruleData && this.ruleData.rule_status == 'approved' && this.stateService.isGranted(RESOURCES.CARRY_FORWARD, 2)){
        this.showCarryBtn=true;
    }
  }

}
