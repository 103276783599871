import { Component } from '@angular/core';
import { RangeFieldConfig } from '../range-field/range-field.component';

@Component({
  selector: 'app-auto-loan-cibil-score',
  templateUrl: './auto-loan-cibil-score.component.html',
  styleUrls: ['./auto-loan-cibil-score.component.scss']
})
export class AutoLoanCibilScoreComponent {
  config: RangeFieldConfig = {
    required: false,
    label: 'Cibil Score',
    controlName: 'cibil_score',
  }
}
