import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-premium-payment-type',
  templateUrl: './premium-payment-type.component.html',
  styleUrls: ['./premium-payment-type.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PremiumPaymentTypeComponent {
  @Input('required') isRequired = false;
  @Input('multiple') isMultiSelect:boolean = true;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl('premium_payment_type', new FormControl([], Validators.required));
    } else {
      this.form.addControl('premium_payment_type', new FormControl([]));
    }

    this.form.controls['premium_payment_type'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['premium_payment_type'].setValue([]);
      }
    });
  }
  selectionChange(event:any){
    if(!this.isMultiSelect && event.isUserInput && event.source.selected){
      this.form.controls['premium_payment_type'].setValue([event.source.value]);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('premium_payment_type');
  }
}
