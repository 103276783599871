import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-deferment-period',
  templateUrl: './deferment-period.component.html',
  styleUrls: ['./deferment-period.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class DefermentPeriodComponent {
  @Input('required') isRequired = false;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('deferment_period',  new FormGroup({
      'from': new FormControl(),
      'to': new FormControl()
    }));
  }

  ngAfterContentInit() {
    if (this.form.controls['annuity_type'] && this.form.controls['annuity_type'].value !== 'deferred') {
      this.form.controls['deferment_period'].reset();
      this.form.controls['deferment_period'].disable();
    }
  }
  
  ngOnDestroy(): void {
    this.form.removeControl('deferment_period');
  }
}
