import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ProductComponent implements OnInit {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('prductSelect', { static: true }) prductSelect: MatSelect;
  @Input('required') isRequired = false;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  form: FormGroup;
  @Input('lob') lob: any;
  @Input('product') product: any;
  insurers: any;
  productDataList: any;
  productList: any = [];
  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'product',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('product', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.productDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        // this.form.patchValue({
        //   product: [],
        // });

        if (
          lobSelected === 'health' ||
          lobSelected === 'travel' ||
          lobSelected === 'life' ||
          lobSelected === 'sme' ||
          lobSelected === 'motor' ||
          lobSelected === 'motor4W' ||
          lobSelected === 'cv' ||
          lobSelected === 'finsure'
        ) {
          this.form.get('product')?.setValidators([]);
          this.form.get('product')?.updateValueAndValidity();
        } else {
          this.form.get('product')?.setValidators([Validators.required]);
          this.form.get('product')?.updateValueAndValidity();
        }
        this.productList = [];

        if (
          lobSelected === 'motor' ||
          lobSelected === 'motor4W' ||
          lobSelected === 'cv' ||
          lobSelected === 'misp' ||
          lobSelected === 'finsure'
        ) {
          if (this.productDataList[lobSelected]['product']) {
            for (
              let i = 0;
              i <= this.productDataList[lobSelected]['product'].length - 1;
              i++
            ) {
              let productValue =
                this.productDataList[lobSelected]['product'][i];
              if (lobSelected === 'finsure') {
                this.productList.push(
                  this.productDataList[lobSelected]['product'][i]
                );
              } else {
                // if (
                //   this.productDataList[lobSelected]['sub_product_type'][
                //     productValue
                //   ] == null
                // ) 
                // {
                  this.productList.push(
                    this.productDataList[lobSelected]['product'][i]
                  );
                // }
              }
            }
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('product');
  }
}
