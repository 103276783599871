import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { RESOURCES } from 'src/app/core/utils/constants';
import { StateService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CopyButtonComponent implements OnInit {
  @Input() show:boolean;
  isDisabled:boolean;
  form: FormGroup;
  a:any;
  ruleId: any;
  constructor(
    private router: Router,
    private ctrlContainer: FormGroupDirective,
    private activatedRoute: ActivatedRoute,
    public stateService: StateService,
  ) { }
  ngOnInit(): void {
    this.isDisabled=true;
    if(this.stateService.isGranted(RESOURCES.COPY_MAIN_RULES,2)){
      this.isDisabled=false;
    }
    this.form=this.ctrlContainer.form;
    const id=document.getElementById('new');
    // id.style.display='show'
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
          this.ruleId = params['id'];
      }
  });
    this.a=window.location.href.replace(this.ruleId,'');
    const a=JSON.parse(localStorage.getItem('copy'));
    if(!this.show){
      id.style.display='none';
     setTimeout(() => {
       this.form.patchValue(a);
       this.form.controls['rule_name']?.setValue(null);
       this.form.controls['evi_capture']?.setValue(null);
       this.form.controls['insurer_email']?.setValue(null);
       this.form.get('payin')?.reset();
       this.form.get('payout')?.reset();
       this.form.get('actual_payin')?.reset();

    }, 1000);
    localStorage.removeItem('copy');
  } 
  }
  copy(){
    if(this.show){
      localStorage.setItem('copy',JSON.stringify(this.form.value)); 
    }
    window.open(this.a)
}
}
