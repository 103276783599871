export const environment = {
    production: false,
    amsurl: "https://accounts.rbstaging.in/",
    paymasterurl : "https://partners.rbstaging.in",
    paymaster_fe_url:"https://partners.rbstaging.in",
    products: `{
        "rb": {
            "domain": "https://dev.renewbuy.com",
            "headers": {"a": "b"}
        },
        "finsure": {
            "domain": ""
        },
        "form_data": {
            "domain": "",
            "headers": {
                "Content-Type": "multipart/form-data"
            }
        }
    }`
}