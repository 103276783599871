import { Component, OnInit,Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-chassis-no-field',
  templateUrl: './chassis-no-field.component.html',
  styleUrls: ['./chassis-no-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ChassisNoFieldComponent implements OnInit {
  alphaRegex = /^[a-zA-Z0-9]+$/;
  @Input('required') isRequired = false;

  form: FormGroup;
  chassisControl = new FormControl(null, [ Validators.minLength(6)]);

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('chassis_no', this.chassisControl);

    this.form.controls['business_type'].valueChanges.subscribe((val: any) => {
      if ((val.indexOf('new_business') != -1)) {
        this.isRequired = true;
        if (!this.chassisControl.hasValidator(Validators.required)) {
          this.chassisControl.addValidators(Validators.required);
          this.chassisControl.updateValueAndValidity();
        }
      } else if(this.chassisControl.hasValidator(Validators.required)){
        this.chassisControl.removeValidators(Validators.required);
        this.isRequired = false;
        this.chassisControl.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('chassis_no');
  }
}
