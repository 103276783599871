import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-no-of-members',
  templateUrl: './no-of-members.component.html',
  styleUrls: ['./no-of-members.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class NoOfMembersComponent implements OnInit{

  @Input('required') isRequired = false;
  form: FormGroup;
  memberCountForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });
  constructor(private ctrlContainer: FormGroupDirective) { };
  ngOnInit(): void {
    if (this.isRequired) {
      this.memberCountForm.controls['to'].addValidators([Validators.required, rangeValidator.bind(this, this.memberCountForm), alternateValidator.bind(this, this.memberCountForm)]);
      this.memberCountForm.controls['from'].addValidators([Validators.required, rangeValidator.bind(this, this.memberCountForm), alternateValidator.bind(this, this.memberCountForm)]);
    }
    else {
      this.memberCountForm.controls['to'].addValidators(rangeValidator.bind(this, this.memberCountForm));
      this.memberCountForm.controls['from'].addValidators(rangeValidator.bind(this, this.memberCountForm));
    }

    this.form = this.ctrlContainer.form;

    if (this.form.controls['memberCountForm']) {
      this.memberCountForm.patchValue(this.form.controls['memberCountForm'].value);
      this.form.removeControl('memberCountForm');
    }

    this.form.addControl('memberCountForm', this.memberCountForm)
  }

  change() {
    if (parseInt(this.memberCountForm.controls['to'].value) >= 10) {
      this.memberCountForm.controls['to'].setValue(10);
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('memberCountForm');
  }

}
