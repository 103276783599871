import { AfterViewInit, Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpService } from "src/app/core/services/http.service";
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { formatAllDates, sort } from 'src/app/core/utils/utility.functions';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
@Component({
  selector: 'app-extend-dialog',
  templateUrl: './extend-dialog.component.html',
  styleUrls: ['./extend-dialog.component.scss'],
})
export class ExtendDialogComponent implements AfterViewInit{
  carry:FormGroup;
  fileCtrl: FormControl = new FormControl(null, Validators.required)
  lob:any;
  ruleId:any;
  carryData:any;
  ruleData:any;
  flag=false;
  result:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  actionButtonLabel: string = '';
  action: boolean;
  setAutoHide: boolean = true;
  autoHide: number = 5000;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ExtendDialogComponent>,private http: HttpService,public snackBar: MatSnackBar) {}
  ngOnInit() {
    this.carry=new FormGroup({
      'evi_capture': this.fileCtrl
    });
    this.lob=this.data['lob']
    this.ruleId=this.data['ruleId'];
    this.ruleData=this.data['ruleData']
    // console.log(this.ruleData)
    this.carryData=this.data;
    this.flag=true;
  }
  submit()
  {
    const url = `/services/api/v1/console`;
    const data1 = {end_date:'',lob:'',rule_id:'',evi_capture:''};
    data1['end_date']=formatAllDates(this.carry.get('rule_date').get('end_date').value);
    data1['evi_capture']=this.carry.get('evi_capture').value;
    data1["lob"] = this.lob;
    data1["rule_id"] = this.ruleId;
    this.http.post(url, data1).subscribe((res: any) => {
      this.result = res['message'];
      // console.log(res);
      let config = new MatSnackBarConfig();
      config.verticalPosition = this.verticalPosition;
      config.horizontalPosition = this.horizontalPosition;
      config.duration = this.setAutoHide ? this.autoHide : 0;
      this.snackBar.open(
        this.result,
        this.action ? this.actionButtonLabel : undefined,
        config
      );
    });
    this.closeDialog()
  }
  closeDialog() {
    this.dialogRef.close();
}
  ngAfterViewInit() {
    this.carry.get('rule_date').get('start_date').disable();
    this.carry.get('rule_date').patchValue(this.ruleData['rule_date']);
  }
  onFileChange(file: File) {
    let fileExt:any = file.name.split('.');
    fileExt = fileExt[fileExt.length - 1];
    const timeNow = new Date();
    const fileName = `${this.lob}_${timeNow.getFullYear()}-${timeNow.getMonth() + 1}-${timeNow.getDate()}_${timeNow.getHours()}:${timeNow.getMinutes()}:${timeNow.getSeconds()}.${fileExt}`;
    const newFile = new File([file], fileName, { type: file.type });
    this.uploadFile(newFile);
  }
  uploadFile(file:File) {
    const formData = new FormData();
    formData.append('file', file);
    const url = '/services/api/v1/rules/upload_document';
    this.http.post(url, formData).subscribe((res: any) => {
      const s3Url = res['url'];
      this.fileCtrl.setValue(s3Url);
    });
  }
}

