import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-maturity-age',
  templateUrl: './maturity-age.component.html',
  styleUrls: ['./maturity-age.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class MaturityAgeComponent {
  form: FormGroup;
  ageForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.ageForm.controls['to'].addValidators(rangeValidator.bind(this, this.ageForm));
    this.ageForm.controls['from'].addValidators(rangeValidator.bind(this, this.ageForm));

    this.form = this.ctrlContainer.form;

    if (this.form.controls['maturity_age']) {
      this.ageForm.patchValue(this.form.controls['maturity_age'].value);
      this.form.removeControl('maturity_age');
    }

    this.form.addControl('maturity_age', this.ageForm);

  }

  ngOnDestroy(): void {
    this.form.removeControl('maturity_age');
  }
}
