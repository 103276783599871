import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-insurer-field-life-payout',
  templateUrl: './insurer-field-life-payout.component.html',
  styleUrls: ['./insurer-field-life-payout.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class InsurerFieldLifePayoutComponent implements OnInit {
  @Input('lob') lob: any;
  @Input('required') isRequired = true;
  @Input('productDependent') productDependent: boolean = false;
  @Input('allRuleIdData') allRuleIdData: any;
  insurers: any = [];
  lobSelected: any = '';
  form: FormGroup;
  insurerdata: any = [];
  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService,
    public activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl(
        'insurer',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('insurer', new FormControl(null));
    }

    if (this.productDependent) {
      this.form.controls['product_type'].valueChanges.subscribe((val: any) => {
        if (val) {
          this.getinsurer(val);
        }
      });
    }
  }

  getinsurer(product?: any) {
    const url = '/services/api/v1/rb_masters/life_insurer_list';
    const params: any = {};
    if (product) {
      params['product_type'] = product;
    }
    // this.form.patchValue({
    //   insurer: '',
    // });
    this.http.get(url, params).subscribe((res: any) => {
      this.insurers = res['data'];
      this.insurers = sort(this.insurers, 'insurer_name');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allRuleIdData']) {
      if (changes['allRuleIdData'].currentValue.insurer !== undefined) {
        if (changes['allRuleIdData'].currentValue.insurer.length > 0) {
          this.insurerdata = changes['allRuleIdData'].currentValue.insurer;
          this.getinsurer(changes['allRuleIdData'].currentValue.product_type);

          if (this.form === undefined) {
            this.form = this.ctrlContainer.form;
            if (this.isRequired) {
              this.form.addControl(
                'insurer',
                new FormControl(null, Validators.required)
              );
            } else {
              this.form.addControl('insurer', new FormControl(null));
            }
          }
          this.form.patchValue({
            insurer: this.insurerdata,
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('insurer');
  }
}
