import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

import { Column } from 'src/app/features/table/table.interface';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent {

  columnDefs: any[] = [];
  displayColumns: any = [];

  lob: string;
  isContest: boolean = false;
  bp:boolean=false

  selectedRuleType: string = 'main';

  dataSource: MatTableDataSource<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TableDialogComponent>) {
    this.lob = data['lob'];
    if(this.lob=='bp'){
      this.bp=true
    }
    const exact_matching_rules = data.data.exact_matching_rule || [];
    const matching_rules = data.data.matching_rule || [];
    exact_matching_rules.forEach((rule: any) => rule.class = 'exact-match');
    matching_rules.forEach((rule: any) => rule.class = 'partial-match');
    const d = [...exact_matching_rules, ...matching_rules];
    this.dataSource = new MatTableDataSource(d);
    this.onTableDataChange(d);
  };

  onTableDataChange(data: any) {
    if (data[0]) {
      this.columnDefs = this.createColumnDef(data[0]);
      this.displayColumns = this.columnDefs.map((c) => c.columnDef);
    }
  }


  createColumnDef(data: any) {
    const columnDef = [];
    if (data) {
      for (let key of Object.keys(data)) {
        if (key == 'class') { continue };
        const def: Column = {
          columnDef: key,
          header: key.replaceAll("_", " "),
          cell: (element: Record<string, any>) => {
            if (Array.isArray(element[key])) {
              let st = "";
                element[key].forEach((val: any) => st += `${val}\n `);
              return st || 'All';
            }
            if (!element[key] && element[key] === false) {
              return `${element[key]}`
            }
            return `${element[key] || 'All'}`;
          },
          isLink: false,
          url: '',
          isSelect: false
        };
        if (key == '_id') {
          def.isLink = true;
          if (this.isContest) {
            def.url = (element: any) => `/rules/${this.lob}/${element[key]}`;
          } else {
            def.url = (element: any) => `/rules/${this.selectedRuleType}/${this.lob}/${element[key]}`;
          }
          def.cell = (element: Record<string, any>) => `View`;
          def.header = 'Action';
        };
        if (key == 'evi_capture') {
          def.url = (element:any)=>element[key];
          def.isLink = true;
          def.cell = (element: Record<string, any>) => `View Evidence`;
        }
        columnDef.push(def);
      }

    }
    return columnDef;
  }

  clickOk() {
    this.dialogRef.close('continue');
  }

  clickCancel() {
    this.dialogRef.close();
  }

}
