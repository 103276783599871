<label class="subHeading"
  >Plan Name<span *ngIf="isRequired" class="star">*</span></label
>
<app-search-dropdown
  [dataSearchKey]="'plan_name'"
  [dataValueKey]="'rb_id'"
  [dataSource]="plans"
  [control]="plannameControl"
  [multiple]="!isRequired"
></app-search-dropdown>
