import { Component } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-payout-net-percent-range',
  templateUrl: './payout-net-percent-range.component.html',
  styleUrls: ['./payout-net-percent-range.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayoutNetPercentRangeComponent {
  form: FormGroup;

  rangeForm = new FormGroup({
    "from": new FormControl(),
    "to": new FormControl(),
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.rangeForm.controls['to'].addValidators(rangeValidator.bind(this, this.rangeForm));
    this.rangeForm.controls['from'].addValidators(rangeValidator.bind(this, this.rangeForm));

    if (this.form.controls['net_percent']) {
      this.rangeForm.patchValue(this.form.controls['od_percent'].value);
      this.form.removeControl('net_percent');
    }

    this.form.addControl('net_percent', this.rangeForm);
  }

  ngOnDestroy(): void {
    this.form.removeControl('net_percent');
  }
}
