<div class="container nav-bar">
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel *ngFor="let option of menuOptions" hideToggle>
         
            <mat-expansion-panel-header>
                <mat-panel-title class="justify-content-between">
                    <p>{{ option.title }}</p>
                    <span class="open h-100"><img src="../../../assets/icons/closed_menu.svg"></span>
                    <span class="close h-100"><img src="../../../assets/icons/opened_menu.svg"></span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ul >
                <ng-container *ngFor="let something of option.insides; let i =index" >
                    <li class="inactive" *appIsGranted="[something.module, 1]"  routerLinkActive="active">
                        <a [routerLink]="something.link" >{{something.name}}</a>
                    </li>
                </ng-container>
                </ul>
            
        </mat-expansion-panel>
    </mat-accordion>
</div>