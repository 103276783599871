import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-payment-instrument',
  templateUrl: './payment-instrument.component.html',
  styleUrls: ['./payment-instrument.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PaymentInstrumentComponent implements OnInit, OnDestroy {
  @Input('required') isRequired: boolean = false;
  
  form: FormGroup;
  paymentOptions = [
    { key: 'Cash', value: 2 },
    { key: 'Online', value: 0 },
    { key: 'Cheque',value: 1 },
    { key: 'Float',value: 3 },
    { key: 'Credit Card',value: 4 },
    { key: 'Net Banking',value: 5 },
    { key: 'Debit Card',value: 6 },
  ];

  constructor(private ctrlContainer: FormGroupDirective) {}

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.form.addControl('payment_instrument', new FormControl([]));
  }

  ngOnDestroy(): void {
    this.form.removeControl('payment_instrument');
  }
}

