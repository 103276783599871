<!-- <p>insurer-field-two-wheeler works!</p> -->
<div class="even">
  <mat-label class="subHeading"
    >Insurer<span *ngIf="isRequired" class="star">*</span></mat-label
  >
  <button
    class="side"
    (click)="form.controls['insurerMultiple'].setValue(null)"
  >
    Remove
  </button>
</div>
<mat-select
  *ngIf="!isMultiSelect; else multi"
  formControlName="insurerMultiple"
  class="insurer"
  placeholder="Select"
>
  <mat-option *ngFor="let insurerObj of insurers" [value]="insurerObj.rb_id">
    {{ insurerObj.insurer_name }}
  </mat-option>
</mat-select>
<ng-template #multi>
  <app-search-dropdown
    [dataSearchKey]="'insurer_name'"
    [dataValueKey]="'rb_id'"
    [dataSource]="insurers"
    [control]="form.controls['insurerMultiple']"
    [multiple]="true"
  ></app-search-dropdown>
</ng-template>
