import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";

export function sort(arr: any[], sortBy?: string, hasObject = true) {
    arr.sort(function (a: any, b: any) {
        let nameA;
        let nameB;
        if (hasObject) {
            nameA = a[sortBy].toUpperCase();
            nameB = b[sortBy].toUpperCase();
        } else {
            nameA = a;
            nameB = b;
        }

        if (nameA > nameB) {
            return 1;
        }
        if (nameA < nameB) {
            return -1;
        }
        return 0;
    });

    return arr;
}

export function formatDate(date: any) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
}

export function formatAllDates(data: any) {
    if (Array.isArray(data)) {
        for (let d of data) {
            formatAllDates(d);
        }
    } else if (data && typeof data === 'object' && data.constructor === Object) {
        for (let d of Object.keys(data)) {
            data[d] = formatAllDates(data[d]);
        }
    } else {
        try {
            if (typeof (data) === "string" && (!Number(data) && Number(data) !== 0) && Date.parse(data)) {
                data = new Date(data);
            }
            data = formatDate(data);
        } catch (error) { }
    }
    return data;
}


export function getFormData(obj: any = {}, formData = new FormData(), key = '') {
    if (!([Array, File, Object].includes(obj.constructor))) {
        return formData;
    }

    // Handle File recursive
    if (obj instanceof File) {
        formData.append(key, obj);
        return formData;
    }

    for (const prop in obj) {
        // Validate value type
        if (obj[prop] && !([String, Number, Boolean, Array, Object, File].includes(obj[prop].constructor))) {
            continue;
        }

        // Set deep index of prop
        const deepKey = key ? key + `[${prop}]` : prop;

        // Handle array
        if (Array.isArray(obj[prop])) {
            obj[prop].forEach((item: any, index: any) => {
                getFormData(item, formData, `${deepKey}[${index}]`);
            })
            continue;
        }

        (typeof obj[prop] === 'object' && obj[prop] && obj[prop].constructor === Object)
            ? getFormData(obj[prop], formData, deepKey) // Handle object
            : formData.append(deepKey, [undefined, null].includes(obj[prop]) ? '' : obj[prop]) // Handle string, number, boolean
    }

    return formData;
}


export function rangeValidator(form: FormGroup): { [s: string]: boolean } {
    if(!isValidNumber(form.controls['from'].value) && !isValidNumber(form.controls['to'].value)){
        return null;
    }

    if(isValidNumber(form.controls['from'].value) && isValidNumber(form.controls['to'].value)){
        if(Number(form.controls['from'].value) > Number(form.controls['to'].value)){
            form.controls['from'].setErrors({'small':true})
            form.controls['to'].setErrors({'small':true})
            return {'small': true};
        }
    }
    console.log(form.controls['to'].hasError('small'));

    if (form.controls['from'].hasError('small')) {
        form.controls['from'].setErrors({'small':null});
        form.controls['from'].updateValueAndValidity();
    }
    if (form.controls['to'].hasError('small')) {
        form.controls['to'].setErrors({'small':null});
        form.controls['to'].updateValueAndValidity();
    }
    return null;
}
function isValidNumber(value:any){
    if(value === null || value === ""){
        return false;
    }
    return !isNaN(Number(value));
}
export function alternateValidator(form: FormGroup): { [s: string]: boolean } {
    if(!isValidNumber(form.controls['from'].value) && !isValidNumber(form.controls['to'].value)){
        if (!form.controls['to'].hasValidator(Validators.required)) {
            form.controls['to'].addValidators(Validators.required);
            form.controls['to'].updateValueAndValidity();
        }
        if (!form.controls['from'].hasValidator(Validators.required)) {
            form.controls['from'].addValidators(Validators.required);
            form.controls['from'].updateValueAndValidity();
        }
    }else if(isValidNumber(form.controls['from'].value) || isValidNumber(form.controls['to'].value)){
        if (form.controls['to'].hasValidator(Validators.required)) {
            form.controls['to'].removeValidators(Validators.required);
            form.controls['to'].updateValueAndValidity();
        }
        if (form.controls['from'].hasValidator(Validators.required)) {
            form.controls['from'].removeValidators(Validators.required);
            form.controls['from'].updateValueAndValidity();            
        }
    }
    // if((!form.controls['from'].value && !form.controls['to'].value)||(form.controls['from'].value && form.controls['to'].value)){
    //     form.controls['from'].hasValidator(Validators.required);
    //     form.controls['from'].updateValueAndValidity;
    //     form.controls['to'].hasValidator(Validators.required);
    //     form.controls['to'].updateValueAndValidity;
    //     return null;
    // }
    // else if(form.controls['from'].value){
    //     form.controls['to'].removeValidators(Validators.required);
    //     return null;
    // }
    // else if(form.controls['to'].value){
    //     form.controls['from'].removeValidators(Validators.required);
    //     return null;
    // }
    // else{
    //     form.controls['from'].removeValidators(Validators.required);
    //     form.controls['to'].removeValidators(Validators.required);
    //     return null;
    // }
    return null;
}

export function removeDuplicates(item: any) {
    return [...new Set(item.map((val: any) => JSON.stringify(val)))].map((val: any) => JSON.parse(val));
}

export interface Mapper {
    dataSource: any
    dataKey: string | number
    parentKey?: string | number
    selfKey: any
    valuesToMap: any
}

export function mapper(main_mapping: any, data: Mapper) {
    let toMap = data.valuesToMap;

    for (const city_id of toMap) {
        if (!main_mapping.find((val: any) => val[data.selfKey] == city_id)) {
            if (data.parentKey) {
                const city_obj = data.dataSource.find((obj: any) => obj[data.dataKey] == city_id);
                const state_id = city_obj[data.parentKey];
                let obj = main_mapping.find((val: any) => val[data.parentKey] == state_id && !val[data.selfKey]);
                if (!obj) {
                    let partialObj = main_mapping.find((val: any) => val[data.parentKey] == state_id);
                    let objToPush: any = {};
                    for (const [k, v] of Object.entries(partialObj)) {
                        objToPush[k] = v;
                        if (k == data.selfKey) { break };
                    }
                    obj = objToPush;
                    obj[data.selfKey] = city_id;
                    main_mapping.push(obj);
                } else {
                    obj[data.selfKey] = city_id;
                }
            } else {
                const obj: any = { [data.selfKey] : city_id };
                main_mapping.push(obj);
            }
        }
    }
    const toRemove = [];
    for (const [idx, obj] of main_mapping.entries()) {
        if (obj[data.selfKey] && toMap.indexOf(obj[data.selfKey]) === -1) {
            toRemove.push(idx);
        }
    }
    toRemove.forEach((idx: any) => {
        if (main_mapping.filter((obj: any) => obj[data.parentKey] == main_mapping[idx][data.parentKey]).length > 1 || !data.parentKey) {
            main_mapping.splice(idx, 1);
        } else {
            const ownKey = Object.keys(main_mapping[idx]).indexOf(data.selfKey);
            for (const [i, key] of Object.keys(main_mapping[idx]).entries()) {
                if (Number(i) < ownKey) { continue };
                main_mapping[idx][key] = null;
            }
        }
    });
    main_mapping = removeDuplicates(main_mapping);
    return main_mapping;
}

export function getRandomTimeStampedStr() {
    const randomstr = Math.random().toString(36).substring(2) + Date.now().toString(36);
    return `${randomstr}_${formatDate(new Date())}`;
}