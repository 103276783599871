
<form [formGroup]="consoleTransferform">
        <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
        <h2 class="console">Console Transfer</h2>
        <div class="container">
            <div class="row mb-3">
                <div class="col-6">
                    <mat-label class="subHeading">LOB</mat-label>
                    <input class="des" formControlName="lob" readonly/>
                </div>

                <div class="col-6">
                    <app-insurer-field [lob]="this.lob"></app-insurer-field>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <mat-label class="subHeading">End Date<span class="star">*</span></mat-label>
                    <mat-form-field appearance="fill"  class="custom-mat-form-field custom-datpicker des">
                        <mat-label>End Date</mat-label>
                        <input
                            matInput
                            [min]="minDate"
                            [ngxMatDatetimePicker]="picker2"
                            placeholder="dd/mm/yyyy"
                            formControlName="end_date"
                        >
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker
                            #picker2
                            [showSpinners]="showSpinners"
                            [showSeconds]="showSeconds"
                            [stepHour]="stepHour"
                            [stepMinute]="stepMinute"
                            [stepSecond]="stepSecond"
                            [touchUi]="touchUi"
                            [enableMeridian]="enableMeridian">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <app-evidence-console [lob]="this.lob"></app-evidence-console>
                </div>
            </div>
            <button type="button" (click)="consoleTransfer()" class="btn submit-btn " [disabled]="!consoleTransferform.valid">Send For Approval</button>
        </div>
  </form>
