import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-policy-tenure',
  templateUrl: './policy-tenure.component.html',
  styleUrls: ['./policy-tenure.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PolicyTenureComponent implements OnInit {
  @Input('required') isRequired = false;
  form: FormGroup;
  policy_tenure: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });
  constructor(private ctrlContainer: FormGroupDirective) { };
  ngOnInit(): void {
    if (this.isRequired) {
      this.policy_tenure.controls['to'].addValidators([Validators.required, rangeValidator.bind(this, this.policy_tenure), alternateValidator.bind(this, this.policy_tenure)]);
      this.policy_tenure.controls['from'].addValidators([Validators.required, rangeValidator.bind(this, this.policy_tenure), alternateValidator.bind(this, this.policy_tenure)]);
    }
    else {
      this.policy_tenure.controls['to'].addValidators(rangeValidator.bind(this, this.policy_tenure));
      this.policy_tenure.controls['from'].addValidators(rangeValidator.bind(this, this.policy_tenure));
    }

    this.form = this.ctrlContainer.form;

    if (this.form.controls['policy_tenure']) {
      this.policy_tenure.patchValue(this.form.controls['policy_tenure'].value);
      this.form.removeControl('policy_tenure');
    }

    this.form.addControl('policy_tenure', this.policy_tenure)
  }

  change() {
    if (parseInt(this.policy_tenure.controls['to'].value) >= 10) {
      this.policy_tenure.controls['to'].setValue(10);
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('policy_tenure');
  }
}
