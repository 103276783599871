import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { Apiurls } from '../insurer-field/Api_urls';

@Component({
  selector: 'app-sub-product-type',
  templateUrl: './sub-product-type.component.html',
  styleUrls: ['./sub-product-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SubProductTypeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('subprductSelect', { static: true }) subprductSelect: MatSelect;
  @Input('required') isRequired = false;
  form: FormGroup;
  insurers: any;
  @Input('lob') lob: any;
  @Input('product') product: any;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  subProductDataList: any = [];
  subProductList: any = [];
  lobSelected: any = '';
  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'subproducttype',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('subproducttype', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.subProductDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;

        if (
          this.lobSelected === 'health' ||
          this.lobSelected === 'life' ||
          this.lobSelected === 'travel' ||
          this.lobSelected === 'sme' ||
          this.lobSelected === 'finsure' ||
          this.lobSelected === 'motor' ||
          this.lobSelected === 'motor4W'
        ) {
          this.form.get('subproducttype')?.setValidators([]);
          this.form.get('subproducttype')?.updateValueAndValidity();
        } else if(this.lobSelected === 'cv') {
          this.form.get('subproducttype')?.setValidators([Validators.required]);
          this.form.get('subproducttype')?.updateValueAndValidity();
        }
        else{
          this.form.removeControl('subproducttype');
        }
        this.subProductList = [];
        let productSelected = 'Commercial_Vehicle';

        if (
          this.lobSelected === 'motor' ||
          this.lobSelected === 'motor4W' ||
          this.lobSelected === 'cv'
        ) {
          this.subProductList =
            this.subProductDataList[this.lobSelected]['sub_product_type'][
              productSelected
            ];
        }
      }
    }
    if (changes['product']) {
      if (changes['product'].currentValue !== '') {
        let productSelected = changes['product'].currentValue;
        this.subProductList = [];
        if (this.lobSelected === 'finsure') {
          this.subProductList =
            this.subProductDataList[this.lobSelected]['sub_product_type'];
        } else if (
          this.lobSelected === 'sme' ||
          this.lobSelected === 'health' ||
          this.lobSelected === 'life'
        ) {
          this.subProductList = [];
        } else {
          if (
            this.subProductDataList[this.lobSelected]['sub_product_type'] !==
            undefined
          ) {
            this.subProductList =
              this.subProductDataList[this.lobSelected]['sub_product_type'][
                productSelected
              ];
          }
        }
      }
    }
    // if (changes['allRuleIdData']) {
    //   if (
    //     changes['allRuleIdData'].currentValue.sub_product_type !== undefined
    //   ) {
    //     let getUrl = window.location.href;
    //     if (getUrl.includes('payout-processing/main/edit-payout-rule')) {
    //       let subProduct =
    //         changes['allRuleIdData'].currentValue.sub_product_type;
    //       this.form.patchValue({
    //         subproducttype: subProduct,
    //       });
    //     }
    //   }
    // }
  }

  ngOnDestroy(): void {
    this.form.removeControl('subproducttype');
  }
}
