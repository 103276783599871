import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-cubic-capacity-field',
  templateUrl: './cubic-capacity-field.component.html',
  styleUrls: ['./cubic-capacity-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CubicCapacityFieldComponent implements OnInit {
  @Input('required') isRequired = false;

  form: FormGroup;

  ccForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit(): void {
    if (this.isRequired) {
      this.ccForm.controls['to'].addValidators([Validators.required, rangeValidator.bind(this,this.ccForm),alternateValidator.bind(this,this.ccForm)]);
      this.ccForm.controls['from'].addValidators([Validators.required, rangeValidator.bind(this,this.ccForm),alternateValidator.bind(this,this.ccForm)]);
    } else {
      this.ccForm.controls['to'].addValidators(rangeValidator.bind(this,this.ccForm));
      this.ccForm.controls['from'].addValidators(rangeValidator.bind(this,this.ccForm));
    }

    this.form = this.ctrlContainer.form;

    if (this.form.controls['cc']) {
      this.ccForm.patchValue(this.form.controls['cc'].value);
      this.form.removeControl('cc');
    }

    this.form.addControl('cc', this.ccForm);
  }

  ngOnDestroy(): void {
    this.form.removeControl('cc');
  }
}
