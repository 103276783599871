import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserPermissions } from 'src/app/shared/models/user.permissions';
import { GlobalService } from 'src/app/shared/services/auth.service/auth.service';
import { StateService } from 'src/app/shared/services/global.service';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public loggedIn = new BehaviorSubject<boolean>(false);
    public userExecutiveInfo = new BehaviorSubject<string>('');
    public obtainedToken = new BehaviorSubject<string>('');

    private BASE_URL = '/api/v1/accounts';
    private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
    fetchProfileInformation: any;

    constructor(
        private globalService: GlobalService,
        private state: StateService
    ) {
        this.logOut = this.logOut.bind(this);
    }

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    getToken(data: any) {
        this.obtainedToken.next(data);
    }

    getUserExecutiveData(data: any) {
        this.userExecutiveInfo.next(data);
    }

    setUser = (user: any) => {
        if (user && user.token) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('ta_user_type', user.user_type);
            localStorage.setItem('ta_user_email', user.email);
            localStorage.setItem('ta_user_name', user.first_name);
            localStorage.setItem('tier', user.tier);
            localStorage.setItem('registration_date', user.registration_date);
            localStorage.setItem('username', user.username);
            localStorage.setItem('pos_code', user.pos_code);

            this.loggedIn.next(true);
            this.getToken(user.token);
            this.getUserExecutiveData(user);
            this.globalService.getUserPermissions().subscribe((resp: UserPermissions) => {
                let permissions = resp;
                this.state.addState(permissions);
                this.state.send(true);
            });

        } else {
            this.logOut();
        }
    }


    /**
     * We don't required logout function
     * as currenlty we are not checking for this
     * just to clear out value
     */
    logOut() {
        localStorage.clear();
        this.loggedIn.next(false);
        setTimeout(function() {window.location.href = environment.amsurl + "?next="+window.location.href}, 2000);


    }

    logOutWithoutNext()
    {
        localStorage.clear();
        setTimeout(function() {window.location.href = environment.amsurl}, 2000);

    }
    getTokenFromStorage() {
        return localStorage.getItem('token')
    }

}
