<mat-label class="subHeading d-block mb-2">Policy issued date</mat-label>

<mat-form-field>
    <mat-label>Select Date Range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [formControl]="dateForm.controls['from']">
        <input matEndDate placeholder="End date" [formControl]="dateForm.controls['to']">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>