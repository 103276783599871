import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';

@Injectable({providedIn: 'root'})
export class TableDataService {

  apiUrl = '';

  selectedData:any[] = [];

  triggerSubject = new BehaviorSubject<any>(null);
  getSelectedRowInfo: Subject<any> = new Subject();

  constructor(private http: HttpService) { }

  getData(params: any={}, filter='', data:any=null, sortOrder = 'asc',
    pageNumber = 0, pageSize = 3): Observable<any> {
    params = {
      // 'filter': filter,
      'sortOrder': sortOrder,
      'page_no': pageNumber+1,
      'page_size': pageSize,
      'filters': params
    };

    if(data){
      return this.http.post(this.apiUrl, data, params).pipe(
        map((res:any) => this.formatData(res['data']))
      );
    }
    return this.http.get(this.apiUrl, params).pipe(
      map((res:any) => this.formatData(res['data']))
    );
  }

  addSelectedElement(value: any) {
    if (this.selectedData.indexOf(value) == -1) {
      this.selectedData.push(value);
      this.getSelectedRowInfo.next(this.selectedData);
    }
  }

  removeSelectedElement(value: any) {
    if (this.selectedData.indexOf(value) != -1) {
      this.selectedData.splice(this.selectedData.indexOf(value), 1);
      this.getSelectedRowInfo.next(this.selectedData);
    }
  }

  formatData(data: any) {
    return data;
    for (let obj of data['data']) {
      for (const key of Object.keys(obj)) {
        if (!obj[key]) {
          obj[key] = "";
        }
        else if (typeof(obj[key]) == 'object') {
          const strigified = JSON.stringify(obj[key]);
          obj[key] = strigified.substring(1, strigified.length - 1);
        }
      }
    }
    return data;
  }
}
