import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';
import { Router } from '@angular/router';
import { StateService } from 'src/app/shared/services/global.service';
import { RESOURCES } from 'src/app/core/utils/constants';

// If using Moment
const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h23',
  hour: '2-digit',
  minute: '2-digit',
};

const MAT_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
    { provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DateFieldComponent implements OnInit, OnDestroy {
  @Input('label') label: string = "Effective Date & Time";
  form: FormGroup;
  ruleType: string = '';
  date: any;
  @Input('flag') flag: boolean;
  @Input('Erequired') Erequired: boolean;
  @Input('need') need = true;

  enableEndDate = false;


  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;

  minDate: string | Date = '';

  public start_dateControl = new FormControl(
    getMonthStartEnd()[0],
    Validators.required
  );
  public end_dateControl = new FormControl(
    getMonthStartEnd()[1],
    Validators.required
  );

  constructor(
    private ctrlContainer: FormGroupDirective,
    private router: Router,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    console.log('DATE INIT');

    this.form = this.ctrlContainer.form;
    this.ruleType = this.router.url.split('/').filter((val: any) => val)[1];
    if (!this.need) {
      this.start_dateControl = new FormControl(null)
      this.end_dateControl = new FormControl(null)
    }
    if (this.flag) {
      this.enableEndDate = true;
    }

    this.setMindate();

    this.form.addControl(
      'rule_date',
      new FormGroup({
        'start_date': this.start_dateControl,
        'end_date': this.end_dateControl,
      })
    );
  }

  ngOnDestroy() {
    this.form.removeControl('rule_date');
  }

  private setMindate() {
    const mapping = something[this.ruleType];
    if (!mapping) { return };

    const resources = mapping['resources'];

    for (const resource of resources) {
      if (this.stateService.isGranted(resource, 2)) {
        let dateDifference = getDateByPerm(this.ruleType, resource);
        this.minDate = '';
        if (dateDifference || dateDifference == 0) {
          const d = new Date();
          d.setDate(d.getDate() - dateDifference);
          this.minDate = d;
        }
        break;
      }
    }
  }

}

const something: any = {
  main: {
    resources: [
      RESOURCES.MAIN_RULE_RULE_CREATION_NO_BACKDATION_LIMIT,
      RESOURCES.MAIN_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS,
      RESOURCES.MAIN_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS,
      RESOURCES.MAIN_RULE_APPROVAL_OF_RULE,
      RESOURCES.MAIN_RULE_RULE_CREATION,
    ],
    dateMappings: {
      [RESOURCES.MAIN_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS]: 30,
      [RESOURCES.MAIN_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS]: 7,
      [RESOURCES.MAIN_RULE_APPROVAL_OF_RULE]: 0,
      [RESOURCES.MAIN_RULE_RULE_CREATION]: 0,
    },
  },
  special: {
    resources: [
      RESOURCES.SPECIAL_RULE_RULE_CREATION_NO_BACKDATION_LIMIT,
      RESOURCES.SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS,
      RESOURCES.SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS,
      RESOURCES.SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_03_DAYS,
      RESOURCES.SPECIAL_RULE_APPROVAL_OF_RULE,
      RESOURCES.SPECIAL_RULE_RULE_CREATION,
    ],
    dateMappings: {
      [RESOURCES.SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS]: 30,
      [RESOURCES.SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS]: 7,
      [RESOURCES.SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_03_DAYS]: 3,
      [RESOURCES.SPECIAL_RULE_APPROVAL_OF_RULE]: 0,
      [RESOURCES.SPECIAL_RULE_RULE_CREATION]: 0,
    },
  },
  contest: {
    resources: [
      RESOURCES.CONTEST_RULE_RULE_CREATION_NO_BACKDATION_LIMIT,
      RESOURCES.CONTEST_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS,
      RESOURCES.CONTEST_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS,
      RESOURCES.CONTEST_RULE_APPROVAL_OF_RULE,
      RESOURCES.CONTEST_RULE_CREATION_OF_RULE,
    ],
    dateMappings: {
      [RESOURCES.CONTEST_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS]: 30,
      [RESOURCES.CONTEST_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS]: 7,
      [RESOURCES.CONTEST_RULE_APPROVAL_OF_RULE]: 0,
      [RESOURCES.CONTEST_RULE_CREATION_OF_RULE]: 0,
    },
  },
};

function getDateByPerm(ruleType: string, resource: any) {
  const minDate = Number(something[ruleType]['dateMappings'][resource]);
  return minDate;
}

function getMonthStartEnd() {
  const today = new Date();
  let start = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes() + 5 // As per business requirement +5 min is added because 5 min is leeway time for filling the form.
  );
  let end = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59); // +1 is added in month to compensate for month getting behind when using date as 0.

  return [start, end];
}
