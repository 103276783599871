<label class="subHeading"
  >Product<span *ngIf="isRequired" class="star">*</span></label
>

<mat-select formControlName="product_name">
    <mat-option *ngFor="let obj of product" [value]="obj.rb_id">
        {{obj.name}}
    </mat-option>
</mat-select>

<!-- <app-search-dropdown
  [dataSearchKey]="'name'"
  [dataValueKey]="'rb_id'"
  [dataSource]="product"
  [control]="productControl"
></app-search-dropdown> -->
