import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-history-button',
    templateUrl: './history-button.component.html',
    styleUrls: ['./history-button.component.scss']
})
export class HistoryButtonComponent {

    @Input() ruleId!: string;
    
    constructor(
        private router: Router
    ){}

    ngOnInit(): void {
        // console.log('*******', this.ruleId);
    }

    history(){
        const url: any = this.router.navigate(['/rules','history', this.ruleId]);
    }

}
