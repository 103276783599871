import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-rto-field',
  templateUrl: './rto-field.component.html',
  styleUrls: ['./rto-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RtoFieldComponent implements OnInit {

  rtoList:any = [];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('rto', new FormControl());
  }

  getStatesRto() {
    const url = '/services/api/v1/rb_masters/rtos';

    this.http.get(url).subscribe((res:any) => {
      this.rtoList=res['data'];
    });
  }
}
