import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-payout-percentage-field',
  templateUrl: './payout-percentage-field.component.html',
  styleUrls: ['./payout-percentage-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayoutPercentageFieldComponent implements OnInit {
  label: string = 'Payout Percentage';
  isFlatAmount: boolean;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('payout_value', new FormControl());

    this.form.controls['payout_type'].valueChanges.subscribe((val: any) => {
      if (val == 1) {
        this.label = 'Payout Percentage';
        this.isFlatAmount = false;
      }
      if (val == 2) {
        this.label = 'Payout Amount';
        this.isFlatAmount = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('payout_value');
  }

}
