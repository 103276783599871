<app-payin-out-child
    [form]="form.controls['payout']"
    [lob]="lob"
    [title]="'Pay Out'"
></app-payin-out-child>
<app-payin-out-child
    [form]="form.controls['payin']"
    [lob]="lob"
    [title]="'Pay In'"
></app-payin-out-child>
<app-payin-out-child
    [form]="form.controls['actual_payin']"
    [lob]="lob"
    [title]="'Actual Pay In'"
    [required]="false"
></app-payin-out-child>

<mat-error *ngIf="form.hasError('invalidData')">Invalid Values</mat-error>