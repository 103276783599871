import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-varient-field',
  templateUrl: './varient-field.component.html',
  styleUrls: ['./varient-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class VarientFieldComponent implements OnInit {

  variantList:any = [];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective, private http:HttpService){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('variant', new FormControl());
  }
  getvarient() {
    const url ='/services/api/v1/rb_masters/vehicle-model-variants';
  
    this.http.get(url).subscribe((res:any) => {
      this.variantList =res['data'];
    });
  }
}
