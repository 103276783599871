import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
@Component({
  selector: 'app-lob-bp-field',
  templateUrl: './lob-bp-field.component.html',
  styleUrls: ['./lob-bp-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class LobBpFieldComponent implements OnInit{
  @Input('required') isRequired = false;
  lobs: any = [];
  form: FormGroup;

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService, public activatedRoute: ActivatedRoute) { };

  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl('lob', new FormControl(null, Validators.required));
    } else {
      this.form.addControl('lob', new FormControl());
    }
    this.getlob();
  }

  getlob(){
    const url = '/services/api/v1/rb_masters/escalation_lob?entity=1'
      this.http.get(url).subscribe((res: any) => {
        this.lobs = res['data'].filter((lob:any)=> !lob.key.includes('bp'));
      });

  }

  ngOnDestroy(): void {
    this.form.removeControl('lob');
  }

}
