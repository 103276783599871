import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-company-field',
  templateUrl: './company-field.component.html',
  styleUrls: ['./company-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CompanyFieldComponent {
  @Input('required') isRequired = false;
  // companyList = [
  //   { value: 'motor', name: 'Two Wheeler' },
  //   { value: 'motor4W', name: 'Four Wheeler' },
  // ];
  companyList: any = [];
  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.getCompanyList();
    if (this.isRequired) {
      this.form.addControl(
        'company',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('company', new FormControl());
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('company');
  }

  getCompanyList() {
    const url = `services/api/v1/company`;

    this.http.get(url).subscribe((res: any) => {
      if (res) {
        this.companyList = res.data;
      }
    });
  }
}
