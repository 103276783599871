import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { getPayoutBase, PAYOUT_BASE } from './constants';


@Component({
  selector: 'app-payin-out',
  templateUrl: './payin-out.component.html',
  styleUrls: ['./payin-out.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayinOutComponent implements OnInit {
  @Input('lob') lob: string = 'motor';

  hasValue = {
    [PAYOUT_BASE.OD_PREMIUM.value]: false,
    [PAYOUT_BASE.TP_PREMIUM.value]: false,
    [PAYOUT_BASE.NET_PREMIUM.value]: false,
  };

  payoutControls: any = { "percent": [], "amount": [] };
  payinControls: any = { "percent": [], "amount": [] };
  acPayinControls: any = { "percent": [], "amount": [] };

  payin_payout_types = [
    { 'ctrlName': 'payout', 'displayName': 'Pay Out' },
    { 'ctrlName': 'payin', 'displayName': 'Pay In' },
    { 'ctrlName': 'actualPayin', 'displayName': 'Actual Pay In' }
  ];

  form: FormGroup;

  payoutBase: any[];
  payoutGroup: FormGroup = new FormGroup({});
  payinGroup: FormGroup = new FormGroup({});
  actualPayinGroup: FormGroup = new FormGroup({});

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.payoutBase = getPayoutBase(this.lob);
    this.createFormControls();
    this.setAlternatingControls();
  }

  createFormControls() {
    for (const payoutBase of this.payoutBase) {
      const payoutPercControl = new FormControl('', Validators.required);
      const payinPercCtrl = new FormControl('', Validators.required);
      const acPayinPercCtrl = new FormControl('', Validators.required);
      const payoutAmtCtrl = new FormControl('', Validators.required);
      const payinAmtCtrl = new FormControl('', Validators.required);
      const acPayinAmtCtrl = new FormControl('', Validators.required);

      payinPercCtrl.disable();
      acPayinPercCtrl.disable();
      payinAmtCtrl.disable();
      acPayinAmtCtrl.disable();

      this.payoutControls.percent.push(payoutPercControl);
      this.payinControls.percent.push(payinPercCtrl);
      this.acPayinControls.percent.push(acPayinPercCtrl);
      
      this.payoutControls.amount.push(payoutAmtCtrl);
      this.payinControls.amount.push(payinAmtCtrl);
      this.acPayinControls.amount.push(acPayinAmtCtrl);

      this.payoutGroup.addControl(`${payoutBase.value}`, new FormGroup({ 'percent': payoutPercControl, 'amount': payoutAmtCtrl }));
      this.payinGroup.addControl(`${payoutBase.value}`, new FormGroup({ 'percent': payinPercCtrl, 'amount': payinAmtCtrl }));
      this.actualPayinGroup.addControl(`${payoutBase.value}`, new FormGroup({ 'percent': acPayinPercCtrl, 'amount': acPayinAmtCtrl }));

      this.valueRangeCheck(payoutPercControl, payinPercCtrl, acPayinPercCtrl);
      this.valueRangeCheck(payoutAmtCtrl, payinAmtCtrl, acPayinAmtCtrl);

      this.test(payoutPercControl, payinPercCtrl, acPayinPercCtrl, payoutBase.value);
      this.test(payoutAmtCtrl, payinAmtCtrl, acPayinAmtCtrl, payoutBase.value);

    };
    
    this.form.addControl('payout', this.payoutGroup);
    this.form.addControl('payin', this.payinGroup);
    this.form.addControl('actualPayin', this.actualPayinGroup);
  }

  setAlternatingControls() {
    const percentControls = [
      ...this.payoutControls.percent,
      // ...this.payinControls.percent,
      // ...this.acPayinControls.percent,
    ];
    const amountControls = [
      ...this.payoutControls.amount,
      // ...this.payinControls.amount,
      // ...this.acPayinControls.amount,
    ];

    this.enableDisable(percentControls, amountControls);
    this.enableDisable(amountControls, percentControls);

  }

  enableDisable(arr1: any, arr2: any) {
    for (const ctrl of arr1) {
      ctrl.valueChanges.subscribe((val: any) => {
        if (val) {
          arr2.forEach((ctrl2: any) => {
            ctrl2.disable({ onlySelf: true, emitEvent: false });
          });
        } else if (!this.valueExists(arr1)) {
          arr2.forEach((ctrl2: any) => {
            ctrl2.enable({ onlySelf: true, emitEvent: false });
          });
        }
      });
    }
  }

  valueRangeCheck(ctrl1: any, ctrl2: any, ctrl3: any) {
    ctrl1.valueChanges.subscribe((val: any) => {
      const val1 = this.numberOrZero(val);
      const val2 = this.numberOrZero(ctrl2.value);
      const val3 = this.numberOrZero(ctrl3.value);

      if ((val1 > val2 || val1 > val3)) {
        ctrl1.setErrors({ 'invalid': true });
      } else if (val1 < val2 && val1 < val3) {
        ctrl1.setErrors(null);
        ctrl2.setErrors(null);
        ctrl3.setErrors(null);
      }
    });

    ctrl2.valueChanges.subscribe((val: any) => {
      const val1 = this.numberOrZero(ctrl1.value);
      const val2 = this.numberOrZero(val);
      const val3 = this.numberOrZero(ctrl3.value);

      if ((val2 < val1 || val2 > val3)) {
        ctrl2.setErrors({ 'invalid': true });
      } else if (val2 > val1 && val2 < val3) {
        ctrl1.setErrors(null);
        ctrl2.setErrors(null);
        ctrl3.setErrors(null);
      }
    });

    ctrl3.valueChanges.subscribe((val: any) => {
      const val1 = this.numberOrZero(ctrl1.value);
      const val2 = this.numberOrZero(ctrl2.value);
      const val3 = this.numberOrZero(val);

      if ((val3 < val1 || val3 < val2)) {
        ctrl3.setErrors({ 'invalid': true });
      } else if (val3 > val1 && val3 > val2) {
        ctrl1.setErrors(null);
        ctrl2.setErrors(null);
        ctrl3.setErrors(null);
      }
    });
  }


  test(payoutCtrl: any, payinCtrl: any, acPayinCtrl: any, payoutBase:any) {
    payoutCtrl.valueChanges.subscribe((val: any) => {
      if (this.isBadNet || this.isBadOD || this.isBadTp || (!val && !this.form.touched)) { return };
      console.log('value', val);
      console.log('payout base V', payoutBase);

      if (this.numberOrZero(val) > 0) {
        this.hasValue[payoutBase] = true;
        this.removeRequired(payoutBase);
        this.disableGroup(payoutBase);
        payinCtrl.enable();
        acPayinCtrl.enable();
      } else {
        this.hasValue[payoutBase] = false;
        this.setRequired(payoutBase);
        this.enableGroup(payoutBase);
        payinCtrl.disable();
        acPayinCtrl.disable();
      }
    })
  }

  isBadOD = false;
  isBadTp = false;
  isBadNet = false;

  removeRequired(payoutBase: any) {
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value) {
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(tpGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadTp = true;
          ctrl.updateValueAndValidity();
          this.isBadTp = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(odGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadOD = true;
          ctrl.updateValueAndValidity();
          this.isBadOD = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (ctrl.hasValidator(Validators.required)) {
          ctrl.removeValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      if (odGroup) {
        Object.values(odGroup.controls).forEach((ctrl: any) => {
          if (ctrl.hasValidator(Validators.required)) {
            ctrl.removeValidators(Validators.required);
            this.isBadOD = true;
            ctrl.updateValueAndValidity();
            this.isBadOD = false;
          }
        });
      }
      if (tpGroup) {
        Object.values(tpGroup.controls).forEach((ctrl: any) => {
          if (ctrl.hasValidator(Validators.required)) {
            ctrl.removeValidators(Validators.required);
            this.isBadTp = true;
            ctrl.updateValueAndValidity();
            this.isBadTp = false;
          }
        });
      }
    }
  }

  setRequired(payoutBase: any) {
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value) {
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(tpGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadTp = true;
          ctrl.updateValueAndValidity();
          this.isBadTp = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const netGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      Object.values(odGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadOD = true;
          ctrl.updateValueAndValidity();
          this.isBadOD = false;
        }
      });
      Object.values(netGroup.controls).forEach((ctrl: any) => {
        if (!ctrl.hasValidator(Validators.required)) {
          ctrl.addValidators(Validators.required);
          this.isBadNet = true;
          ctrl.updateValueAndValidity();
          this.isBadNet = false;
        }
      });
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      const odGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      const tpGroup = this.getControlAsFormGroup(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      if (odGroup) {
        Object.values(odGroup.controls).forEach((ctrl: any) => {
          if (!ctrl.hasValidator(Validators.required)) {
            ctrl.addValidators(Validators.required);
            this.isBadOD = true;
            ctrl.updateValueAndValidity();
            this.isBadOD = false;
          }
        });
      }
      if (tpGroup) {
        Object.values(tpGroup.controls).forEach((ctrl: any) => {
          if (!ctrl.hasValidator(Validators.required)) {
            ctrl.addValidators(Validators.required);
            this.isBadTp = true;
            ctrl.updateValueAndValidity();
            this.isBadTp = false;
          }
        });
      }
    }
  }

  disableGroup(payoutBase: any) {
    console.log("payout base D", payoutBase)
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value || payoutBase == PAYOUT_BASE.TP_PREMIUM.value) {
      this.ddd(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      this.ddd(this.payinGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      this.ddd(this.actualPayinGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      this.ddd(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.ddd(this.payinGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.ddd(this.actualPayinGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.ddd(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      this.ddd(this.payinGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      this.ddd(this.actualPayinGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
    }
  }

  enableGroup(payoutBase: any) {
    console.log("payout base E", payoutBase)
    if (payoutBase == PAYOUT_BASE.OD_PREMIUM.value || payoutBase == PAYOUT_BASE.TP_PREMIUM.value && (!this.hasValue[PAYOUT_BASE.OD_PREMIUM.value] && !this.hasValue[PAYOUT_BASE.TP_PREMIUM.value])) {
      this.eee(this.payoutGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      this.ddd(this.payinGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
      this.ddd(this.actualPayinGroup.controls[`${PAYOUT_BASE.NET_PREMIUM.value}`]);
    } else if (payoutBase == PAYOUT_BASE.NET_PREMIUM.value) {
      this.eee(this.payoutGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.ddd(this.payinGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.ddd(this.actualPayinGroup.controls[`${PAYOUT_BASE.OD_PREMIUM.value}`]);
      this.eee(this.payoutGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      this.ddd(this.payinGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
      this.ddd(this.actualPayinGroup.controls[`${PAYOUT_BASE.TP_PREMIUM.value}`]);
    }
  }

  valueExists(arr: any[]) {
    let flag = false;
    arr.forEach((ctrl: any) => {
      if (ctrl.value) { flag = true; return; };
    });
    return flag;
  }

  numberOrZero(num: any) {
    return parseFloat(num) || 0;
  }


  ddd(group: any) {
    if (!this.getControlAsFormGroup(group)) { return };
    Object.values(this.getControlAsFormGroup(group).controls).forEach((ctrl: any) => {
      if (ctrl.enabled) {
        ctrl.disable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  eee(group: any) {
    if (!this.getControlAsFormGroup(group)) { return };
    Object.values(this.getControlAsFormGroup(group).controls).forEach((ctrl: any) => {
      if (ctrl.disabled) {
        ctrl.enable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  getControlAsFormGroup(ctrl:any) {
    return ctrl as FormGroup;
  }
}
