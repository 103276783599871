<mat-label>Evidence Capture<span class="star">*</span></mat-label>
<mat-select #evidenceTypeSelect (selectionChange)="onEvidenceTypeChange($event.value)" placeholder="Select">
    <mat-option [value]="'new'">New Evidence</mat-option>
    <mat-option [value]="'previous'">Use Previous</mat-option>
</mat-select>

<app-file-field
    *ngIf="evidenceTypeSelect.value == 'new'"
    (fileUploaded)="onFileChange($event)"
>
</app-file-field>

<a *ngIf="fileCtrl.value" [href]="fileCtrl.value" target="_blank"><span class="text-success">View Evidence</span></a>

<mat-error *ngIf="showError">Please select insurer</mat-error>