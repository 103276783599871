import { Component, Input } from '@angular/core';
import { AutoCompleteSearchDropdownOptionsI } from '../autocomplete-field/autocomplete-field.interface';

@Component({
  selector: 'app-not-in-make-field',
  templateUrl: './not-in-make-field.component.html',
  styleUrls: ['./not-in-make-field.component.scss']
})
export class NotInMakeFieldComponent {
  @Input('lob') lob: string;

  controlName = 'not_in_make';

  autoCompleteFieldOptions: AutoCompleteSearchDropdownOptionsI;

  cvAutoCompleteFieldOptions: AutoCompleteSearchDropdownOptionsI = {
    searchApiUrl: '/services/api/v1/rb_masters/get-make-by-name?lob=cv',
    dataApiUrl: '/services/api/v1/rb_masters/get-all-manufacturers?lob=cv',
    queryParamKey: 'manufacturer_name',
    dataIdKey: 'rb_id',
    dataNameKey: 'manufacturer_name',
  }

  motorAutoCompleteOptions: AutoCompleteSearchDropdownOptionsI = {
    searchApiUrl: `/services/api/v1/rb_masters/get-make-by-name?lob=motor`,
    dataApiUrl: '/services/api/v1/rb_masters/get-all-manufacturers?lob=motor',
    queryParamKey: 'manufacturer_name',
    dataIdKey: 'rb_id',
    dataNameKey: 'manufacturer_name',
  }

  ngOnInit() {
    if (this.lob == 'cv') {
      this.autoCompleteFieldOptions = this.cvAutoCompleteFieldOptions;
    } else {
      this.autoCompleteFieldOptions = this.motorAutoCompleteOptions;
    }
  }
}
