<mat-label class="subHeading"
  >Company<span *ngIf="isRequired" class="star">*</span></mat-label
>
<button class="side" (click)="form.controls['company'].setValue(null)">Remove</button>
<mat-select formControlName="company" placeholder="All">
  <mat-option
    *ngFor="let company of companyList"
    [value]="company.company_name"
  >
    {{ company.company_name }}
  </mat-option>
</mat-select>
