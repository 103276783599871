import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-age-field',
  templateUrl: './age-field.component.html',
  styleUrls: ['./age-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AgeFieldComponent implements OnInit, OnDestroy {
  form: FormGroup;
  ageForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.ageForm.controls['to'].addValidators(rangeValidator.bind(this, this.ageForm));
    this.ageForm.controls['from'].addValidators(rangeValidator.bind(this, this.ageForm));

    this.form = this.ctrlContainer.form;

    if (this.form.controls['age']) {
      this.ageForm.patchValue(this.form.controls['age'].value);
      this.form.removeControl('age');
    }

    this.form.addControl('age', this.ageForm);
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.ageForm.controls['to'].value) < Number(this.ageForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.ageForm.controls['to'].invalid) {
  //     this.ageForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.ageForm.controls['from'].invalid) {
  //     this.ageForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('age');
  }
}
