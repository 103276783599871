import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-sum-insured-field',
  templateUrl: './sum-insured-field.component.html',
  styleUrls: ['./sum-insured-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SumInsuredFieldComponent implements OnInit {
  @Input('required') isRequired = false;
  @Input('allRuleIdData') allRuleIdData: any;

  form: FormGroup;
  sumInsuredForm: FormGroup = new FormGroup({
    from: new FormControl(),
    to: new FormControl(),
  });

  constructor(private ctrlContainer: FormGroupDirective) { }

  ngOnInit(): void {
    if (this.isRequired) {
      this.sumInsuredForm.controls['to'].addValidators([Validators.required,rangeValidator.bind(this, this.sumInsuredForm),alternateValidator.bind(this, this.sumInsuredForm)]);
      this.sumInsuredForm.controls['from'].addValidators([Validators.required,rangeValidator.bind(this, this.sumInsuredForm),alternateValidator.bind(this, this.sumInsuredForm)]);
    } else {
      this.sumInsuredForm.controls['to'].addValidators(rangeValidator.bind(this, this.sumInsuredForm));
      this.sumInsuredForm.controls['from'].addValidators(rangeValidator.bind(this, this.sumInsuredForm));
    }

    this.form = this.ctrlContainer.form;

    if (this.form.controls['sum_insured']) {
      this.sumInsuredForm.patchValue(this.form.controls['sum_insured'].value);
      this.form.removeControl('sum_insured');
    }

    this.form.addControl('sum_insured', this.sumInsuredForm);
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.sumInsuredForm.controls['to'].value) <
  //     Number(this.sumInsuredForm.controls['from'].value)
  //   ) {
  //     return { small: true };
  //   }
  //   if (this.sumInsuredForm.controls['to'].invalid) {
  //     this.sumInsuredForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.sumInsuredForm.controls['from'].invalid) {
  //     this.sumInsuredForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        if (
          lobSelected === 'health' ||
          lobSelected === 'life' ||
          lobSelected === 'finsure' ||
          lobSelected === 'travel' ||
          lobSelected === 'sme'
        ) {
          this.sumInsuredForm.get('to')?.setValidators([Validators.required]);
          this.sumInsuredForm.get('to')?.updateValueAndValidity();
          this.sumInsuredForm.get('from')?.setValidators([Validators.required]);
          this.sumInsuredForm.get('from')?.updateValueAndValidity();
        } else {
          this.sumInsuredForm.get('to')?.setValidators([]);
          this.sumInsuredForm.get('to')?.updateValueAndValidity();
          this.sumInsuredForm.get('from')?.setValidators([]);
          this.sumInsuredForm.get('from')?.updateValueAndValidity();
        }
      }
    }
    if (changes['allRuleIdData']) {
      if (changes['allRuleIdData'].currentValue.sum_insured !== undefined) {
        let getUrl = window.location.href;
        // if (getUrl.includes('payout-processing/main/edit-payout-rule')) {
        let sumInsuredDataFrom =
          changes['allRuleIdData'].currentValue.sum_insured.from;
        let sumInsuredDataTo =
          changes['allRuleIdData'].currentValue.sum_insured.to;
        this.sumInsuredForm.patchValue({
          from: sumInsuredDataFrom,
          to: sumInsuredDataTo,
        });
        // }
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('sum_insured');
  }
}
