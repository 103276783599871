<mat-select multiple [formControl]="selectFormControl" (openedChange)="openedChange($event)">
    <div class="p-3">
        <input
            #inputField
            matInput
            [formControl]="inputControl"
            placeholder="{{'Enter more than '+minCharacters+' letters to start searching'}}"
            tabindex="-1"
            (keydown)="$event.stopPropagation()"
        />
    </div>
    <mat-option disabled *ngIf="(filteredOptions | async)?.length == 0">-</mat-option>
    <mat-option *ngFor="let option of filteredOptions | async"
        [value]="option[dataIdKey]"
        (onSelectionChange)="selectionChange($event, option)"
    >
        {{ option[dataValueKey] }}
    </mat-option>
</mat-select>
<app-tooltip [data]="toolTipData"></app-tooltip>