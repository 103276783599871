import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-plan-name-life-finsure-field',
  templateUrl: './plan-name-life-finsure-field.component.html',
  styleUrls: ['./plan-name-life-finsure-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PlanNameLifeFinsureFieldComponent {
  @Input('lob') lob: any;
  @Input('multiple') isMultiSelect = false;
  plan_name: any[] = [];
  @Input('allRuleIdData') allRuleIdData: any;
  form: FormGroup;
  @Input('required') isRequired = false;
  plannameControl = new FormControl();
  @ViewChild('planNameSelect', { static: true })
  planNameSelect: MatSelect;
  lobSelected: any = '';

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('plan_name', this.plannameControl);
    if (this.form.value['plan_name']) {
      this.plannameControl.setValue(this.form.value['plan_name']);
    }

    this.getPlanName();
  }
  
  getPlanName() {
    const url = '/services/api/v1/rb_masters/finsure_life_plan_name';
    this.http.get(url).subscribe((res: any) => {
      this.plan_name = res['data'];
      if (this.form.value['plan_name_edit']) {
        this.plannameControl.setValue(this.form.value['plan_name_edit']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('plan_name');
  }
}
