<app-header></app-header>
<div class="row content main-content">
  <ng-container *ngIf="(stateService.loaded$|async) && stateService.getState().permission.length">
    <div class="col-2 sidebar d-md-block d-none">
      <app-nav-bar style="position: fixed; width: 18%; left: 0"></app-nav-bar>
    </div>
  </ng-container>
  <div class="col-12 col-md-10 sidebar-left" [ngClass]="{'col-md-12': (stateService.loaded$|async) && stateService.getState().permission.length === 0}">
    <router-outlet></router-outlet>
  </div>
</div>

<app-base-loader></app-base-loader>
