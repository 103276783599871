import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { Apiurls } from '../insurer-field/Api_urls';

@Component({
  selector: 'app-insurer-business-type',
  templateUrl: './insurer-business-type.component.html',
  styleUrls: ['./insurer-business-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class InsurerBusinessTypeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('policyTypeSelect', { static: true }) policyTypeSelect: MatSelect;
  @Input('required') isRequired = false;
  form: FormGroup;
  insurers: any;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('lob') lob: any;
  insurerBusinessTypeDataList: any = [];
  insurerbusinessTypeList: any = [];

  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'insurerBusinessType',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('insurerBusinessType', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.insurerBusinessTypeDataList =
          changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        this.insurerbusinessTypeList = [];
        this.insurerbusinessTypeList =
          this.insurerBusinessTypeDataList[lobSelected]['RB_Insurer_Type'];
      }
    }
    if (changes['allRuleIdData']) {
      if (
        changes['allRuleIdData'].currentValue.insurer_business_type !==
        undefined
      ) {
        let getUrl = window.location.href;
        if (getUrl.includes('payout-processing/main/edit-payout-rule')) {
          let insurer_business_type =
            changes['allRuleIdData'].currentValue.insurer_business_type;
          this.form.patchValue({
            insurerBusinessType: insurer_business_type,
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('insurerBusinessType');
  }
}
