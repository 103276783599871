import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { WindowRef } from '../../../core/services/window.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { StateService } from 'src/app/shared/services/global.service';
import { UserPermissions } from 'src/app/shared/models/user.permissions';
import { GlobalService } from 'src/app/shared/services/auth.service/auth.service';

@Component({
    selector: 'app-profile-avatar',
    templateUrl: './profile-avatar.component.html',
    styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent {
    elem: any;
    isLoggedInVal: Observable<boolean> | any;
    source: Boolean = false;
    @ViewChild('widgetId') widgetId!: ElementRef;
    private self:any={}
    constructor(
        private authService: AuthService,
        private win: WindowRef,
        private activatedRoute: ActivatedRoute,
        private globalService: GlobalService,
        private state: StateService,
        
            
    
    ) {
       // this.self=this;
        this.logonOut = this.logonOut.bind(this);
        const getQueryParams = this.activatedRoute.snapshot.queryParams;
        const isSourceApp = getQueryParams['source'];
        const fromLocalStorage = localStorage.getItem('isSource');
        if (isSourceApp && isSourceApp.length > 1) {
            this.source = true;
            localStorage.setItem('isSource', 'app');
        }

        if (fromLocalStorage && fromLocalStorage.length > 1) {
            this.source = true;
        }
    }
    getToken() {
        return localStorage.getItem('token')
    }


    logonOut() {
        this.authService.logOut();
        
        //this.win.nativeWindow.UserlogOut()
        

        
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.elem = this.widgetId.nativeElement;

        this.self= new this.win.nativeWindow.RB_AMS_SDK({
            userInfo: this.elem,
            islogIn: this.authService.setUser,
            UserlogOut: this.logonOut,
            amsurl: environment.amsurl
        });
        
    }
}
