import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-insurer-email',
  templateUrl: './insurer-email.component.html',
  styleUrls: ['./insurer-email.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InsurerEmailComponent implements OnInit {
  form: FormGroup;

  emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('insurer_email', new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]));
    this.form.controls['insurer_email'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['insurer_email'].setValue('');
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('insurer_email');
  }
}
