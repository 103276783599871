<mat-label class="subHeading">Contest Period</mat-label>

<!-- <div class="d-flex align-items-center mt-2" style="gap: 10px;" formGroupName="contest_period">
    <mat-form-field appearance="fill" class="custom-mat-form-field custom-datpicker">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="start_date">
        <mat-datepicker-toggle matIconSuffix [for]="picker1">
            <mat-icon matDatepickerToggleIcon>
                <img src="../../../../../assets/icons/calendar.svg">
            </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    -
    <mat-form-field appearance="fill">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="end_date" [min]="start_date.value">
        <mat-datepicker-toggle matIconSuffix [for]="picker2">
            <mat-icon matDatepickerToggleIcon>
                <img src="../../../../../assets/icons/calendar.svg">
            </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
</div> -->


<div class="d-flex align-items-center mt-2" style="gap: 10px;" formGroupName="contest_period">
    <mat-form-field appearance="fill" class="custom-mat-form-field custom-datpicker">
        <mat-label>From Date</mat-label>
        <input
            matInput
            [ngxMatDatetimePicker]="picker1"
            placeholder="dd/mm/yyyy"
            [formControl]="start_dateControl"
        >
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
            #picker1
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [enableMeridian]="enableMeridian"
        >
        </ngx-mat-datetime-picker>
    </mat-form-field>
    -
    <mat-form-field appearance="fill">
        <mat-label>To Date</mat-label>
        <input
            matInput
            [min]="start_dateControl.value"
            [ngxMatDatetimePicker]="picker2"
            placeholder="dd/mm/yyyy"
            [formControl]="end_dateControl"
        >
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
            #picker2
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [enableMeridian]="enableMeridian">
        </ngx-mat-datetime-picker>
    </mat-form-field>
</div>
