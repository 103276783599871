<mat-label class="subHeading ">Trailer IDV</mat-label>

<div formGroupName="vehicle_trailer_idv" class="d-flex align-items-center mt-2" style="gap: 10px;">
    <input formControlName="from" [maxlength]="6" type="text" class="w-50 m-0" placeholder="From" appNumberOnly>
    -
    <input formControlName="to" [maxlength]="6" type="text" class="w-50 m-0" placeholder="To" appNumberOnly>
</div>
<p
    class="text-danger"
    *ngIf="
        trailerIdvForm.controls['from'].hasError('small')
        ||
        trailerIdvForm.controls['to'].hasError('small')
    "
>
Invalid range for values
</p>
