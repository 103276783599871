import {Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent {
 modalData:any
  consoleReason:FormGroup = new FormGroup({
    reason: new FormControl('',[Validators.required])
  });
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ReasonDialogComponent>,) {}
  
  ngOnInit() {
  
  }

  clickOk() {
    this.modalData = this.consoleReason.get('reason').value;
    this.dialogRef.close(this.modalData);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
