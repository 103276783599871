<mat-label class="subHeading">Policy Tenure<span *ngIf="isRequired" class="star">*</span></mat-label>
<div formGroupName="policy_tenure" class="d-flex align-items-center mt-2" style="gap: 10px;">
    <input formControlName="from" [maxlength]="1" type="text" class="w-50 m-0" placeholder="From" appNumberOnly>
    -
    <input formControlName="to" [maxlength]="2" (keyup)="change()" type="text" class="w-50 m-0" placeholder="To" appNumberOnly>
</div>
<p
    class="text-danger"
    *ngIf="
        policy_tenure.controls['from'].hasError('small')
        ||
        policy_tenure.controls['to'].hasError('small')
    "
>
Invalid range for values
</p>
