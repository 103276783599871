<div class="row">
    <div class="col-4">
        <mat-label class="subHeading">RB Nomenclature<span *ngIf="isRequired" class="star">*</span></mat-label>
        <mat-select formControlName="rb_nomenclature">
          <mat-option  *ngFor="let item of nomenclatureRb" [value]="item">{{item | titlecase}}</mat-option>
      </mat-select>
    </div>
    <div class="col-4">
        <mat-label class="subHeading">Insurer Nomenclature<span *ngIf="isRequired" class="star">*</span></mat-label>
        <mat-select multiple formControlName="insurer_nomenclature">
          <mat-option  *ngFor="let item of nomenclatureInsurer" [value]="item">{{item | titlecase}}</mat-option>
      </mat-select>
    </div>
    <div class="col-2">
        <mat-label class="subHeading">Status<span *ngIf="isRequired" class="star">*</span></mat-label>
        <div class="form-switch">
          <input
          formControlName="status"
          (click)="active_status()"
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          checked
        />
        </div>
    </div>
    <div class="col-2">
      <mat-label class="subHeading">Recon<span *ngIf="isRequired" class="star">*</span></mat-label>
      <div class="form-switch">
        <input
        formControlName="recon"
        (click)="active_recon()"
        class="form-check-input"
        type="checkbox"
        id="flexSwitchCheckChecked"
        checked
      />
      </div>
  </div>
</div>

