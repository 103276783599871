export const PAYOUT_BASE = {
    OD_PREMIUM: {'title': 'OD Premium', 'value': 1},
    TP_PREMIUM: {'title': 'Third Party', 'value': 2},
    NET_PREMIUM: {'title': 'Net Premium/ Per Policy', 'value': 3},
};

const PAYOUT_BASE_MAPPING:any = {
    'motor4W': [
        PAYOUT_BASE.OD_PREMIUM, PAYOUT_BASE.TP_PREMIUM, PAYOUT_BASE.NET_PREMIUM
    ],   
    'motor': [
        PAYOUT_BASE.OD_PREMIUM, PAYOUT_BASE.TP_PREMIUM, PAYOUT_BASE.NET_PREMIUM
    ],
    'cv': [
        PAYOUT_BASE.OD_PREMIUM, PAYOUT_BASE.TP_PREMIUM, PAYOUT_BASE.NET_PREMIUM
    ],
    'life': [
        PAYOUT_BASE.NET_PREMIUM
    ],
    'health': [
        PAYOUT_BASE.NET_PREMIUM
    ]
};


export function getPayoutBase(lob: string) {
    return PAYOUT_BASE_MAPPING[lob];
}
