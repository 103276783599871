import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { Apiurls } from '../insurer-field/Api_urls';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PaymentTypeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('paymentTypeSelect', { static: true })
  paymentTypeSelect: MatSelect;
  @Input('required') isRequired = false;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('lob') lob: any;
  form: FormGroup;
  insurers: any;
  paymentTypeDataList: any = [];
  paymentTypeList: any = [];
  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'paymentType',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('paymentType', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.paymentTypeDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        this.paymentTypeList = [];
        this.paymentTypeList =
          this.paymentTypeDataList[lobSelected]['Payment_Type'];
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('paymentType');
  }
}
