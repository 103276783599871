import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayinOutService {
  enableDisableAmount = new BehaviorSubject(null);
  enableDisablePercent = new BehaviorSubject(null);
  enableDisablePayoutBaseGroup = new BehaviorSubject<any>(null);
  enableDisableValidatorSub = new BehaviorSubject<any>(null);

  form: FormGroup;

  constructor() { };

  valueExists(type: string, payoutBase?: any) {
    if (payoutBase) {
      return this.valueExistsByPayoutBase(payoutBase);
    }
    const group1 = this.getControlAsFormGroup(this.form.controls['payout']);
    const group2 = this.getControlAsFormGroup(this.form.controls['payin']);
    const group3 = this.getControlAsFormGroup(this.form.controls['actual_payin']);

    for (const group of [group1, group2, group3]) {
      if(!group) continue;
      for (const ctrlP of Object.values(group.controls)) {
          if ((ctrlP as FormGroup).controls[type].value) {
            return true;
          }
        
      }
    }
    return false;
  }

  valueExistsByPayoutBase(payoutBase: any) {
    const group1:any = this.getControlAsFormGroup(this.form.controls['payout']).controls[payoutBase] as FormGroup;

    let group2:any = this.getControlAsFormGroup(this.form.controls['payin']);
    if(group2){
      group2 = group2.controls[payoutBase] as FormGroup;
    }else{
      group2 = null;
    }

    let group3:any = this.getControlAsFormGroup(this.form.controls['actual_payin']);
    if(group3){
      group3 = group3.controls[payoutBase] as FormGroup;
    }else{
      group3 = null;
    }

    for (const group of [group1, group2, group3]) {
      if(!group) continue;
      for (const ctrl of Object.values(group.controls) as Array<any>) {
        if (ctrl.value) {
          return true;
        }
      }
    }
    return false;
  }

  isValidForm() {
    return !this.checkAndUpdateValidity(1) && !this.checkAndUpdateValidity(2) && !this.checkAndUpdateValidity(3);
  }

  checkAndUpdateValidity(payoutBase: any) {
    const group1 = this.getControlAsFormGroup(this.form.controls['payout']).controls[payoutBase] as FormGroup;
    let group2:any = this.getControlAsFormGroup(this.form.controls['payin']);
    if(group2){
      group2 = group2.controls[payoutBase] as FormGroup;
    }else{
      group2 = null;
    }

    let group3:any = this.getControlAsFormGroup(this.form.controls['actual_payin']);
    if(group3){
      group3 = group3.controls[payoutBase] as FormGroup;
    }else{
      group3 = null;
    }

    if (!group1 && !group2 && !group3) {
      return false;
    }

    let payoutValue = 0;
    let payinValue = 0;
    let act_value = 0;

    for (const ctrl of Object.values(group1.controls)) {
      if (ctrl.value) {
        payoutValue = Number(ctrl.value);
      }
    }
    if(group2){
      for (const ctrl of Object.values(group2.controls) as Array<any>) {
        if (ctrl.value) {
          payinValue = Number(ctrl.value);
        }
      }
    }else{
      payinValue = payoutValue;
    }

    if(group3){
      for (const ctrl of Object.values(group3.controls) as Array<any>) {
        if (ctrl.value) {
          act_value = Number(ctrl.value);
        }
      }
    }

    if (!act_value) {
      act_value = payinValue;
    }

    return payoutValue > payinValue || payinValue > act_value || payoutValue > act_value;
    if (payoutValue > payinValue || payinValue > act_value || payoutValue > act_value) {
      this.form.setErrors({ 'aaaaaaaaaa': true });
    } else {
      this.form.setErrors({ 'aaaaaaaaaa': null });
    }
  }


  resetForm(){
    for(const payoutBase of [1,2,3]){
      this.enableDisableValidatorSub.next([payoutBase, 'enable']);
      this.enableDisablePayoutBaseGroup.next([payoutBase, 'enable']);
      this.enableDisableAmount.next('enable');
      this.enableDisablePercent.next('enable');
    }
  }
  getControlAsFormGroup(ctrl: any) {
    return ctrl as FormGroup;
  }
}
