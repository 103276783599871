import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';
import { Apiurls } from '../insurer-field/Api_urls';

@Component({
  selector: 'app-policy-type',
  templateUrl: './policy-type.component.html',
  styleUrls: ['./policy-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PolicyTypeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('policyTypeSelect', { static: true }) policyTypeSelect: MatSelect;
  form: FormGroup;
  @Input('required') isRequired = false;
  insurers: any;
  @Input('lob') lob: any;
  @Input('product') product: any;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  policyTypeDataList: any = '';
  lobSelected: any = '';
  policyTypeList: any = [];
  ngOnInit() {
    this.getinsurer();
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'policyType',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('policyType', new FormControl());
    }
  }
  getinsurer() {
    const product = 'motor';
    const url = Apiurls[product as keyof typeof Apiurls];
    this.http.get(url).subscribe((res: any) => {
      this.insurers = sort(res['data'], 'insurer_name');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.policyTypeDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
        this.policyTypeList = [];
        if (
          this.lobSelected === 'motor' ||
          this.lobSelected === 'motor4W' ||
          this.lobSelected === 'cv'
        ) {
          let productSelected = 'Commercial_Vehicle';
          this.policyTypeList =
            this.policyTypeDataList[this.lobSelected]['policy_type'][
              productSelected
            ];
        }
      }
    }
    if (changes['product']) {
      if (changes['product'].currentValue !== '') {
        let productSelected = changes['product'].currentValue;
        this.policyTypeList = [];
        if (this.lobSelected === 'finsure') {
          this.policyTypeList =
            this.policyTypeDataList[this.lobSelected]['policy_type'];
        } else {
          this.policyTypeList =
            this.policyTypeDataList[this.lobSelected]['policy_type'][
              productSelected
            ];
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('policyType');
  }
}
