import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { RESOURCES } from 'src/app/core/utils/constants';
import { StateService } from 'src/app/shared/services/global.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
    selector: 'app-approve-button',
    templateUrl: './approve-button.component.html',
    styleUrls: ['./approve-button.component.scss']
})
export class ApproveButtonComponent {
    @Input('lob') lob: string;
    ruleId: string;
    submitting: boolean = false;

    remark: string;

    constructor(private http: HttpService, private activatedRoute: ActivatedRoute, private snackbarService: SnackbarService) { };

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: any) => {
            this.ruleId = params['id'];
        });
    }

    approve() {
        this.submitting = true;
        const url = `/services/api/v1/rules/approved_rules`;
        const data = {
            'lob': this.lob,
            'rule_id': this.ruleId,
        };

        this.http.post(url, data).subscribe((res: any) => {
            this.snackbarService.openSnackbar('Approved');
            window.location.href = "/rules/overview/" + this.lob;
            this.submitting = false;
        },
            err => this.submitting = false
        );
    }

    reject() {
        this.submitting = true;
        const url = `/services/api/v1/rules/reject_rules`;
        const data = {
            'lob': this.lob,
            'rule_id': this.ruleId,
            'remark': this.remark,
        };

        this.http.post(url, data).subscribe((res: any) => {
            this.snackbarService.openSnackbar('Rejected');
            window.location.href = "/rules/overview/" + this.lob;
            this.submitting = false;
        },
            err => this.submitting = false
        );
    }
}

