import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, finalize, Observable } from "rxjs";
import { TableDataService } from "./table-data.service";

export class TableDataSource implements DataSource<any>{
    lessonsSubject = new BehaviorSubject<any>([]);
    private tableDataSubject = new BehaviorSubject<any>([]);
    private loadingSubject = new BehaviorSubject<boolean>(true);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private tableService: TableDataService) { }

    connect(collectionViewer: CollectionViewer): Observable<any> {
        return this.tableDataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.lessonsSubject.complete();
        this.loadingSubject.complete();
    }

    loadData(params={}, filter = '', data:any=null,
        sortDirection = 'asc', pageIndex = 0, pageSize = 3) {
        if (!params && !data) { return };
        this.loadingSubject.next(true);
        this.tableDataSubject.next([]);
        this.lessonsSubject.next({ 'total_records': 0, 'data': [] });
        this.tableService.getData(params, filter, data, sortDirection,
            pageIndex, pageSize).pipe(
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => {
                this.tableDataSubject.next(data['data']);
                this.lessonsSubject.next(data);
            });
    }

}