<div class="col-4">
    <label class="subHeading">Product<span *ngIf="isRequired" class="star">*</span></label>
    <mat-select formControlName="product_name">
        <mat-option *ngFor="let obj of product" [value]="obj.rb_id">
            {{obj.name}}
        </mat-option>
    </mat-select>
</div>

<div class="row">
    <ng-container #fieldsContainer></ng-container>
</div>