import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-lcc-field',
  templateUrl: './lcc-field.component.html',
  styleUrls: ['./lcc-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class LCCFieldComponent implements OnInit {
  form: FormGroup;
  lccForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.lccForm.controls['to'].addValidators(rangeValidator.bind(this, this.lccForm));
    this.lccForm.controls['from'].addValidators(rangeValidator.bind(this, this.lccForm));
    this.form = this.ctrlContainer.form;

    if (this.form.controls['lcc']) {
      this.lccForm.patchValue(this.form.controls['lcc'].value);
      this.form.removeControl('lcc');
    }

    this.form.addControl('lcc', this.lccForm);

    (this.form.controls['lcc'] as FormGroup).controls['from'].valueChanges.pipe(
      debounceTime(-200)
    ).subscribe((val: any) => {
      if (val && Number(val[0]) == 0) {
        (this.form.controls['lcc'] as FormGroup).controls['from'].patchValue(val.slice(1));
      }
    });

    (this.form.controls['lcc'] as FormGroup).controls['to'].valueChanges.pipe(
      debounceTime(-200)
    ).subscribe((val: any) => {
      if (val && Number(val[0]) == 0) {
        (this.form.controls['lcc'] as FormGroup).controls['to'].patchValue(val.slice(1));
      }
    });
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.lccForm.controls['to'].value) < Number(this.lccForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.lccForm.controls['to'].invalid) {
  //     this.lccForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.lccForm.controls['from'].invalid) {
  //     this.lccForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('lcc');
  }
}
