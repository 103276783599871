import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-premium-payment-term',
  templateUrl: './premium-payment-term.component.html',
  styleUrls: ['./premium-payment-term.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]

})
export class PremiumPaymentTermComponent implements OnInit{
  @Input('required') isRequired = false;

  form: FormGroup;
  premiumPaymentTermControl:FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.premiumPaymentTermControl.controls['from'].addValidators([Validators.required,rangeValidator.bind(this, this.premiumPaymentTermControl),alternateValidator.bind(this,this.premiumPaymentTermControl)]);
      this.premiumPaymentTermControl.controls['to'].addValidators([Validators.required,rangeValidator.bind(this, this.premiumPaymentTermControl),alternateValidator.bind(this,this.premiumPaymentTermControl)]);
    } else {
      this.premiumPaymentTermControl.controls['from'].addValidators(rangeValidator.bind(this, this.premiumPaymentTermControl));
      this.premiumPaymentTermControl.controls['to'].addValidators(rangeValidator.bind(this, this.premiumPaymentTermControl));
    }
    this.form.addControl('premium_payment_term', this.premiumPaymentTermControl)
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.premiumPaymentTermControl.controls['to'].value) < Number(this.premiumPaymentTermControl.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.premiumPaymentTermControl.controls['to'].invalid) {
  //     this.premiumPaymentTermControl.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.premiumPaymentTermControl.controls['from'].invalid) {
  //     this.premiumPaymentTermControl.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('premium_payment_term');
  }
}
