import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { sort } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-insurer-bp-field',
  templateUrl: './insurer-bp-field.component.html',
  styleUrls: ['./insurer-bp-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InsurerBpFieldComponent implements OnInit {
  @Input('required') isRequired = true;
  @Input('lobDependent') lobDependent: boolean = false;

  insurers: any = [];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService, public activatedRoute: ActivatedRoute) { };
  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl('insurer', new FormControl(null, Validators.required));
    } else {
      this.form.addControl('insurer', new FormControl(null));
    }
    // this.form.controls['insurer'].valueChanges.subscribe((val: any) => {
    //   if (val == null) {
    //     this.form.controls['insurer'].setValue('');
    //   }
    // })
    if (this.lobDependent){
      this.form.controls['lob'].valueChanges.subscribe(
        (val: any) => {
          if (val) {
            this.getinsurer(val);
          }
        }
      );
    }
    
  }

  getinsurer(product?:any) {
      const url = '/services/api/v1/rb_masters/insurer_list_based_on_lob'
      const params:any = {};
      if (product) {
        
        params['lob'] = product;
      }
      this.http.get(url,params).subscribe((res: any) => {
        this.insurers = res['data'];
        this.insurers = sort(this.insurers, 'insurer_name');
      });
  }

  ngOnDestroy(): void {
    this.form.removeControl('insurer');
  }

}
