<ng-template #withoutFile>
    <mat-label class="subHeading ">Partner EI Code<span *ngIf="isRequired" class="star">*</span></mat-label>
    <input
        formControlName="partner_code"
        [value]="form.controls['partner_code'].value | uppercase"
        class="reg mt-2"
        placeholder="Enter Valid EI Code"
    />
    <mat-error
        *ngIf="form.controls['partner_code'].value && form.controls['partner_code'].hasError('pattern')"
    >
    Partner code should be alpha-numeric
    </mat-error>
</ng-template>


<div *ngIf="fromFile; else withoutFile" class="row">
    <div class="col-4">
        <mat-label>{{label}}<span *ngIf="isRequired" class="star">*</span></mat-label>
        <input class="mt-3"
            formControlName={{controlName}}
            type=text
            readonly
        />
    </div>
    <div class="col-8">
        <app-file-field
            [label]="'CSV Upload'"
            [acceptOnly]="['text/csv']"
            (fileUploaded)="onCodeCsvUpload($event)"
        >
        </app-file-field>
        <p>
            <span> Click Here To </span>
            <a
            class="downloadFile"
            href="https://rb-dev-transcripts.s3.ap-south-1.amazonaws.com/template/sample_csv.csv"
            target="_blank"
            ><span>Download Template</span></a
            >
        </p>
    </div>
</div>
