import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-business-type-field',
  templateUrl: './business-type-field.component.html',
  styleUrls: ['./business-type-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class BusinessTypeFieldComponent implements OnInit {
  @Input('lob') lob: string = 'motor';
  @Input('required') isRequired = false;
  @Input('multiple') isMultiSelect:boolean = true;

  form: FormGroup;
  businessTypes: any = [];

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit(): void {
    this.setBusinessType();
    this.form = this.ctrlContainer.form;
    this.form.addControl('business_type', new FormControl([]));

    if (this.isRequired) {
      this.form.controls['business_type'].addValidators(Validators.required);
    }

    this.form.controls['business_type'].valueChanges.subscribe((val: any) => {
      if (val == null) {
        this.form.controls['business_type'].setValue([]);
      }
    });
  }

  ngOnChanges(changes: any) {
    if (changes['lob'] && changes['lob'].currentValue) {
      this.setBusinessType();
    }    
  }

  setBusinessType() {
    if(this.lob == 'life'){
      this.businessTypes = ['new_business', 'renewal', 'snb'];
    }
    else if(this.lob == 'health'){
      this.businessTypes = ['new_business', 'renewal', 'portability'];
    }
    else if(['finsure', 'finsure_life'].includes(this.lob)){
      this.businessTypes = ['new_business', 'renewal'];
    }
    else{
      this.businessTypes = ['new_business', 'renewal', 'rollover'];
    }
  }

  selectionChange(event:any){
    if(!this.isMultiSelect && event.isUserInput && event.source.selected){
      this.form.controls['business_type'].setValue([event.source.value]);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('business_type');
  }
}

