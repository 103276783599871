import { Component } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-auto-loan-vehicle-segment',
  templateUrl: './auto-loan-vehicle-segment.component.html',
  styleUrls: ['./auto-loan-vehicle-segment.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AutoLoanVehicleSegmentComponent {
  controlName = 'vehicle_segment';
  segments: string[] = [];

  constructor(private http: HttpService, private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.getVehicleSegments();
    if (!this.ctrlContainer.form.controls[this.controlName]) {
      this.ctrlContainer.form.addControl(this.controlName, new FormControl());
    }

    this.ctrlContainer.form.controls['insurer'].valueChanges.pipe(
      debounceTime(500)
    ).subscribe((bankNames) => {
      if (!Array.isArray(bankNames)) {
        bankNames = [bankNames];
      }
      this.getVehicleSegments(bankNames)
    });
  }

  getVehicleSegments(bankNames:any=null) {
    this.http.post('/services/api/v1/rb_masters/get-autoloan-segment', {'bank_names': bankNames}).subscribe((res: any) => {
      this.segments = res;
    });
  }

  ngOnDestroy() {
    this.ctrlContainer.form.removeControl(this.controlName);
  }
}
