import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-multi-year',
  templateUrl: './multi-year.component.html',
  styleUrls: ['./multi-year.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class MultiYearComponent implements OnInit {
  @Input('required') isRequired = false;
  form: FormGroup;

  multiYearTypes = [
    {isDefault:true, viewValue: 'Yes'},
    {isDefault:false, viewValue: 'No'},
  ];
  constructor(private ctrlContainer: FormGroupDirective){};


  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl('multi_year', new FormControl('', Validators.required));
    } else {
      this.form.addControl('multi_year', new FormControl(''));
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('premium_base');
  }
}
