import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-credit-card-bank-field',
  templateUrl: './credit-card-bank-field.component.html',
  styleUrls: ['./credit-card-bank-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CreditCardBankFieldComponent {
  @Input('lob') lob: any;
  @Input('multiple') isMultiSelect = false;
  cc_bank: any = [];
  cc_name: any = [];
  @Input('allRuleIdData') allRuleIdData: any;
  form: FormGroup;
  @Input('required') isRequired = false;
  ccBankControl = new FormControl();
  ccNameControl = new FormControl();
  lobSelected: any = '';
  credit_card: string;
  @ViewChild('cardNameSelect', { static: true })
  cardNameSelect: MatSelect;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('cc_bank', this.ccBankControl);
    if (this.form.value['cc_bank']) {
      this.ccBankControl.setValue(this.form.value['cc_bank']);
    }

    this.form.addControl('cc_name', this.ccNameControl);
    if (this.form.value['cc_name']) {
      this.ccNameControl.setValue(this.form.value['cc_name']);
    }

    this.getBankName();
    this.form.controls['cc_bank'].valueChanges.subscribe((val: any) => {
      if (val && val.length) {
        this.getCCName();
      } else {
        this.ccNameControl.setValue('');
      }
    });
  }

  getBankName() {

    const url = `/services/api/v1/rb_masters/finsure_credit_card_banks`;
    this.http.get(url).subscribe((res: any) => {
      this.cc_bank = res['data'];
      if (this.form.value['cc_bank_edit']) {
        this.ccBankControl.setValue(this.form.value['cc_bank_edit']);
      }
      if (this.form.controls['cc_bank'].value && this.form.controls['cc_bank'].value.length !=0) {
        this.getCCName();
      }
    });
  }

  getCCName() {
    this.credit_card = this.form.controls['cc_bank'].value;
    if(this.credit_card){
      const url = `/services/api/v1/rb_masters/finsure_credit_card_names?credit_card=${this.credit_card}`;
      this.http.get(url).subscribe((res: any) => {
        this.cc_name = res['data'];
        if (this.form.value['cc_name_edit']) {
          this.ccNameControl.setValue(this.form.value['cc_name_edit']);
        }
      });
    }
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        this.lobSelected = changes['lob'].currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('cc_bank');
    this.form.removeControl('cc_name');
  }
}
