import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-city-field',
  templateUrl: './city-field.component.html',
  styleUrls: ['./city-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CityFieldComponent implements OnInit {
  cities:any = [];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective, private http:HttpService){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('city', new FormControl());
    // this.getcity();
  }
  getcity() {
    const url ='/services/api/v1/rb_masters/cities';

    this.http.get(url).subscribe((res:any) => {
      this.cities =res['data'];
    });
  }
  

}
