import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-payin-type-field',
  templateUrl: './payin-type-field.component.html',
  styleUrls: ['./payin-type-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayinTypeFieldComponent implements OnInit {
  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('payin_type', new FormControl());
  }
}
