<mat-label class="subHeading">Approved Loan Amount<span *ngIf="isRequired" class="star">*</span></mat-label>
<div formGroupName="laonApprovedAmount" class="d-flex align-items-center mt-2" style="gap: 10px;">
    <input formControlName="from" [maxlength]="9" type="text" class="w-50 m-0" placeholder="From" appNumberOnly>
    -
    <input formControlName="to" [maxlength]="9"  type="text" class="w-50 m-0" placeholder="To" appNumberOnly>
</div>
<p
    class="text-danger"
    *ngIf="
    laonApprovedAmount.controls['from'].hasError('small')
    ||
    laonApprovedAmount.controls['to'].hasError('small')
" 
>
Invalid range for values
</p>
