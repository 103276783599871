import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-payout-base-field',
  templateUrl: './payout-base-field.component.html',
  styleUrls: ['./payout-base-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayoutBaseFieldComponent implements OnInit, OnChanges {
  payoutBase = {
    'motor': [
      { 'name': 'OD Premium', 'value': 1 },
      { 'name': 'TP Premium', 'value': 2 },
      { 'name': 'Net Premium', 'value': 3 },
      // { 'name': 'Per Policy', 'value': 4 },
    ],
    'motor4W': [
      { 'name': 'OD Premium', 'value': 1 },
      { 'name': 'TP Premium', 'value': 2 },
      { 'name': 'Net Premium', 'value': 3 },
      // { 'name': 'Per Policy', 'value': 4 },
    ],
    'cv': [
      { 'name': 'OD Premium', 'value': 1 },
      { 'name': 'TP Premium', 'value': 2 },
      { 'name': 'Net Premium', 'value': 3 },
      // {'name': 'Per Policy', 'value': 4},
    ],
    'travel': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'life': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'health': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'finsure': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'finsure_life': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'finsure_cards': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'misp': [
      { 'name': 'OD Premium', 'value': 1 },
      { 'name': 'TP Premium', 'value': 2 },
      { 'name': 'Net Premium', 'value': 3 },
      // {'name': 'Per Policy', 'value': 4},
    ],
    'sme': [
      { 'name': 'OD Premium', 'value': 1 },
      { 'name': 'TP Premium', 'value': 2 },
      { 'name': 'Net Premium', 'value': 3 },
    ],
    'auto_loans': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
    'road_side_assist': [
      { 'name': 'Net Premium', 'value': 3 }
    ],
  };

  @Input('lob') lob: any;
  payoutBaseTypes: any = [];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('payout_base', new FormControl(null, Validators.required));

    if (this.form.controls['payout_type']) {
      this.form.controls['payout_type'].valueChanges.subscribe((val: any) => {
        if (val == 'flat') {
          this.payoutBaseTypes = [{ 'name': 'Per Policy', 'value': 4 }];
        } else {
          this.payoutBaseTypes = this.lob ? this.payoutBase[this.lob as keyof typeof this.payoutBase] : [];
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let payoutBases = this.payoutBase[this.lob as keyof typeof this.payoutBase];
    if (this.form && this.form.controls['payout_type'] && this.form.controls['payout_type'].value == 'flat') {
      payoutBases = [{ 'name': 'Per Policy', 'value': 4 }];
    }
    this.payoutBaseTypes = payoutBases;
  }

  ngOnDestroy(): void {
    this.form.removeControl('payout_base');
  }
}
