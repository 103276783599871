import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-evidence-console',
  templateUrl: './evidence-console.component.html',
  styleUrls: ['./evidence-console.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EvidenceConsoleComponent implements OnInit {
  form: FormGroup;

  showError: boolean = false;

  fileCtrl: FormControl = new FormControl(null, Validators.required);
  @Input('lob') lob: string;

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('evi_capture', this.fileCtrl);
    this.fileCtrl.valueChanges.subscribe((val: any) => {
    });
  }


  onFileChange(file: File) {
    let fileExt:any = file.name.split('.');
    fileExt = fileExt[fileExt.length - 1];
    const timeNow = new Date();
    const fileName = `${this.lob}_${timeNow.getFullYear()}-${timeNow.getMonth() + 1}-${timeNow.getDate()}_${timeNow.getHours()}:${timeNow.getMinutes()}:${timeNow.getSeconds()}.${fileExt}`;
    const newFile = new File([file], fileName, { type: file.type });
    this.uploadFile(newFile);
  }

  uploadFile(file:File) {
    const formData = new FormData();
    formData.append('file', file);
    const url = '/services/api/v1/rules/upload_document';
    this.http.post(url, formData).subscribe((res: any) => {
      const s3Url = res['url'];
      this.fileCtrl.setValue(s3Url);
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('evi_capture');
  }
}
