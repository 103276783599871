import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-commissionable-premium-business-field',
  templateUrl: './commissionable-premium-business-field.component.html',
  styleUrls: ['./commissionable-premium-business-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CommissionablePremiumBusinessFieldComponent implements OnInit {
  @Input('required') isRequired = false;

  form: FormGroup;
  activePosForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    if (this.isRequired) {
      this.activePosForm.controls['to'].addValidators([Validators.required,rangeValidator.bind(this, this.activePosForm)]);
      this.activePosForm.controls['from'].addValidators([Validators.required,rangeValidator.bind(this, this.activePosForm)]);
    } else {
      this.activePosForm.controls['to'].addValidators(rangeValidator.bind(this, this.activePosForm));
      this.activePosForm.controls['from'].addValidators(rangeValidator.bind(this, this.activePosForm));
    }
    this.form = this.ctrlContainer.form;

    this.form.addControl('commissionable_premium', this.activePosForm);
  }

  ngOnDestroy(): void {
    this.form.removeControl('commissionable_premium');
  }
}
