import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EvidenceComponent implements OnInit{
  @Input('lob') lob: string;
  @ViewChild('evidenceTypeSelect', { static: false }) evidenceSelect: MatSelect;

  form: FormGroup;

  showError: boolean = false;

  fileCtrl: FormControl = new FormControl(null, Validators.required);
  fileNameCtrl: FormControl = new FormControl(null);

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService) { };

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.form.controls['evi_capture']) {
      this.fileCtrl.patchValue(this.form.controls['evi_capture'].value);
      this.form.removeControl('evi_capture');
    }
    this.form.addControl('evi_capture', this.fileCtrl);

    if (this.form.controls['evidence_document']) {
      this.fileNameCtrl.patchValue(this.form.controls['evidence_document'].value);
      this.form.removeControl('evidence_document');
    }
    this.form.addControl('evidence_document', this.fileNameCtrl);

    this.fileCtrl.valueChanges.subscribe((val: any) => {
      if (!val) {
        this.evidenceSelect.value = '';
      }
    });

    if (this.form.controls['insurer']) {
      this.form.controls['insurer'].valueChanges.subscribe((val: any) => {
        this.evidenceSelect.value = '';
        this.fileCtrl.setValue(null);
        this.fileNameCtrl.setValue(null);
        this.form.patchValue({ 'insurer_email': null });
      });
    }
  }

  onEvidenceTypeChange(value:any) {
    if (value == 'previous') {
      this.getPreviousEvidence();
    }
  }

  getPreviousEvidence() {
    this.showError = false;
    if (!this.form.controls['insurer'].value) {
      this.evidenceSelect.value = '';
      this.showError = true;
      return;
    }

    const params = {
      "insurer": this.form.controls['insurer'].value,
      "rule_type": this.form.controls['rule_type'].value,
      "lob": `${this.lob}`,
    }

    const url = `/services/api/v1/rules/evidence/get-previous`;
    this.http.get(url, params).subscribe((res: any) => {
      if (res.url) {
        this.patchForm(res);
      } else {
        this.evidenceSelect.value = '';
      }
    });

  }

  onFileChange(file: File) {
    let fileExt:any = file.name.split('.');
    fileExt = fileExt[fileExt.length - 1];
    const timeNow = new Date();
    const fileName = `${this.lob}_${timeNow.getFullYear()}-${timeNow.getMonth() + 1}-${timeNow.getDate()}_${timeNow.getHours()}:${timeNow.getMinutes()}:${timeNow.getSeconds()}.${fileExt}`;
    const newFile = new File([file], fileName, { type: file.type });
    this.uploadFile(newFile);
  }

  patchForm(data: any) {
    const url = data['url'];
    const insurer = data['insurer_email']
    this.fileCtrl.setValue(url);
    this.fileNameCtrl.setValue(data['evidence_document']);
    this.form.patchValue({ 'insurer_email': insurer });
  }

  uploadFile(file:File) {
    const formData = new FormData();
    formData.append('file', file);
    const url = '/services/api/v1/rules/upload_document';
    this.http.post(url, formData).subscribe((res: any) => {
      const s3Url = res['url'];
      this.fileCtrl.setValue(s3Url);
      this.fileNameCtrl.setValue(res['filename']);
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('evi_capture');
    this.form.removeControl('evidence_document');
  }
}
