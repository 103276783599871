import { Component } from '@angular/core';
import { AutoCompleteSearchDropdownOptionsI } from '../autocomplete-field/autocomplete-field.interface';

@Component({
  selector: 'app-not-in-city-field',
  templateUrl: './not-in-city-field.component.html',
  styleUrls: ['./not-in-city-field.component.scss']
})
export class NotInCityFieldComponent {
  controlName = 'not_in_city';

  autoCompleteFieldOptions: AutoCompleteSearchDropdownOptionsI = {
    searchApiUrl: '/services/api/v1/rb_masters/get-city-by-name',
    dataApiUrl: '/services/api/v1/rb_masters/cities',
    queryParamKey: 'city_name',
    dataIdKey: 'rb_id',
    dataNameKey: 'city_name',
  }
}
