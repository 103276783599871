<!-- <div style="display: flex; position: relative;"> -->
<mat-select
  #matSelect
  (click)="clickEvent()"
  (openedChange)="openedChange($event)"
  placeholder="{{ !isMultiSelect ? 'Select' : 'All' }}"
  [formControl]="selectFormControl"
  multiple
>
  <!-- <mat-select-trigger>
        {{selectFormControl.value ? selectFormControl.value[0] : ''}}
        <span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
            (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ? 'other' : 'others'}})
        </span>
    </mat-select-trigger> -->
  <div class="select-container">
    <mat-optgroup>
      <!--
        $event.stopPropagation() is there just to prevent space key and others to have
        any effect on mat options. Since default functionality of space key is to select 
        highlighted option.
      -->
      <input
        #search
        autocomplete="off"
        placeholder="Search"
        aria-label="Search"
        matInput
        [formControl]="searchTextboxControl"
        tabindex="-1"
        (keydown)="$event.stopPropagation()"
      />
      <button
        [disableRipple]="true"
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch($event)"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button *ngIf="isMultiSelect" class="btn selectAll" (click)="selectAll()">
        Select All
      </button>
      <button
        *ngIf="isMultiSelect"
        class="btn selectAll"
        (click)="unSelectAll()"
      >
        Remove All
      </button>
    </mat-optgroup>
    <mat-optgroup *ngIf="dataToShow.length == 0">
      <div>No results found!</div>
    </mat-optgroup>
    <mat-option *ngFor="let option of dataToShow"
      (onSelectionChange)="selectionChange($event)"
      [value]="option[dataValueKey] || option"
    >
      {{ option[dataSearchKey] || option }}
    </mat-option>
  </div>
</mat-select>
<app-tooltip [data]="toolTipData"></app-tooltip>
<!-- </div> -->