import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-payout-payment-mode',
  templateUrl: './payout-payment-mode.component.html',
  styleUrls: ['./payout-payment-mode.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PayoutPaymentModeComponent {
  constructor(
    private http: HttpService,
    private ctrlContainer: FormGroupDirective
  ) {}
  @ViewChild('payoutPaymentModeSelect', { static: true })
  payoutPaymentModeSelect: MatSelect;
  @Input('required') isRequired = false;
  @Input('allLobBasedList') allLobBasedList: any;
  @Input('allRuleIdData') allRuleIdData: any;
  @Input('lob') lob: any;
  @Input('paymentType') paymentType: any;
  form: FormGroup;
  insurers: any;
  paymentModeDataList: any = [];
  paymentModeList: any = [];
  newPaymentModeList: any = [];
  paymentTypeValue: any = '';
  payment_mode: any = '';
  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'payoutpaymentmode',
        new FormControl('', Validators.required)
      );
    } else {
      this.form.addControl('payoutpaymentmode', new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allLobBasedList']) {
      if (changes['allLobBasedList'].currentValue.length !== 0) {
        this.paymentModeDataList = changes['allLobBasedList'].currentValue;
      }
    }
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;

        this.newPaymentModeList = [];

        this.newPaymentModeList =
          this.paymentModeDataList[lobSelected]['Payment_Mode'];
      }
    }

    if (changes['paymentType']) {
      if (changes['paymentType'].currentValue !== '') {
        this.paymentTypeValue = changes['paymentType'].currentValue;
        this.paymentModeList = [];
        if (this.paymentTypeValue.indexOf('Online') !== -1) {
          this.paymentModeList.push('Online');
        }
        if (
          this.paymentTypeValue.indexOf('Cheque') !== -1 ||
          this.paymentTypeValue.indexOf('Cash') !== -1
        ) {
          this.paymentModeList.push('Offline');
        }

        // if (
        //   (this.paymentTypeValue[0] === 'Cheque' &&
        //     this.paymentTypeValue[1] === 'Cash') ||
        //   (this.paymentTypeValue[1] === 'Cheque' &&
        //     this.paymentTypeValue[0] === 'Cash')
        // ) {
        //   this.paymentModeList = ['Offline'];
        // } else if (
        //   this.paymentTypeValue[1] === 'Offline' ||
        //   this.paymentTypeValue[0] === 'Offline'
        // ) {
        //   this.paymentModeList = ['Cheque', 'Cash'];
        // } else if (
        //   this.paymentTypeValue[0] === 'Online' ||
        //   this.paymentTypeValue[1] === 'Online'
        // ) {
        //   this.paymentModeList.push('Online');
        // }
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('payoutpaymentmode');
  }
}
