import { Component, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-policy-issue-date',
  templateUrl: './policy-issue-date.component.html',
  styleUrls: ['./policy-issue-date.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  encapsulation: ViewEncapsulation.None,
})
export class PolicyIssueDateComponent {

  controlName = 'policy_issued_date';
  dateForm = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl(),
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    if (this.ctrlContainer.form.controls[this.controlName]) {
      this.dateForm.patchValue(this.ctrlContainer.form.controls[this.controlName].value);
      this.ctrlContainer.form.removeControl(this.controlName);
    }
    this.ctrlContainer.form.addControl(this.controlName, this.dateForm);
  }

  ngOnDestroy() {
    this.ctrlContainer.form.removeControl(this.controlName);
  }

}
