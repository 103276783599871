import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-vehicle-age-field',
  templateUrl: './vehicle-age-field.component.html',
  styleUrls: ['./vehicle-age-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class VehicleAgeFieldComponent implements OnInit {
  form: FormGroup;

  fromAges: number[] = Array.from(Array(51).keys()).map(x => x);
  toAges: number[] = [...this.fromAges];

  vehicleAgeForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.vehicleAgeForm.controls['from'].valueChanges.subscribe((val: any) => {
      this.toAges = this.fromAges.filter((age: number) => age >= val);
      if (this.vehicleAgeForm.controls['to'].value < val) {
        this.vehicleAgeForm.controls['to'].setValue(null);
      }
    });

    this.form = this.ctrlContainer.form;

    if (this.form.controls['vehicle_age']) {
      this.vehicleAgeForm.patchValue(this.form.controls['vehicle_age'].value);
      this.form.removeControl('vehicle_age');
    }

    this.form.addControl('vehicle_age', this.vehicleAgeForm);
  }
  
  ngOnDestroy(): void {
    this.form.removeControl('vehicle_age');
  }
}
