import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';


@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]

})
export class RenewalComponent implements OnInit {

  form:FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('renewal', new FormControl());
  }
}
