import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
    {
        path: 'rules',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./rules/rules.module').then((m) => m.RulesModule),
    },
    {
        path: 'admin',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'dashboard',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'approval',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./approval/approval.module').then((m) => m.ApprovalModule),
    },
    {
        path: 'payout-processing',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./payout-processing/payout-processing.module').then(
                (m) => m.PayoutProcessingModule
            ),
    },
    {
        path: 'finance',
        canLoad: [AuthGuard],
        loadChildren: () =>
        import('./finance/finance.module').then(
            (m) => m.FinanceModule
        ),
    },
    {
        path: 'disbursement-history',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./disbursement-history/disbursement-history.module').then(
                (m) => m.DisbursementHistoryModule
            ),
    },
    {
        path: 'outstanding',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./outstanding/outstanding.module').then(
                (m) => m.OutstandingModule
            ),
    },
    {
        path: 'payout-statement',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./payout-statement/payout-statement.module').then(
                (m) => m.PayoutStatementModule
            ),
    },
    {
        path: 'pay-in-processing',
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('./pay-in-processing/pay-in-processing.module').then(
                (m) => m.PayInProcessingModule
            ),
    },
    {
        path: 'rule-search',
        canLoad: [AuthGuard],
        loadChildren: () => import('./rule-search/rule-search.module').then((m)=>m.RuleSearchModule)
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
