import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-weighted-premium-field',
  templateUrl: './weighted-premium-field.component.html',
  styleUrls: ['./weighted-premium-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class WeightedPremiumFieldComponent implements OnInit{
  @Input('required') isRequired = false;

  form: FormGroup;
  weightedPremiumForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    if (this.isRequired) {
      this.weightedPremiumForm.controls['to'].addValidators([Validators.required,rangeValidator.bind(this, this.weightedPremiumForm)]);
      this.weightedPremiumForm.controls['from'].addValidators([Validators.required,rangeValidator.bind(this, this.weightedPremiumForm)]);
    } else {
      this.weightedPremiumForm.controls['to'].addValidators(rangeValidator.bind(this, this.weightedPremiumForm));
      this.weightedPremiumForm.controls['from'].addValidators(rangeValidator.bind(this, this.weightedPremiumForm));
    }
    this.form = this.ctrlContainer.form;

    this.form.addControl('weighted_premium', this.weightedPremiumForm);
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.weightedPremiumForm.controls['to'].value) < Number(this.weightedPremiumForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.weightedPremiumForm.controls['to'].invalid) {
  //     this.weightedPremiumForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.weightedPremiumForm.controls['from'].invalid) {
  //     this.weightedPremiumForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('weighted_premium');
  }

}
