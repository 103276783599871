import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { StateFieldComponent } from './components/form_fields/state-field/state-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { AgeFieldComponent } from './components/form_fields/age-field/age-field.component';
import { BusinessTypeFieldComponent } from './components/form_fields/business-type-field/business-type-field.component';
import { CityFieldComponent } from './components/form_fields/city-field/city-field.component';
import { CoverageTypeFieldComponent } from './components/form_fields/coverage-type-field/coverage-type-field.component';
import { CpaCoverFieldComponent } from './components/form_fields/cpa-cover-field/cpa-cover-field.component';
import { CubicCapacityFieldComponent } from './components/form_fields/cubic-capacity-field/cubic-capacity-field.component';
import { DateFieldComponent } from './components/form_fields/date-field/date-field.component';
import { DefermentPeriodComponent } from './components/form_fields/deferment-period/deferment-period.component';
import { FuelTypeFieldComponent } from './components/form_fields/fuel-type-field/fuel-type-field.component';
import { GVWFieldComponent } from './components/form_fields/gvw-field/gvw-field.component';
import { IDVFieldComponent } from './components/form_fields/idv-field/idv-field.component';
import { InsurerFieldComponent } from './components/form_fields/insurer-field/insurer-field.component';
import { LCCFieldComponent } from './components/form_fields/lcc-field/lcc-field.component';
import { MakeFieldComponent } from './components/form_fields/make-field/make-field.component';
import { MaturityAgeComponent } from './components/form_fields/maturity-age/maturity-age.component';
import { ModelFieldComponent } from './components/form_fields/model-field/model-field.component';
import { NCBFieldComponent } from './components/form_fields/ncb-field/ncb-field.component';
import { OrderTypeComponent } from './components/form_fields/order-type/order-type.component';
import { PayinPercentageFieldComponent } from './components/form_fields/payin-percentage-field/payin-percentage-field.component';
import { PayinTypeFieldComponent } from './components/form_fields/payin-type-field/payin-type-field.component';
import { PaymentModeFieldComponent } from './components/form_fields/payment-mode-field/payment-mode-field.component';
import { PayoutBaseFieldComponent } from './components/form_fields/payout-base-field/payout-base-field.component';
import { PayoutPercentageFieldComponent } from './components/form_fields/payout-percentage-field/payout-percentage-field.component';
import { PayoutTypeFieldComponent } from './components/form_fields/payout-type-field/payout-type-field.component';
import { PlanTypeComponent } from './components/form_fields/plan-type/plan-type.component';
import { PolicyAgeComponent } from './components/form_fields/policy-age/policy-age.component';
import { PremiumBaseFieldComponent } from './components/form_fields/premium-base-field/premium-base-field.component';
import { PremiumPaymentTypeComponent } from './components/form_fields/premium-payment-type/premium-payment-type.component';
import { RegistrationNoFieldComponent } from './components/form_fields/registration-no-field/registration-no-field.component';
import { RtoFieldComponent } from './components/form_fields/rto-field/rto-field.component';
import { RuleFieldComponent } from './components/form_fields/rule-field/rule-field.component';
import { SeatingCapacityFieldComponent } from './components/form_fields/seating-capacity-field/seating-capacity-field.component';
import { SumInsuredFieldComponent } from './components/form_fields/sum-insured-field/sum-insured-field.component';
import { VarientFieldComponent } from './components/form_fields/varient-field/varient-field.component';
import { VehicleAgeFieldComponent } from './components/form_fields/vehicle-age-field/vehicle-age-field.component';
import { FormSaveButtonsComponent } from './components/form_fields/form-save-buttons/form-save-buttons.component';
import { StateCityRtoComponent } from './components/form_fields/state-city-rto/state-city-rto.component';
import { MakeModelVariantComponent } from './components/form_fields/make-model-variant/make-model-variant.component';
import { VehicleCategoryComponent } from './components/form_fields/vehicle-category/vehicle-category.component';
import { PolicyPlanComponent } from './components/form_fields/policy-plan/policy-plan.component';
import { LobSelectComponent } from './components/form_fields/lob-select/lob-select.component';
import { ContestPeriodComponent } from './components/form_fields/contest-period/contest-period.component';
import { EngineNoFieldComponent } from './components/form_fields/engine-no-field/engine-no-field.component';
import { ChassisNoFieldComponent } from './components/form_fields/chassis-no-field/chassis-no-field.component';
import { DobFieldComponent } from './components/form_fields/dob-field/dob-field.component';
import { EvidenceComponent } from './components/form_fields/evidence/evidence.component';
import { FileFieldComponent } from './components/form_fields/file-field/file-field.component';
import { HistoryButtonComponent } from './components/form_fields/history-button/history-button.component';
import { InsurerEmailComponent } from './components/form_fields/insurer-email/insurer-email.component';
import { NameFieldComponent } from './components/form_fields/name-field/name-field.component';
import { PayinOutComponent } from './components/form_fields/payin-out/payin-out.component';
import { SearchDropdownComponent } from './components/form_fields/search-dropdown/search-dropdown.component';
import { CommaSeperatorPipe, PartnerCodeComponent } from './components/form_fields/partner-code/partner-code.component';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { PayOutComponent } from './components/form_fields/pay-out/pay-out.component';
import { ProductTypeFieldComponent } from './components/form_fields/product-type-field/product-type-field.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { TableDialogComponent } from './components/dialog-components/table-dialog/table-dialog.component';
import { ProductTypeComponent } from './components/form_fields/product-type/product-type.component';
import { PremiumPaymentTermComponent } from './components/form_fields/premium-payment-term/premium-payment-term.component';
import { TotalBusinessComponent } from './components/form_fields/total-business/total-business.component';
import { PaymentTermComponent } from './components/form_fields/payment-term/payment-term.component';
import { NumberOnlyDirective } from './directive/number-only.directive';
import { PercentOnlyDirective } from './directive/percent-only.directive';
import { NopFieldComponent } from './components/form_fields/nop-field/nop-field.component';
import { ProductNameFieldComponent } from './components/form_fields/product-name-field/product-name-field.component';
import { ProductFinsureFieldComponent } from './components/form_fields/product-finsure-field/product-finsure-field.component';
import { PlanTypeFinsureFieldComponent } from './components/form_fields/plan-type-finsure-field/plan-type-finsure-field.component';
import { PlanNameFinsureFieldComponent } from './components/form_fields/plan-name-finsure-field/plan-name-finsure-field.component';
import { PayinOutParentComponent } from './components/form_fields/payin-out-parent/payin-out-parent.component';
import { PayinOutChildComponent } from './components/form_fields/payin-out-child/payin-out-child.component';
import { BasicDialogComponent } from './components/dialog-components/basic-dialog/basic-dialog.component';
import { AlphaNumericDirective } from './directive/alpha-numeric.directive';
import { TwoDigitDirective } from './directive/two-digit.directive';
import { StateCityComponent } from './components/form_fields/state-city/state-city.component';
import { InsurerFieldLifeComponent } from './components/form_fields/insurer-field-life/insurer-field-life.component';
import { CompanyFieldComponent } from './components/form_fields/company-field/company-field.component';
import { ActivationRuleFieldComponent } from './components/form_fields/activation-rule-field/activation-rule-field.component';
import { ActivePosFieldComponent } from './components/form_fields/active-pos-field/active-pos-field.component';
import { WeightedPremiumFieldComponent } from './components/form_fields/weighted-premium-field/weighted-premium-field.component';
import { ActivationBonusFieldComponent } from './components/form_fields/activation-bonus-field/activation-bonus-field.component';
import { MinimumCommissionablePremiumFieldComponent } from './components/form_fields/minimum-commissionable-premium-field/minimum-commissionable-premium-field.component';
import { CommissionablePremiumBusinessFieldComponent } from './components/form_fields/commissionable-premium-business-field/commissionable-premium-business-field.component';
import { PartnerPerformanceIncentiveFieldComponent } from './components/form_fields/partner-performance-incentive-field/partner-performance-incentive-field.component';
import { MinimumPartnerActiveFieldComponent } from './components/form_fields/minimum-partner-active-field/minimum-partner-active-field.component';
import { PartnerCodeTypeComponent } from './components/form_fields/partner-code-type/partner-code-type.component';
import { ProductComponent } from './components/form_fields/product/product.component';
import { SubProductTypeComponent } from './components/form_fields/sub-product-type/sub-product-type.component';
import { PolicyTypeComponent } from './components/form_fields/policy-type/policy-type.component';
import { PaymentTypeComponent } from './components/form_fields/payment-type/payment-type.component';
import { RbBusinessTypeComponent } from './components/form_fields/rb-business-type/rb-business-type.component';
import { InsurerBusinessTypeComponent } from './components/form_fields/insurer-business-type/insurer-business-type.component';
import { PayoutCompanyComponent } from './components/form_fields/payout-company/payout-company.component';
import { LobBpFieldComponent } from './components/form_fields/lob-bp-field/lob-bp-field.component';
import { InsurerBpFieldComponent } from './components/form_fields/insurer-bp-field/insurer-bp-field.component';
import { ApproveButtonComponent } from './components/form_fields/approve-button/approve-button.component';
import { PayoutPaymentModeComponent } from './components/form_fields/payout-payment-mode/payout-payment-mode.component';
import { UinNoComponent } from './components/form_fields/uin-no/uin-no.component';
import { SumOfPremiumComponent } from './components/form_fields/sum-of-premium/sum-of-premium.component';
import { ExtendDialogComponent } from './components/dialog-components/extend-dialog/extend-dialog.component';
import { CarryButtonComponent } from './components/form_fields/carry-button/carry-button.component';
import { MatDialogModule } from '@angular/material/dialog';

import { SuccessDateComponent } from './components/form_fields/success-date/success-date.component';
import { TransactionIdComponen } from './components/form_fields/transaction-id/transaction-id.component';
import { InsurerFieldLifePayoutComponent } from './components/form_fields/insurer-field-life-payout/insurer-field-life-payout.component';
import { EvidenceConsoleComponent } from './components/form_fields/evidence-console/evidence-console.component';
import { ConsoleDialogComponent } from './components/dialog-components/console-dialog/console-dialog.component';
import { ReasonDialogComponent } from './components/dialog-components/reason-dialog/reason-dialog.component';
import { RenewalComponent } from './components/form_fields/renewal/renewal.component';
import { DownloadDialogComponent } from './components/dialog-components/download-dialog/download-dialog.component';
import { PolicyTenureComponent } from './components/form_fields/policy-tenure/policy-tenure.component';
import { CopyButtonComponent } from './components/form_fields/copy-button/copy-button.component';
import { RunRulesDialogComponent } from './components/dialog-components/run-rules-dialog/run-rules-dialog.component';
import { RuleIdComponent } from './components/form_fields/rule-id/rule-id.component';
import { SourcingTypeComponent } from './components/form_fields/sourcing-type/sourcing-type.component';
import { MultiYearComponent } from './components/form_fields/multi-year/multi-year.component';
import { PlanNameLifeFinsureFieldComponent } from './components/form_fields/plan-name-life-finsure-field/plan-name-life-finsure-field.component';
import { CreditCardBankFieldComponent } from './components/form_fields/credit-card-bank-field/credit-card-bank-field.component';
import { CreditCardNameComponent } from './components/form_fields/credit-card-name/credit-card-name.component';
import { InsurerFieldTwoWheelerComponent } from './components/form_fields/insurer-field-two-wheeler/insurer-field-two-wheeler.component';
import { NetPremiumFieldComponent } from './components/form_fields/net-premium-field/net-premium-field.component';
import { RbInsurerNomenclatureComponent } from './components/form_fields/rb-insurer-nomenclature/rb-insurer-nomenclature.component';
import { FinsureProductFieldV2Component } from './components/form_fields/finsure-product-field-v2/finsure-product-field-v2.component';
import { SchedularDialogComponent } from './components/dialog-components/schedular-dialog/schedular-dialog.component';
import { AnnuityTypeComponent } from './components/form_fields/annuity-type/annuity-type.component';
import { PlanPolicyTypeComponent } from './components/form_fields/plan-policy-type/plan-policy-type.component';
import { PayTillAgeFieldComponent } from './components/form_fields/pay-till-age-field/pay-till-age-field.component';
import { AutocompleteFieldComponent } from './components/form_fields/autocomplete-field/autocomplete-field.component';
import { NotInRtoComponent } from './components/form_fields/not-in-rto/not-in-rto.component';
import { NotInCityFieldComponent } from './components/form_fields/not-in-city-field/not-in-city-field.component';
import { NotInMakeFieldComponent } from './components/form_fields/not-in-make-field/not-in-make-field.component';
import { NotInModelFieldComponent } from './components/form_fields/not-in-model-field/not-in-model-field.component';
import { NotInVariantFieldComponent } from './components/form_fields/not-in-variant-field/not-in-variant-field.component';
import { AddRemoveButtonDirective } from './directive/add-remove-button.directive';
import { PayinCompanyComponent } from './components/form_fields/payin-company/payin-company.component';
import { GroupRtoComponent } from './components/form_fields/group-rto/group-rto.component';
import { SelectCompanyComponent } from './components/form_fields/select-company/select-company.component';
import { CustomerTypeComponent } from './components/form_fields/customer-type/customer-type.component';
import { RenewalPayinOutParentComponent } from './components/form_fields/renewal-payin-out-parent/renewal-payin-out-parent.component';
import { LoanBankFieldComponent } from './components/form_fields/loan-bank-field/loan-bank-field.component';
import { NoOfMembersComponent } from './components/form_fields/no-of-members/no-of-members.component';
import { LoanApprovedAmountComponent } from './components/form_fields/loan-approved-amount/loan-approved-amount.component';
import { FinsureInsurerFieldComponent } from './components/form_fields/finsure-insurer-field/finsure-insurer-field.component';
import { PayoutOdPercentRangeComponent } from './components/form_fields/payout-od-percent-range/payout-od-percent-range.component';
import { PayoutTpPercentRangeComponent } from './components/form_fields/payout-tp-percent-range/payout-tp-percent-range.component';
import { PayoutNetPercentRangeComponent } from './components/form_fields/payout-net-percent-range/payout-net-percent-range.component';
import { ConfirmActionDirective } from './directive/confirm-action.directive';
import { PolicyIssueDateComponent } from './components/form_fields/policy-issue-date/policy-issue-date.component';
import { RenewalPaymentModeComponent } from './components/form_fields/renewal-payment-mode/renewal-payment-mode.component';
import { AutoLoanBankNameComponent } from './components/form_fields/auto-loan-bank-name/auto-loan-bank-name.component';
import { AutoLoanCibilScoreComponent } from './components/form_fields/auto-loan-cibil-score/auto-loan-cibil-score.component';
import { AutoLoanInterestRateComponent } from './components/form_fields/auto-loan-interest-rate/auto-loan-interest-rate.component';
import { AutoLoanVehicleSegmentComponent } from './components/form_fields/auto-loan-vehicle-segment/auto-loan-vehicle-segment.component';
import { LoanDisburseAmountComponent } from './components/form_fields/loan-disburse-amount/loan-disburse-amount.component';
import { RangeFieldComponent } from './components/form_fields/range-field/range-field.component';
import { TrailerIdvFieldComponent } from './components/form_fields/trailer-idv-field/trailer-idv-field.component';
import { ZeroDepComponent } from './components/form_fields/zero-dep/zero-dep.component';

@NgModule({
  declarations: [
    StateFieldComponent,
    RtoFieldComponent,
    RuleFieldComponent,
    InsurerFieldComponent,
    CityFieldComponent,
    MakeFieldComponent,
    ModelFieldComponent,
    VarientFieldComponent,
    DateFieldComponent,
    FuelTypeFieldComponent,
    VehicleAgeFieldComponent,
    SeatingCapacityFieldComponent,
    RegistrationNoFieldComponent,
    CubicCapacityFieldComponent,
    BusinessTypeFieldComponent,
    CoverageTypeFieldComponent,
    NCBFieldComponent,
    CpaCoverFieldComponent,
    IDVFieldComponent,
    PayoutTypeFieldComponent,
    PayoutPercentageFieldComponent,
    PayinPercentageFieldComponent,
    PremiumBaseFieldComponent,
    PayoutBaseFieldComponent,
    PayinTypeFieldComponent,
    GVWFieldComponent,
    LCCFieldComponent,
    AgeFieldComponent,
    PaymentModeFieldComponent,
    SumInsuredFieldComponent,
    MaturityAgeComponent,
    PlanTypeComponent,
    PremiumPaymentTypeComponent,
    DefermentPeriodComponent,
    OrderTypeComponent,
    PolicyAgeComponent,
    PlanTypeComponent,
    FormSaveButtonsComponent,
    StateCityRtoComponent,
    MakeModelVariantComponent,
    VehicleCategoryComponent,
    PolicyPlanComponent,
    LobSelectComponent,
    ContestPeriodComponent,
    EngineNoFieldComponent,
    ChassisNoFieldComponent,
    DobFieldComponent,
    NameFieldComponent,
    FileFieldComponent,
    InsurerEmailComponent,
    PayinOutComponent,
    SearchDropdownComponent,
    HistoryButtonComponent,
    EvidenceComponent,
    PartnerCodeComponent,
    CommaSeperatorPipe,
    PayOutComponent,
    ProductTypeFieldComponent,
    TooltipComponent,
    TableDialogComponent,
    ProductTypeComponent,
    PremiumPaymentTermComponent,
    TotalBusinessComponent,
    PaymentTermComponent,
    NumberOnlyDirective,
    PercentOnlyDirective,
    NopFieldComponent,
    ProductNameFieldComponent,
    ProductFinsureFieldComponent,
    PlanTypeFinsureFieldComponent,
    PlanNameFinsureFieldComponent,
    PayinOutParentComponent,
    PayinOutChildComponent,
    BasicDialogComponent,
    AlphaNumericDirective,
    TwoDigitDirective,
    StateCityComponent,
    InsurerFieldLifeComponent,
    CompanyFieldComponent,
    ActivationRuleFieldComponent,
    ActivePosFieldComponent,
    WeightedPremiumFieldComponent,
    ActivationBonusFieldComponent,
    MinimumCommissionablePremiumFieldComponent,
    CommissionablePremiumBusinessFieldComponent,
    PartnerPerformanceIncentiveFieldComponent,
    MinimumPartnerActiveFieldComponent,
    PartnerCodeTypeComponent,
    ProductComponent,
    SubProductTypeComponent,
    PolicyTypeComponent,
    PaymentTypeComponent,
    RbBusinessTypeComponent,
    InsurerBusinessTypeComponent,
    PayoutCompanyComponent,
    LobBpFieldComponent,
    InsurerBpFieldComponent,
    ApproveButtonComponent,
    PayoutPaymentModeComponent,
    UinNoComponent,
    ExtendDialogComponent,
    CarryButtonComponent,
    SuccessDateComponent,
    TransactionIdComponen,
    InsurerFieldLifePayoutComponent,
    // SumOfPremiumComponent,
    EvidenceConsoleComponent,
    ConsoleDialogComponent,
    ReasonDialogComponent,
    RenewalComponent,
    DownloadDialogComponent,
    PolicyTenureComponent,
    CopyButtonComponent,
    RunRulesDialogComponent,
    RuleIdComponent,
    SourcingTypeComponent,
    MultiYearComponent,
    PlanNameLifeFinsureFieldComponent,
    CreditCardBankFieldComponent,
    CreditCardNameComponent,
    InsurerFieldTwoWheelerComponent,
    NetPremiumFieldComponent,
    RbInsurerNomenclatureComponent,
    FinsureProductFieldV2Component,
    SchedularDialogComponent,
    AnnuityTypeComponent,
    PlanPolicyTypeComponent,
    PayTillAgeFieldComponent,
    AutocompleteFieldComponent,
    NotInRtoComponent,
    NotInCityFieldComponent,
    NotInMakeFieldComponent,
    NotInModelFieldComponent,
    NotInVariantFieldComponent,
    PayinCompanyComponent,
    GroupRtoComponent,
    SelectCompanyComponent,
    CustomerTypeComponent,
    RenewalPayinOutParentComponent,
    LoanBankFieldComponent,
    NoOfMembersComponent,
    LoanApprovedAmountComponent,
    FinsureInsurerFieldComponent,
    PayoutOdPercentRangeComponent,
    PayoutTpPercentRangeComponent,
    PayoutNetPercentRangeComponent,
    ConfirmActionDirective,
    PolicyIssueDateComponent,
    RenewalPaymentModeComponent,
    AutoLoanBankNameComponent,
    AutoLoanCibilScoreComponent,
    AutoLoanInterestRateComponent,
    AutoLoanVehicleSegmentComponent,
    LoanDisburseAmountComponent,
    RangeFieldComponent,
    ZeroDepComponent,
    TrailerIdvFieldComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatExpansionModule,
    MatIconModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDialogModule,
  ],
  exports: [
    MatInputModule,
    MatSelectModule,
    StateFieldComponent,
    RtoFieldComponent,
    RuleFieldComponent,
    CityFieldComponent,
    MakeFieldComponent,
    InsurerFieldComponent,
    ModelFieldComponent,
    VarientFieldComponent,
    DateFieldComponent,
    FuelTypeFieldComponent,
    VehicleAgeFieldComponent,
    SeatingCapacityFieldComponent,
    RegistrationNoFieldComponent,
    CubicCapacityFieldComponent,
    BusinessTypeFieldComponent,
    CoverageTypeFieldComponent,
    NCBFieldComponent,
    CpaCoverFieldComponent,
    IDVFieldComponent,
    PayoutTypeFieldComponent,
    PayoutPercentageFieldComponent,
    PayinPercentageFieldComponent,
    PremiumBaseFieldComponent,
    PayoutBaseFieldComponent,
    PayinTypeFieldComponent,
    GVWFieldComponent,
    LCCFieldComponent,
    AgeFieldComponent,
    PaymentModeFieldComponent,
    SumInsuredFieldComponent,
    MaturityAgeComponent,
    PlanTypeComponent,
    PremiumPaymentTypeComponent,
    DefermentPeriodComponent,
    PlanTypeComponent,
    OrderTypeComponent,
    PolicyAgeComponent,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    FormSaveButtonsComponent,
    StateCityRtoComponent,
    MakeModelVariantComponent,
    VehicleCategoryComponent,
    PolicyPlanComponent,
    LobSelectComponent,
    ContestPeriodComponent,
    EngineNoFieldComponent,
    ChassisNoFieldComponent,
    DobFieldComponent,
    NameFieldComponent,
    FileFieldComponent,
    InsurerEmailComponent,
    PayinOutComponent,
    SearchDropdownComponent,
    HistoryButtonComponent,
    EvidenceComponent,
    PartnerCodeComponent,
    PayOutComponent,
    ProductTypeFieldComponent,
    TableDialogComponent,
    ProductTypeComponent,
    PremiumPaymentTermComponent,
    TotalBusinessComponent,
    PaymentTermComponent,
    NumberOnlyDirective,
    PercentOnlyDirective,
    NopFieldComponent,
    ProductNameFieldComponent,
    ProductFinsureFieldComponent,
    PlanTypeFinsureFieldComponent,
    PlanNameFinsureFieldComponent,
    PayinOutParentComponent,
    PayinOutChildComponent,
    TwoDigitDirective,
    StateCityComponent,
    InsurerFieldLifeComponent,
    CompanyFieldComponent,
    ActivationRuleFieldComponent,
    ActivePosFieldComponent,
    WeightedPremiumFieldComponent,
    ActivationBonusFieldComponent,
    MinimumCommissionablePremiumFieldComponent,
    CommissionablePremiumBusinessFieldComponent,
    PartnerPerformanceIncentiveFieldComponent,
    MinimumPartnerActiveFieldComponent,
    PartnerCodeTypeComponent,
    ProductComponent,
    SubProductTypeComponent,
    PolicyTypeComponent,
    PaymentTypeComponent,
    RbBusinessTypeComponent,
    InsurerBusinessTypeComponent,
    PayoutCompanyComponent,
    LobBpFieldComponent,
    InsurerBpFieldComponent,
    ApproveButtonComponent,
    PayoutPaymentModeComponent,
    TooltipComponent,
    UinNoComponent,
    ExtendDialogComponent,
    CarryButtonComponent,
    InsurerFieldLifePayoutComponent,
    SuccessDateComponent,
    TransactionIdComponen,
    InsurerFieldLifePayoutComponent,
    EvidenceConsoleComponent,
    ConsoleDialogComponent,
    ReasonDialogComponent,
    RenewalComponent,
    DownloadDialogComponent,
    PolicyTenureComponent,
    CopyButtonComponent,
    RunRulesDialogComponent,
    RuleIdComponent,
    SourcingTypeComponent,
    MultiYearComponent,
    PlanNameLifeFinsureFieldComponent,
    CreditCardBankFieldComponent,
    CreditCardNameComponent,
    InsurerFieldTwoWheelerComponent,
    NetPremiumFieldComponent,
    RbInsurerNomenclatureComponent,
    FinsureProductFieldV2Component,
    SchedularDialogComponent,
    AnnuityTypeComponent,
    PlanPolicyTypeComponent,
    PayTillAgeFieldComponent,
    NotInRtoComponent,
    NotInCityFieldComponent,
    NotInMakeFieldComponent,
    NotInModelFieldComponent,
    NotInVariantFieldComponent,
    PayinCompanyComponent,
    GroupRtoComponent,
    SelectCompanyComponent,
    CustomerTypeComponent,
    RenewalPayinOutParentComponent,
    LoanBankFieldComponent,
    NoOfMembersComponent,
    LoanApprovedAmountComponent,
    FinsureInsurerFieldComponent,
    ConfirmActionDirective,
    PolicyIssueDateComponent,
    RenewalPaymentModeComponent,
    AutoLoanBankNameComponent,
    AutoLoanCibilScoreComponent,
    AutoLoanInterestRateComponent,
    AutoLoanVehicleSegmentComponent,
    LoanDisburseAmountComponent,
    TrailerIdvFieldComponent,
    ZeroDepComponent,
  ],
})
export class SharedModule {}
