import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration-no-field',
  templateUrl: './registration-no-field.component.html',
  styleUrls: ['./registration-no-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RegistrationNoFieldComponent implements OnInit {
  // registrationNumberRegex = /^[A-Z]{2}[0-9A-Z]{2}[A-Z]{2}[0-9]{4}$/gmi;
  registrationNumberRegex = /^[a-zA-Z0-9]+$/;

  @Input('required') isRequired = false;

  form: FormGroup;
  registrationControl = new FormControl(null, Validators.pattern(this.registrationNumberRegex));


  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('registration_no', this.registrationControl);

    this.form.controls['business_type'].valueChanges.subscribe((val: any) => {
      if (val.indexOf('renewal') != -1 || val.indexOf('rollover') != -1) {
        this.isRequired = true;
        if (!this.registrationControl.hasValidator(Validators.required)) {
          this.registrationControl.addValidators(Validators.required);
          this.registrationControl.updateValueAndValidity();
        }
      } else if(this.registrationControl.hasValidator(Validators.required)){
        this.registrationControl.removeValidators(Validators.required);
        this.isRequired = false;
        this.registrationControl.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('registration_no');
  }
}
