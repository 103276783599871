export const RESOURCES = {
    "USER_CREATION": 1,
    "USER_ACTIVATION_AND_DEACTIVATION": 2,
    "CREATION_OF_ROLE": 3,
    "EDIT_ROLE": 4,
    "CREATION_OF_ESCALATION_TREE": 5,
    "EDIT_ESCALATION_TREE": 6,
    "ADD_COMPANY": 7,
    "EDIT_COMPANY": 8,
    "MAIN_RULE_RULE_CREATION": 9,
    "MAIN_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS": 10,
    "MAIN_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS": 11,
    "MAIN_RULE_RULE_CREATION_NO_BACKDATION_LIMIT": 12,
    "MAIN_RULE_APPROVAL_OF_RULE": 13,
    "MAIN_RULE_DEACTIVATION_OF_RULE": 14,
    "MAIN_RULE_EDIT_RULE_PRIOR_TO_APPROVAL": 15,
    "MAIN_RULE_EDIT_RULE_POST_APPROVAL": 62,
    // "MAIN_RULE_REJECTION_OF_RULE": 17,
    "MAIN_RULE_EDIT_REJECTED_RULE": 18,
    "SPECIAL_RULE_RULE_CREATION": 19,
    "SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS": 20,
    "SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS": 21,
    "SPECIAL_RULE_RULE_CREATION_NO_BACKDATION_LIMIT": 22,
    "SPECIAL_RULE_APPROVAL_OF_RULE": 23,
    "SPECIAL_RULE_DEACTIVATION_OF_RULE": 24,
    "SPECIAL_RULE_EDIT_RULE_PRIOR_TO_APPROVAL": 25,
    "SPECIAL_RULE_EDIT_RULE_POST_APPROVAL": 26,
    // "SPECIAL_RULE_REJECTION_OF_RULE": 27,
    "SPECIAL_RULE_EDIT_REJECTED_RULE": 28,
    "CONTEST_RULE_CREATION_OF_RULE": 29,
    "CONTEST_RULE_RULE_CREATION_BACKDATION_UP_TO_07_DAYS": 30,
    "CONTEST_RULE_RULE_CREATION_BACKDATION_UP_TO_30_DAYS": 31,
    "CONTEST_RULE_RULE_CREATION_NO_BACKDATION_LIMIT": 32,
    "CONTEST_RULE_APPROVAL_OF_RULE": 33,
    "CONTEST_RULE_DEACTIVATION_OF_RULE": 34,
    "CONTEST_RULE_EDIT_RULE_PRIOR_TO_APPROVAL": 35,
    "CONTEST_RULE_EDIT_RULE_POST_APPROVAL": 36,
    // "CONTEST_RULE_REJECTION_OF_RULE": 37,
    "CONTEST_RULE_EDIT_REJECTED_RULE": 38,
    "EXCEL_DOWNLOAD": 39,
    "CARRY_FORWARD": 40,
    "CONSOLE_TRANSFER": 41,
    "CONSOLE_TRANSFER_APPROVAL_REJECT":42,
    "COPY_MAIN_RULES":43,
    "TRANSACTION_LEVEL_ACCESS":44,
    "PARTNER_LEVEL_ACCESS":45,
    "COMPANY_LEVEL_ACCESS":46,
    "DISBURSEMENT_L1_APPROVAL":47,
    "DISBURSEMENT_L2_APPROVAL":48,
    "MULTI_YEAR": 49,
    "BANK_SHEET": 50,
    "OUTSTANDING" : 51,
    "PARTNER_UNHOLD" : 52,
    "PARTNER_STATEMENT" : 53,
    "DISBURSEMENT_HISTORY": 54,
    "BP_Rule_Creation" : 55,
    "BP_Rule_Creation_Backdation_upto_07Days" : 56,
    "BP_Rule_Creation_Backdation_upto_30Days" : 57,
    "BP_Rule_Creation_No_backdation_limit" : 58,
    "BP_Rule_Approval" : 59,
    "BP_Rule_Deactivation" : 60,
    "BP_Rule_Edit_Rule_Prior_to_Approval": 61,
    "BP_Rule_Edit_Rejected_Rule": 64,
    "BULK_UPLOAD_ACCESS":65,
    "BULK_UPLOAD_APPROVAL_DISCARD_ACCESS":66,
    "BULK_UPLOAD_CREATE":67,
    "BULK_UPLOAD_EDIT":68,
    "BULK_UPLOAD_MISMATCH":69,
    "BULK_UPLOAD_MATCH":70,
    "TRANSACTION_SEARCH":71,
    "CREATE_GROUP_RTO": 72,

    //Insta Pay
    "INSTA_PAY": 73,

    "ITR_UPLOAD": 74,

    // Rule deactivation
    "Deactivate_Approved_Rule": 75,
    "Deactivate_Approved_Rule_Backdation_upto_07Days": 76,
    "Deactivate_Approved_Rule_No_backdation_limit": 77,

    "RECOVERY_CLEARANCE": 78,

    "UPDATE_FINANCE_SHEET": 79,
    "RESTRICT_INSTAPAY_OPTION": 80,
    "DISBURSEMENT_RULE": 81,
    "SPECIAL_RULE_RULE_CREATION_BACKDATION_UP_TO_03_DAYS": 82,

    "CONTEST_RULE_FINAL_APPROVAL": 83
};

