import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { PermissionsType } from "../models/user.permissions";


@Injectable({
    providedIn: 'root',
})
export class StateService {
    private userState: any
    private loaded = new ReplaySubject<boolean>();

    loaded$ = this.loaded.asObservable();

    send(value: boolean) {
        this.loaded.next(value);
    }

    addState(state: any) {
        this.userState = state;
        this.userState.module = [];

        for (const res of this.userState.permission) {
            for (const module of res.module) {
                this.add(this.userState.module, module)
            }
        }
        // console.log(this.userState.module)

    }


    add(arr: any, module: any) {
        const { length } = arr;
        const id = length + 1;
        const i = arr.findIndex((e: any) => e.module === module.module);
        if (i > -1) {
            const permissions = arr[i].role_rights
            const modulePerm = module.role_rights
            const union = [...new Set([...permissions, ...modulePerm])];
            arr[i].role_rights = union
        }
        else {
            arr.push(module)
        }

    }

    getState(): any {
        return this.userState
    }

    isGranted(resource?: number, permission?: PermissionsType) {
        if (!resource) {
            return true;
        }

        for (const res of this.userState.module) {
            if (resource == res.module) {
                for (const perm of res.role_rights) {
                    if (perm == permission) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}