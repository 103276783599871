import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { alternateValidator, rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-net-premium-field',
  templateUrl: './net-premium-field.component.html',
  styleUrls: ['./net-premium-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class NetPremiumFieldComponent {
  @Input('required') isRequired = false;
  form: FormGroup;
  net_premium: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });
  constructor(private ctrlContainer: FormGroupDirective) { };
  ngOnInit(): void {
   if(this.isRequired){
    this.net_premium.controls['to'].addValidators([Validators.required,rangeValidator.bind(this,this.net_premium),alternateValidator.bind(this,this.net_premium)]);
    this.net_premium.controls['from'].addValidators([Validators.required,rangeValidator.bind(this,this.net_premium),alternateValidator.bind(this,this.net_premium)]);
   }
   else{
    this.net_premium.controls['to'].addValidators(rangeValidator.bind(this,this.net_premium));
    this.net_premium.controls['from'].addValidators(rangeValidator.bind(this,this.net_premium));
   }
   this.form=this.ctrlContainer.form;
   this.form.addControl('net_premium',this.net_premium)
  }
  // change(){
  //   if(parseInt(this.net_premium.controls['to'].value)>=10)
  //   { this.net_premium.controls['to'].setValue(10); 
  //     return; 
  //   }
  // }
  ngOnDestroy():void{
    this.form.removeControl('net_premium');
  }

}
