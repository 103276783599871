<label class="subHeading"
  >Plan Policy Type<span *ngIf="isRequired" class="star">*</span></label
>
<app-search-dropdown
  [dataSearchKey]="'name'"
  [dataValueKey]="'rb_id'"
  [dataSource]="plan_policy_type"
  [control]="planpolicytypeControl"
  [multiple]="!isRequired"
></app-search-dropdown>
