import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { RulesService } from 'src/app/rules/rules.service';

@Component({
  selector: 'app-lob-select',
  templateUrl: './lob-select.component.html',
  styleUrls: ['./lob-select.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class LobSelectComponent implements OnInit {
  @Input('required') isRequired = false;
  @Input('contest') isContest = false;
  @Input('isChild') isChild = false;
  @Input('isTransaction') isTransaction = false;
  @Input('restrict') hasRoleRestriction = false;
  @Input('multiple') isMultiSelect = false;
  @Input('isDisbursement') isDisbursement = false;

  lobMappings = [
    { value: 'motor', name: 'Two Wheeler', hasContest:true,hasChild:true,hasDisbursement:true},
    { value: 'motor4W', name: 'Four Wheeler', hasContest:true,hasChild:true,hasDisbursement:true },
    { value: 'cv', name: 'CV', hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'health', name: 'Health', hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'life', name: 'Life', hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'finsure', name: 'Finsure-Health', hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'misp', name: 'MISP', hasContest:false,hasDisbursement:true },
    { value: 'travel', name: 'TRAVEL', hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'sme', name: 'SME', hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'finsure_life', name: 'Finsure-Life',hasContest:true,hasChild:false,hasDisbursement:true },
    { value: 'finsure_cards', name: 'Finsure-Card', hasContest: true, hasChild: false,hasDisbursement:true },
    { value: 'rb_loans', name: 'RB-Loans', hasContest: true, hasChild: false, hasDisbursement: true },
    { value: 'auto_loans', name: 'Auto Loans', hasContest: true, hasChild: false, hasDisbursement: true },
    { value: 'road_side_assist', name: 'Road Side Assits', hasContest: true, hasChild: false, hasDisbursement: true },
  ];

  lobs:any = [];

  form: FormGroup;

  constructor(private ctrlContainer: FormGroupDirective, private ruleService: RulesService) {}

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    if (this.isContest) {
      this.lobs = this.lobMappings.filter((lob: any) => lob.hasContest);
    }
    else if(this.isChild){
      this.lobs = this.lobMappings.filter((lob: any) => lob.hasChild);
    }else if(this.isDisbursement){
      this.lobs = this.lobMappings.filter((lob: any) => lob.hasDisbursement);
    }
    else if(this.isTransaction){
      this.lobs = this.lobMappings;
    }
    else {
      this.lobs = this.lobMappings;
    }

    if (this.isRequired) {
      this.form.addControl('lob', new FormControl(null, Validators.required));
    } else {
      this.form.addControl('lob', new FormControl(null));
    }

    if (this.hasRoleRestriction) {
      this.applyRoleRestriction();
    }
  }

  applyRoleRestriction() {
    if (this.isContest) {
      this.lobs = this.lobMappings.filter((lob: any) => this.ruleService.isAuthorized(lob.value, 3) && lob.hasContest);
    } else {
      this.lobs = this.lobMappings.filter((lob: any) => this.ruleService.isAuthorized(lob.value, 3));
    }
  }
  ngOnDestroy(): void {
    this.form.removeControl('lob');
  }
}
