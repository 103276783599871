import { Component } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';


@Component({
  selector: 'app-nop-field',
  templateUrl: './nop-field.component.html',
  styleUrls: ['./nop-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class NopFieldComponent {
  form: FormGroup;

  nopForm: FormGroup = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  constructor(private ctrlContainer: FormGroupDirective) { };

  ngOnInit() {
    this.nopForm.controls['to'].addValidators(rangeValidator.bind(this, this.nopForm));
    this.nopForm.controls['from'].addValidators(rangeValidator.bind(this, this.nopForm));

    this.form = this.ctrlContainer.form;

    if (this.form.controls['nop']) {
      this.nopForm.patchValue(this.form.controls['nop'].value);
      this.form.removeControl('nop');
    }
    this.form.addControl('nop', this.nopForm);
  }

  // validRangeValidator(control: AbstractControl): { [s: string]: boolean } {
  //   if (
  //     Number(this.nopForm.controls['to'].value) < Number(this.nopForm.controls['from'].value)
  //   ) {
  //     return { 'small': true };
  //   }
  //   if (this.nopForm.controls['to'].invalid) {
  //     this.nopForm.controls['to'].updateValueAndValidity();
  //   }
  //   if (this.nopForm.controls['from'].invalid) {
  //     this.nopForm.controls['from'].updateValueAndValidity();
  //   }
  //   return null;
  // }

  ngOnDestroy(): void {
    this.form.removeControl('nop');
  }
}
