<div class="row">
    <div class="col-12">
        <mat-label class="subHeading">Group RTO</mat-label>
        <app-search-dropdown
            [dataSearchKey]="'rto_code'"
            [dataValueKey]="'id'"
            [dataSource]="groupList"
            [control]="groupRtoControl"
            [multiple]="false"
            ></app-search-dropdown>
    </div>
</div>