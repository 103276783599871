<table [formGroup]="payoutForm" class="round-table refundDetail">
    <tr>
        <th>{{title}}<span *ngIf="required" class="star">*</span></th>
        <th>Percentage(%)</th>
        <th>Amount</th>
    </tr>
    <tr *ngFor="let payoutBaseObj of payoutBaseObjects" [formGroupName]="payoutBaseObj.value">
        <td>{{payoutBaseObj.title}}</td>
        <td><input formControlName="percent" placeholder="percent" appNumberOnly appPercentOnly></td>
        <td><input formControlName="amount" [maxlength]="5" placeholder="amount" appNumberOnly></td>
    </tr>
</table>
