import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpService } from 'src/app/core/services/http.service';
import { RESOURCES } from 'src/app/core/utils/constants';
import { formatAllDates } from 'src/app/core/utils/utility.functions';
import { StateService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'app-run-rules-dialog',
  templateUrl: './run-rules-dialog.component.html',
  styleUrls: ['./run-rules-dialog.component.scss']
})
export class RunRulesDialogComponent implements OnInit {
  run:FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  actionButtonLabel: string = '';
  action: boolean;
  setAutoHide: boolean = true;
  autoHide: number = 5000;
  result: any;
  perm:boolean=true;
  lob:string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RunRulesDialogComponent>,private http: HttpService,public snackBar: MatSnackBar, public stateService: StateService) {}

  ngOnInit(): void {
  this.run=new FormGroup({

  });
  if(this.stateService.isGranted(RESOURCES.DISBURSEMENT_L1_APPROVAL,2)){
    this.perm=false;
  }
  }
  closeDialog(){
    this.dialogRef.close();
  }
  submit(){
    console.log(this.run.value);
    this.lob = this.run.controls['lob'].value
    const url = `services/api/v1/transactions/rule-rerun-based-on-lob?lob=${this.lob}`;
    this.http.get(url).subscribe((res: any) => {
      this.result = 'Rules are processing';
      console.log(res);
      let config = new MatSnackBarConfig();
      config.verticalPosition = this.verticalPosition;
      config.horizontalPosition = this.horizontalPosition;
      config.duration = this.setAutoHide ? this.autoHide : 0;
      this.snackBar.open(
        this.result,
        this.action ? this.actionButtonLabel : undefined,
        config
      );
    });
    this.closeDialog()
  }

}
