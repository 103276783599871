import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';


@Component({
  selector: 'app-state-field',
  templateUrl: './state-field.component.html',
  styleUrls: ['./state-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class StateFieldComponent implements OnInit {

  stateList: any = [];
  data: any = [];

  form: FormGroup;

  selectFormControl = new FormControl();

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('state', this.selectFormControl);

    this.getStates();
  }

  getStates() {
    const url = '/services/api/v1/rb_masters/states';

    this.http.get(url).subscribe((res:any) => {
      this.stateList = res['data'];
    });

  }

}
