<mat-label class="subHeading"
  >Payin Company<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select formControlName="payinCompany" placeholder="Single Selection">
  <mat-option
    *ngFor="let payinCompanyData of payinCompanyList"
    [value]="payinCompanyData.company_name"
  >
    {{ payinCompanyData.company_name }}
  </mat-option>
</mat-select>

