import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { sort, mapper, Mapper } from 'src/app/core/utils/utility.functions';
import { FormControlService } from 'src/app/shared/services/form-control.service';

@Component({
  selector: 'app-state-city-rto',
  templateUrl: './state-city-rto.component.html',
  styleUrls: ['./state-city-rto.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class StateCityRtoComponent implements OnInit {
  @Input('showRto') showRto = true;
  @Input('required') isRequired = false;

  requiredValidator = Validators.required;

  form: FormGroup;

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService, private controlStateService: FormControlService) { 
    this.controlStateService.register({ controlName: 'state', control: this.stateControl });
    this.controlStateService.register({ controlName: 'city', control: this.cityControl });
    if (this.showRto) {
      this.controlStateService.register({ controlName: 'rto', control: this.rtoControl });
    }
    this.subscribeToValueChangesV2();
  };

  stateList: any = [];
  cityList: any = [];
  rtoList: any = [];

  stateControl = new FormControl([]);
  cityControl = new FormControl([]);
  rtoControl = new FormControl([]);

  mappingControl = new FormControl(main_mapping);

  ngOnInit() {
    this.getStates();
    if (this.isRequired) {
      this.stateControl.addValidators(Validators.required);
      this.cityControl.addValidators(Validators.required);
      this.rtoControl.addValidators(Validators.required);
    }

    this.form = this.ctrlContainer.form;

    // this.subscribeToValueChanges();

    if (this.form.controls['state']) {
      this.stateControl.patchValue(this.form.controls['state'].value);
      this.form.removeControl('state');
    }
    if (this.form.controls['city']) {
      this.cityControl.patchValue(this.form.controls['city'].value);
      this.form.removeControl('city');
    }
    if (this.form.controls['rto']) {
      this.rtoControl.patchValue(this.form.controls['rto'].value);
      this.form.removeControl('rto');
    }

    this.form.addControl('state', this.stateControl);
    this.form.addControl('city', this.cityControl);

    if (this.showRto) {
      this.form.addControl('rto', this.rtoControl);
    }

    // this.form.addControl('state_mappings', this.mappingControl);
  }

  subscribeToValueChanges() {
    this.stateControl.valueChanges.subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getCities(val);
        } else {
          this.cityList = [];
          this.rtoList = [];
          // this.rtoControl.patchValue(null);
          // this.cityControl.patchValue(null);
        }
        // this.mapStates(val);
      }
    );

    this.cityControl.valueChanges.subscribe(
      (val: any) => {
        if (val && val.length && this.showRto) {
          this.getRtos(val);
        } else {
          this.rtoList = [];
          // this.rtoControl.patchValue(null);
        }
        // this.mapCities(val);
      }
    );

    this.rtoControl.valueChanges.subscribe(
      (val: any) => {
        // this.mapRtos(val);
      }
    );

  }

  subscribeToValueChangesV2() {
    this.controlStateService.getControlObservable('state').subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getCities(val);
        } else {
          this.cityList = [];
          this.rtoList = [];
          // this.rtoControl.patchValue(null);
          // this.cityControl.patchValue(null);
        }
        // this.mapStates(val);
      }
    );

    this.controlStateService.getControlObservable('city').subscribe(
      (val: any) => {
        if (val && val.length && this.showRto) {
          this.getRtos(val);
        } else {
          this.rtoList = [];
          // this.rtoControl.patchValue(null);
        }
        // this.mapCities(val);
      }
    );

  }

  getStates() {
    const url = '/services/api/v1/rb_masters/states';
    this.http.get(url).subscribe((res: any) => {
      this.stateList = sort(res['data'], 'state_name');
      // this.mapAll();
    });
  }

  getCities(stateIds: any) {
    const url = `/services/api/v1/rb_masters/get-cities`;
    const data = { 'state_id': stateIds };
    this.http.post(url, data).subscribe((res: any) => {
      this.cityList = sort(res['data'], 'city_name');
      // this.mapAll();
    });
  }

  getRtos(cityIds: any) {
    const url = `/services/api/v1/rb_masters/get-rtos`;
    const data = { 'city_id': cityIds };
    this.http.post(url, data).subscribe((res: any) => {
      this.rtoList = sort(res['data'], 'name');
      // this.mapAll();
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('state');
    this.form.removeControl('city')
    if (this.showRto) {
      this.form.removeControl('rto');
    }
  }

  mapAll() {
    if (
      (!this.stateList || !this.stateList.length)
      ||
      (!this.cityList || !this.cityList.length)
      ||
      (!this.rtoList || !this.rtoList.length)
    ) { return };

    this.mapStates(this.stateControl.value);
    this.mapCities(this.cityControl.value);
    this.mapRtos(this.rtoControl.value);
  }

  mapStates(states: any) {
    if (
      (!this.stateList || !this.stateList.length)
    ) { return };
    const data:Mapper = {
      dataSource: this.stateList,
      dataKey: 'rb_id',
      selfKey: 'state_id',
      valuesToMap: states,
    };
    main_mapping = mapper(main_mapping, data);
    this.mappingControl.setValue(main_mapping);
    console.log(this.mappingControl.value);
  }

  mapCities(cities: any) {
    if (
      (!this.stateList || !this.stateList.length)
      ||
      (!this.cityList || !this.cityList.length)
    ) { return };
    const data:Mapper = {
      dataSource: this.cityList,
      dataKey: 'rb_id',
      selfKey: 'city_id',
      parentKey: 'state_id',
      valuesToMap: cities,
    };
    main_mapping = mapper(main_mapping, data);
    this.mappingControl.setValue(main_mapping);
    console.log(this.mappingControl.value);
  }

  mapRtos(rtos: any) {
    if (
      (!this.stateList || !this.stateList.length)
      ||
      (!this.cityList || !this.cityList.length)
      ||
      (!this.rtoList || !this.rtoList.length)
    ) { return };
    const data:Mapper = {
      dataSource: this.rtoList,
      dataKey: 'rb_id',
      selfKey: 'rto_id',
      parentKey: 'city_id',
      valuesToMap: rtos,
    };
    main_mapping = mapper(main_mapping, data);
    this.mappingControl.setValue(main_mapping);
    console.log(this.mappingControl.value);
  }
}

let main_mapping:any[] = [];
