<mat-label class="subHeading"
  >Insurer Business Type<span *ngIf="isRequired" class="star"
    >*</span
  ></mat-label
>
<mat-select
  #insurerBusinessTypeSelect
  class="insurer"
  multiple
  formControlName="insurerBusinessType"
>
  <mat-option
    *ngFor="let insurerObj of insurerbusinessTypeList"
    [value]="insurerObj"
  >
    {{ insurerObj }}
  </mat-option>
</mat-select>
