import {Component, Inject, Input, OnInit,ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpService } from "src/app/core/services/http.service";
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-schedular-dialog',
  templateUrl: './schedular-dialog.component.html',
  styleUrls: ['./schedular-dialog.component.scss']
})
export class SchedularDialogComponent implements OnInit {
  tableData: any[] = [];
  syncCount:any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<SchedularDialogComponent>,
  private http: HttpService){}

  ngOnInit():void{
    this.fetchData();
    this.syncData();
  }

  fetchData(){
    const url = '/services/api/v1/transactions/get-scheduler-status';
    this.http.get(url).subscribe((res: any) => {
        this.tableData = res['data'];
    })
  }

  syncData(){
    const url= '/services/api/v1/transaction_sync/transaction-job-sync-count';
    this.http.get(url).subscribe((res:any)=>{
      this.syncCount = res;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
