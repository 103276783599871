<mat-label class="subHeading"
  >Payout Company<span *ngIf="isRequired" class="star">*</span></mat-label
>
<mat-select formControlName="payoutCompany" placeholder="Single Selection">
  <mat-option
    *ngFor="let payoutCompanyData of payoutCompanyList"
    [value]="payoutCompanyData.company_name"
  >
    {{ payoutCompanyData.company_name }}
  </mat-option>
</mat-select>
