import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphaNumeric]'
})
export class AlphaNumericDirective {
  alphaRegex = /[a-zA-Z0-9]/;
  allowedKeys = ['space'];

  constructor(private el: ElementRef) { };

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    if (!this.alphaRegex.test(e.key) && this.allowedKeys.indexOf(e.code.toLowerCase()) == -1) {
      e.preventDefault();
      return;
    }
  }
}
