<mat-label class="subHeading ">Chassis No.<span *ngIf="isRequired" class="star">*</span></mat-label>

<input
    [formControl]="chassisControl"
    class="reg mt-2"
    placeholder="xxxxxxxxxx"
    [maxlength]="20"

    [value]="chassisControl.value | uppercase"
/>
<mat-error *ngIf="chassisControl.value && chassisControl.hasError('pattern')">Please enter valid format</mat-error>
<mat-error *ngIf="chassisControl.value && chassisControl.hasError('minlength')">
    Should be atleast 6 characters long
</mat-error>

<mat-error *ngIf="chassisControl.hasError('maxlength')">
  Max length is 20 characters
</mat-error>
