import { Component } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { PAYOUT_BASE } from '../pay-out/constants';

@Component({
  selector: 'app-renewal-payin-out-parent',
  templateUrl: './renewal-payin-out-parent.component.html',
  styleUrls: ['./renewal-payin-out-parent.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RenewalPayinOutParentComponent {
  form: FormGroup;
  renewalPayout: FormGroup;
  renewalPayin: FormGroup;

  constructor(private ctrlContainer: FormGroupDirective) {
    this.renewalPayin = this.getFormGroup();
    this.renewalPayout = this.getFormGroup();
  }

  ngOnInit() {
    this.form = this.ctrlContainer.form;
    this.form.addControl('renewal_payout', this.renewalPayout);
    this.form.addControl('renewal_payin', this.renewalPayin);
  }

  getFormGroup() {
    const percentControl = new FormControl();
    const amountControl = new FormControl();

    percentControl.valueChanges.subscribe((val: any) => {
      if (val) {
        (this.renewalPayout.controls[3] as FormGroup).controls[
          'amount'
        ].disable({ emitEvent: false });
        (this.renewalPayin.controls[3] as FormGroup).controls['amount'].disable(
          { emitEvent: false },
        );
      } else if (
        !(this.renewalPayout.controls[3] as FormGroup).controls['percent']
          .value &&
        !(this.renewalPayin.controls[3] as FormGroup).controls['percent'].value
      ) {
        (this.renewalPayout.controls[3] as FormGroup).controls['amount'].enable(
          { emitEvent: false },
        );
        (this.renewalPayin.controls[3] as FormGroup).controls['amount'].enable({
          emitEvent: false,
        });
      }
    });

    amountControl.valueChanges.subscribe((val: any) => {
      if (val) {
        (this.renewalPayout.controls[3] as FormGroup).controls[
          'percent'
        ].disable({ emitEvent: false });
        (this.renewalPayin.controls[3] as FormGroup).controls[
          'percent'
        ].disable({ emitEvent: false });
      } else if (
        !(this.renewalPayout.controls[3] as FormGroup).controls['amount']
          .value &&
        !(this.renewalPayin.controls[3] as FormGroup).controls['amount'].value
      ) {
        (this.renewalPayout.controls[3] as FormGroup).controls[
          'percent'
        ].enable({ emitEvent: false });
        (this.renewalPayin.controls[3] as FormGroup).controls['percent'].enable(
          { emitEvent: false },
        );
      }
    });

    const formGroup = new FormGroup({
      [PAYOUT_BASE.NET_PREMIUM.value]: new FormGroup({
        percent: percentControl,
        amount: amountControl,
      }),
    });
    return formGroup;
  }

  ngOnDestroy() {
    this.form.removeControl('renewal_payout');
    this.form.removeControl('renewal_payin');
  }
}
