import { Component, Input, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-uin-no',
  templateUrl: './uin-no.component.html',
  styleUrls: ['./uin-no.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class UinNoComponent {
  form: FormGroup;
  @Input('required') isRequired = false;
  @Input('allRuleIdData') allRuleIdData: any;
  uinNoData: any = '';
  constructor(private ctrlContainer: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.form.addControl(
        'uin_no',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('uin_no', new FormControl());
    }
    let getUrl = window.location.href;
    if (getUrl.includes('payout-processing/main/edit-payout-rule')) {
      this.form.patchValue({
        uin_no: this.uinNoData,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lob']) {
      if (changes['lob'].currentValue !== '') {
        let lobSelected = changes['lob'].currentValue;
        if (lobSelected === 'health' || lobSelected === 'life') {
          this.form.get('uin_no')?.setValidators([Validators.required]);
          this.form.get('uin_no')?.updateValueAndValidity();
        } else {
          this.form.get('uin_no')?.setValidators([]);
          this.form.get('uin_no')?.updateValueAndValidity();
        }
      }
    }
    if (changes['allRuleIdData']) {
      if (changes['allRuleIdData'].currentValue.UIN !== undefined) {
        let getUrl = window.location.href;
        if (getUrl.includes('payout-processing/main/edit-payout-rule')) {
          this.uinNoData = changes['allRuleIdData'].currentValue.UIN;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('uin_no');
  }
}
