import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SchedularDialogComponent } from 'src/app/shared/components/dialog-components/schedular-dialog/schedular-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  homeUrl = 'https://renewbuy.com';

  constructor(public dialog: MatDialog, private activatedRoute: ActivatedRoute, private location: Location, private router: Router){
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.previous) {
        const url = this.location.path().split('?')[0];
        this.homeUrl = params.previous;
        this.router.navigateByUrl(url);
      }
    });
  }

  ngOnInit(): void {
    
  }

  openConsoleDialog(){
    this.dialog.open(SchedularDialogComponent, {
      width: '900px', height:'550px'
    });
  }

}
