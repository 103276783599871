import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, Form, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-sourcing-type',
  templateUrl: './sourcing-type.component.html',
  styleUrls: ['./sourcing-type.component.scss'],
  viewProviders:[{provide:ControlContainer,useExisting:FormGroupDirective}]
})
export class SourcingTypeComponent implements OnInit {
  @Input('required') isRequired:boolean=false
  @Input('multiple') isMultiSelect:boolean=true;

  sourceTypes=['BQP','Direct','Direct Renewal','POS'];

  form :FormGroup;
  constructor(private ctrlContainer:FormGroupDirective){};

  ngOnInit(): void {
    this.form=this.ctrlContainer.form;
    if(this.isRequired){
      this.form.addControl('sourcing_type',new FormControl([],Validators.required));
    }
    else{
      this.form.addControl('sourcing_type',new FormControl([]));
    }

    this.form.controls['sourcing_type'].valueChanges.subscribe((val:any)=>{
      if(val==null){
        this.form.patchValue({'sourcing_type':[]});
      }
    });
  }

  selectionChange(event:any){
    if(!this.isMultiSelect && event.isUserInput && event.source.selected){
      this.form.controls['sourcing_type'].setValue([event.source.value]);
    }
  }

  ngOnDestroy():void{
    this.form.removeControl('sourcing_type');
  }
} 
