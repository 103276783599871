import { Component, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { FormControlService } from 'src/app/shared/services/form-control.service';


@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class SearchDropdownComponent implements OnChanges {
  private isPatched: boolean = false; // Represents if form has been patched
  private isTouched: boolean = false;

  @ViewChild('matSelect', {read: ElementRef}) matSelect: ElementRef;
  @ViewChild('search') searchTextBox: ElementRef;

  @Input() dataSearchKey = '';
  @Input() dataValueKey = '';
  @Input() dataSource: any = [];
  @Input('multiple') isMultiSelect:boolean = true;
  @Input('control') selectFormControl: FormControl;

  searchTextboxControl = new FormControl('');

  minLengthToSearch: number = 3;
  maxValuesToShow: number = 50;

  dataToShow: any = [];
  selectedValues: Set<any> = new Set<any>();


  constructor(private ctrlContainer: FormGroupDirective, private controlStateService: FormControlService) { };

  ngOnInit() {
    // If the control is not already registered, it will be registered here
    this.controlStateService.register({ controlName: this.controlName, control: this.selectFormControl });

    if (this.selectFormControl.value == null) {
      this.selectFormControl.setValue([], {emitEvent:false});
    }
    this.selectFormControl.valueChanges.subscribe(val => {
      if (!this.isPatched && !this.isTouched && val && val.length) {
        this.isPatched = true;
        this.selectedValues = new Set(val);
        this.setSelectedValues();
      }else{
        this.selectedValues = new Set(val);
        this.setSelectedValues();
      }
    });

    this.searchTextboxControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => {
      this.searchAndUpdateData(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['dataSource'].firstChange && this.dataSource != changes['dataSource'].previousValue) {
      if (this.dataSource) {
        this.dataToShow = this.dataSource.slice(0, this.maxValuesToShow);
        if (this.selectFormControl.value && this.dataSource.length && this.selectFormControl.value.length) {
          const allDataValueSet = new Set(this.dataSource.map((data: any) => data[this.dataValueKey] || data));
          if (this.selectFormControl.value.length != this.dataSource.length || JSON.stringify(this.selectFormControl.value) != JSON.stringify([...allDataValueSet])) {
            const filteredValues = this.selectFormControl.value.filter((value:any) => allDataValueSet.has(value));
            this.selectedValues = new Set(filteredValues);
            // this.setSelectedValues();
          }
        } else {
          this.dataToShow = [];
          this.selectedValues.clear();
        }
        this.updateDataToShow();
        this.setSelectedValues();
      }
    }
  }

  ngAfterViewInit() {
    if (!this.dataToShow.length) {
      this.matSelect.nativeElement.click();
    }
  }

  setSelectedValues() {
    console.time(`${this.controlName} time`);
    console.time(`${this.controlName} time select value`);
    [...this.selectedValues];
    let newValue = [...this.selectedValues];
    console.timeEnd(`${this.controlName} time select value`);
    const existingValue = this.controlStateService.getStateValue(this.controlName);
    if (!existingValue || existingValue.length != newValue.length || (existingValue.length == newValue.length && !this.hasValue(existingValue))) {
      this.controlStateService.updateStateValue(this.controlName, newValue);
      this.selectFormControl.setValue(newValue);
    }
    console.timeEnd(`${this.controlName} time`)
  }

  selectionChange(event: any) {
    /*
    Clear the set first regardless of selected or unselected.
    If selected, add the item. If not multi select, set will only contain
    one value since we cleared it.
    If unselected, and dropdown is multiselect, we only need to remove that specific item.
    */
    if (event.isUserInput) {
      // Only do stuff if the option is selected by user.
      // Since, setting form control value also triggers this event. Which goes into infinite recursion.
      if (!this.isMultiSelect) {
        this.selectedValues.clear();
      }
      if (event.source.selected) {
        this.selectedValues.add(event.source.value);
        this.selectFormControl.setValue([...this.selectedValues], { emitEvent: false });
      } else if (!event.source.selected && this.isMultiSelect) {
        this.selectedValues.delete(event.source.value);
        this.selectFormControl.setValue([...this.selectedValues], { emitEvent: false });
      }
    }
  }

  clickEvent() {
    this.dataToShow = this.dataSource.slice(0, this.maxValuesToShow);
  }

  openedChange(opened: any) {
    this.isTouched = true;
    // Set search textbox value as empty while opening selectbox 
    // Focus to search textbox while clicking on selectbox
    if (opened) {
      this.dataToShow = this.dataSource.slice(0, this.maxValuesToShow);
      this.searchTextboxControl.patchValue('', {emitEvent:false});
      this.searchTextBox.nativeElement.focus();
    } else if (!opened) {
      this.updateDataToShow();
      this.setSelectedValues();
    }
  }


  updateDataToShow() {
    setTimeout(() => {
      this.dataToShow = [];
      let itemsCount = 0;
      for (const item of this.selectedValues) {
        if (itemsCount == this.maxValuesToShow) {
          break;
        }
        if (this.dataValueKey) {
          this.dataToShow.push(this.dataSource.find((data: any) => data[this.dataValueKey] == item));
        } else {
          this.dataToShow.push(this.dataSource.find((data: any) => data == item));
        }
        itemsCount++;
      }
      if (this.dataToShow.length < this.maxValuesToShow) {
        for (const item of this.dataSource) {
          if (itemsCount == this.maxValuesToShow) {
            break;
          }
          this.dataToShow.push(item);
          itemsCount++;
        }
      }
    }, 120);
  }

  searchAndUpdateData(value: string) {
    value = value.trim().toLowerCase();

    if (!value || value.length < this.minLengthToSearch || !this.dataSource || this.dataSource.length === 0) {
      this.dataToShow = this.dataSource.slice(0, this.maxValuesToShow);
      return false;
    }

    let itemsPushed = 0;
    const updatedList = [];
    if (this.dataSearchKey) {
      for (const option of this.dataSource) {
        if (itemsPushed == this.maxValuesToShow) {
          break;
        }
        if (option[this.dataSearchKey].toLowerCase().indexOf(value) !== -1) {
          updatedList.push(option);
          itemsPushed++;
        }
      }
    } else {
      for (const option of this.dataSource) {
        if (itemsPushed == this.maxValuesToShow) {
          break;
        }
        if (option.toLowerCase().indexdexOf(value) !== -1) {
          updatedList.push(option);
          itemsPushed++;
        }
      }
    }
    this.dataToShow = updatedList;
    return true;
  }

  /**
   * Clearing search textbox value 
   */
  clearSearch(event: any) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('', {emitEvent:false});
  }

  selectAll() {
    if (this.dataValueKey) {
      this.selectedValues = new Set(this.dataSource.map((obj: any) => obj[this.dataValueKey]));
    } else {
      this.selectedValues = new Set(this.dataSource);
    }
    this.selectFormControl.setValue([...this.selectedValues], { emitEvent: false });
  }

  unSelectAll() {
    this.selectedValues.clear();
    this.selectFormControl.setValue([...this.selectedValues], { emitEvent: false });
  }

  get toolTipData() {
    if (!this.dataSource || this.dataSource.length == 0) {
      return '';
    }

    let toolTipString = '';
    let itemsPushed = 0;
    if (this.dataSearchKey) {
      for (const value of this.selectedValues) {
        if (itemsPushed == this.maxValuesToShow) {
          toolTipString += 'more...';
          break;
        }
        const data = this.dataSource.find((item: any) => item[this.dataValueKey] == value);
        if (data) {
          const name = data[this.dataSearchKey];
          toolTipString += `${itemsPushed + 1}. ${name} \n`;
          itemsPushed++;
        }
      }
    } else {
      for (const value of this.selectFormControl.value) {
        if (itemsPushed == this.maxValuesToShow) {
          toolTipString += 'more...';
          break;
        }
        toolTipString += `${itemsPushed + 1}. ${value} \n`
          itemsPushed++;
      }
    }
    return toolTipString;
  }

  get controlName() {
    return Object.keys(this.ctrlContainer.form.controls).find((name: string) => this.selectFormControl === this.ctrlContainer.form.controls[name]);
  }

  private hasValue(arr: any) {
    for (const item of arr) {
      if (!this.selectedValues.has(item)) {
        return false;
      }
    }
    return true;
  }
}