import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { rangeValidator } from 'src/app/core/utils/utility.functions';

@Component({
  selector: 'app-pay-till-age-field',
  templateUrl: './pay-till-age-field.component.html',
  styleUrls: ['./pay-till-age-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PayTillAgeFieldComponent implements OnInit,OnDestroy {
  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('pay_till_age',new FormControl(null))
  }

  ngOnDestroy(): void {
    this.form.removeControl('pay_till_age');
  }

}