<div class="row" *ngIf="isMultiSelect">
  <div class="col-12">
    <label class="subHeading">Credit Card Issuing Bank<span *ngIf="isRequired" class="star">*</span></label>
    <mat-select formControlName="cc_bank" multiple #cardNameSelect>
        <mat-option *ngFor="let obj of cc_bank" [value]="obj.name">
            {{obj.name}}
        </mat-option>
    </mat-select>
  </div>

  <div class="col-12">
    <label class="subHeading"
      >Credit Card<span *ngIf="isRequired" class="star">*</span></label
    >
      <mat-select formControlName="cc_name" multiple>
          <mat-option *ngFor="let obj1 of cc_name" [value]="obj1">
              {{obj1}}
          </mat-option>
      </mat-select>
      <!-- <app-search-dropdown
      [dataSearchKey]="'name'"
      [dataValueKey]="'rb_id'"
      [dataSource]="cc_name"
      [control]="ccNameControl"
    ></app-search-dropdown> -->
  </div>

</div>

<div class="row" *ngIf="!isMultiSelect">
  <div class="col-6">

    <label class="subHeading"
      >Credit Card Issuing Bank<span *ngIf="isRequired" class="star">*</span></label
    >
    <mat-select formControlName="cc_bank">
        <mat-option *ngFor="let obj of cc_bank" [value]="obj.name">
            {{obj.name}}
        </mat-option>
    </mat-select>

  </div>

  <div class="col-6">

    <label class="subHeading"
      >Credit Card<span *ngIf="isRequired" class="star">*</span></label
    >
      <mat-select formControlName="cc_name">
          <mat-option *ngFor="let obj1 of cc_name" [value]="obj1">
              {{obj1}}
          </mat-option>
      </mat-select>
  </div>

</div>
