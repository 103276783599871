import { Component, Input, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-payin-company',
  templateUrl: './payin-company.component.html',
  styleUrls: ['./payin-company.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PayinCompanyComponent {
  @Input('required') isRequired = false;
  @Input('allRuleIdData') allRuleIdData: any;
  payinCompanyList: any = [];
  listOfpayinCompany: any = [];
  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private http: HttpService
  ) {}

  ngOnInit() {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl(
        'payinCompany',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.addControl('payinCompany', new FormControl());
    }
    this.getpayinCompanyList();
  }

  getpayinCompanyList() {
    const url = `services/api/v1/company`;
    this.payinCompanyList = [];
    this.listOfpayinCompany = [];
    this.http.get(url).subscribe((res: any) => {
      if (res) {
        this.listOfpayinCompany = res.data;
        for (let i = 0; i <= this.listOfpayinCompany.length - 1; i++) {
          if (this.listOfpayinCompany[i].is_active) {
            this.payinCompanyList.push(this.listOfpayinCompany[i]);
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['allRuleIdData']) {
    //   if (changes['allRuleIdData'].currentValue.length !== 0) {
    //     if (
    //       changes['allRuleIdData'].currentValue.company_details[0]
    //         .company_name !== undefined
    //     ) {
    //       let getUrl = window.location.href;
    //       if (getUrl.includes('payin-processing/main/edit-payin-rule')) {
    //         let payinCompany =
    //           changes['allRuleIdData'].currentValue.company_details[0]
    //             .company_name;
    //         this.form.patchValue({
    //           payinCompany: payinCompany,
    //         });
    //       }
    //     }
    //   }
    // }
  }
  ngOnDestroy(): void {
    this.form.removeControl('payinCompany');
  }

}
