<mat-label class="subHeading"
  >IDV<span *ngIf="isRequired" class="star">*</span></mat-label
>


<div
  formGroupName="idv"
  class="d-flex align-items-center mt-2"
  style="gap: 10px"
  
>

  <input
    formControlName="from"
    type="text"
    class="w-50 m-0"
    placeholder="From"
    maxlength="9"
    appNumberOnly
  />
  -
  <input
    formControlName="to"
    type="text"
    class="w-50 m-0"
    placeholder="To"
    maxlength="9"
    appNumberOnly
  />
</div>

<p
class="text-danger"
*ngIf="
  idv.controls['from'].hasError('small')
  ||
  idv.controls['to'].hasError('small')
"
>
Invalid range for values
</p>
