import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasicDialogComponent } from '../components/dialog-components/basic-dialog/basic-dialog.component';
import { TableDialogComponent } from '../components/dialog-components/table-dialog/table-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  
  dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog) { }

  openDialogwithTimeout(data: any,component:any, time=2000,f:any)
  {
    if (this.dialogRef) {
      this.dialogRef.close()
    };

    this.dialogRef = this.dialog.open(component, {
      data: data,
      height: 'fit-content',
      width: 'fit-content',
      maxWidth: '90vw',
      maxHeight: '90vh',
      // minHeight: '50%',
      minWidth: '50%'
    });
    this.dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
         
         this.dialogRef.close();
         f()
      }, time)
    })
  }




  openDialog(data: any,component:any, time=2000) {
    if (this.dialogRef) {
      this.dialogRef.close()
    };

    this.dialogRef = this.dialog.open(component, {
      data: data,
      height: 'fit-content',
      width: 'fit-content',
      maxWidth: '90vw',
      maxHeight: '90vh',
      // minHeight: '50%',
      minWidth: '50%'
    });

    setTimeout(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    }, time);

    return this.dialogRef.afterClosed();
  }
}
