import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) { };

  openSnackbar(text: string) {
    this.snackbar.open(text, '', {
      duration: 6000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }
}
