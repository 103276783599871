import { Component, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-rule-field',
  templateUrl: './rule-field.component.html',
  styleUrls: ['./rule-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RuleFieldComponent implements OnInit {
  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl(
      'rule_name',
      new FormControl({ value: '', disabled: true })
    );
  }

  ngOnDestroy(): void {
    this.form.removeControl('rule_name');
  }
}
