import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class OrderTypeComponent implements OnInit{

  orderType: any =[];

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('order_type', new FormControl());
  }

  ngOnDestroy(): void {
    this.form.removeControl('order_type');
  }
}
